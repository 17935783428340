import { marketapi /*, isDebug */ } from "@/config";
import moment from "moment";
//import { find } from "lodash";

class TSMarketplaceService {
  private static instance: TSMarketplaceService;

  public static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this.instance || (this.instance = new this());
  }

  public async saveContractName(
    id: number | string,
    value: string,
  ): Promise<any> {
    const result = await marketapi.post("saveContractName", {
      id: id,
      value: value,
    });
    return result.data.success ? result.data.data : false;
  }

  public async changeTSLabLicenseLogin(
    id: number | string,
    value: string,
  ): Promise<any> {
    const result = await marketapi.post("changeTSLabLicenseLogin", {
      id: id,
      value: value,
    });
    return result.data.success ? result.data.data : false;
  }

  public async archivateContract(id: number | string): Promise<any> {
    const result = await marketapi.post("archivateContract", {
      id: id,
    });
    return result.data.success ? result.data.data : false;
  }

  public async activateContract(id: number | string): Promise<any> {
    const result = await marketapi.post("activateContract", {
      id: id,
    });
    return result.data.success ? result.data.data : false;
  }

  public async contractUnblock(id: number | string): Promise<any> {
    const result = await marketapi.post("contractUnblock", {
      id: id,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getContractsList(
    pagination: any = {},
    filter: any = {},
  ): Promise<any> {
    const result = await marketapi.post("getContractsList", {
      pagination: pagination,
      filter: filter,
    });
    return result.data.success ? result.data.data : false;
  }

  public async broadcastGetList(
    pagination: any = {},
    filter: any = {},
  ): Promise<any> {
    const result = await marketapi.post("broadcastGetList", {
      pagination: pagination,
      filter: filter,
    });
    return result.data.success ? result.data.data : false;
  }

  public async broadcastDelete(id: string): Promise<any> {
    const result = await marketapi.get("broadcastDelete/" + id);
    return result.data.success ? result.data.data : false;
  }

  public async broadcastFetch(broadcastId: string): Promise<any> {
    const result = await marketapi.get("broadcast/" + broadcastId);
    return result.data.success ? result.data.data : false;
  }

  public async tslabProviderGetList(
    pagination: any = {},
    filter: any = {},
  ): Promise<any> {
    const result = await marketapi.post("tslabProviderGetList", {
      pagination: pagination,
      filter: filter,
    });
    return result.data.success ? result.data.data : false;
  }

  // public async tschannelConnectTariffUpdateTslabProviders(offerId, list) {
  //   const result = await marketapi.post(
  //     "tschannelConnectTariffUpdateTslabProviders",
  //     {
  //       id: offerId,
  //       list: list,
  //     },
  //   );
  //   return result.data.success ? result.data.data : false;
  // }

  public async setReceiverOrTransmitterTSPlayerMapping(
    subjectId: string,
    tsplayerId: string,
    subjectType: string,
  ): Promise<any> {
    if (subjectType == "receiver") {
      const result = await marketapi.post("setReceiverTSPlayerMapping", {
        receiverId: subjectId,
        tsplayerId: tsplayerId,
      });
      return result.data.success ? result.data.data : false;
    } else {
      const result = await marketapi.post("setTransmitterTSPlayerMapping", {
        transmitterId: subjectId,
        tsplayerId: tsplayerId,
      });
      return result.data.success ? result.data.data : false;
    }
  }

  public async tsplayerGetList(
    pagination: any = {},
    filter: any = {},
  ): Promise<any> {
    const result = await marketapi.post("tsplayerGetList", {
      pagination: pagination,
      filter: filter,
    });
    return result.data.success ? result.data.data : false;
  }

  public async tsplayerSaveUI(id: string, ui: any = {}): Promise<any> {
    const result = await marketapi.post("tsplayerSaveUI", {
      id: id,
      ui: ui,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getTSPlayerAgent(apiKey): Promise<any> {
    const result = await marketapi.get(
      "getTSPlayerAgentByApiKey/" + apiKey + "?r=" + moment().valueOf(),
    );
    return result.data.success ? result.data.data : false;
  }

  public async getOffersList(
    pagination: any = {},
    filter: any = {},
  ): Promise<any> {
    const result = await marketapi.post("getOffersList", {
      pagination: pagination,
      filter: filter,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getOffer(id, mode = "details") {
    const result = await marketapi.get("offer/" + id + "/" + mode);
    return result.data.success ? result.data.data : false;
  }

  public async deleteOffer(id) {
    const result = await marketapi.post("deleteOffer", {
      id: id,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getContractHistory(
    pagination: any = {},
    contractId: number,
  ): Promise<any> {
    const result = await marketapi.post("getContractHistory", {
      pagination: pagination,
      id: contractId,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getContract(id): Promise<any> {
    const result = await marketapi.get("getContract/" + id);
    return result.data.success ? result.data.data : false;
  }

  public async getShowcaseTSLab(pagination: any = {}): Promise<any> {
    const result = await marketapi.post("getShowcaseTSLab", {
      pagination: pagination,
    });
    return result.data.success ? result.data.data : false;
  }
  /*
  public async getShowcaseTSChannel(pagination: any = {}): Promise<any> {
    const result = await marketapi.post("getShowcaseTSChannel", {
      pagination: pagination,
    });
    return result.data.success ? result.data.data : false;
  }
  */

  public async getTSLabDataProvider(id: number | string): Promise<any> {
    const result = await marketapi.post("getTSLabDataProvider", {
      id: id,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getUserReceivers(pagination: any = {}): Promise<any> {
    const result = await marketapi.post("getUserReceivers", {
      pagination: pagination,
    });
    return result.data.success ? result.data.data : false;
  }

  public async receiverCreateByConnectApiKey(
    connectApiKey,
    name,
  ): Promise<any> {
    const result = await marketapi.post("receiverCreateByConnectApiKey", {
      apikey: connectApiKey,
      name: name,
    });
    return result.data.success ? result.data.data : false;
  }

  public async receiverCreateByTSChannelConnectContract(
    contractId,
    name,
  ): Promise<any> {
    const result = await marketapi.post(
      "receiverCreateByTSChannelConnectContract",
      {
        contractId: contractId,
        name: name,
      },
    );
    return result.data.success ? result.data.data : false;
  }

  public async receiverRename(id, name): Promise<any> {
    const result = await marketapi.post("receiverRename", {
      id: id,
      name: name,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getShowcaseTariffs(
    type: string,
    filter: any = {},
    pagination: any = {},
  ): Promise<any> {
    const result = await marketapi.post("getShowcaseTariffs", {
      type: type,
      filter: filter,
      pagination: pagination,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getShowcaseTschannelConnectTariffs(
    filter: any = {},
    pagination: any = {},
  ): Promise<any> {
    const result = await marketapi.post("getShowcaseTschannelConnectTariffs", {
      filter: filter,
      pagination: pagination,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getShowcaseTschannelConnectTariffsSearchData(): Promise<any> {
    const result = await marketapi.get(
      "getShowcaseTschannelConnectTariffsSearchData",
    );
    return result.data.success ? result.data.data : false;
  }

  public async getTariff(id: number | string): Promise<any> {
    const result = await marketapi.post("getTariff", {
      id: id,
    });
    return result.data.success ? result.data.data : false;
  }

  public async changeTariff(contractId, tariffId) {
    const payload = {
      contractId: contractId,
      tariffId: tariffId,
    };
    const result = await marketapi.post("changeContractTariff", payload);
    return result.data.success ? result.data.data : false;
  }

  public async addExtensionTariff(contractId, tariffId) {
    const payload = {
      contractId: contractId,
      tariffId: tariffId,
    };
    const result = await marketapi.post("addExtensionTariff", payload);
    return result.data.success ? result.data.data : false;
  }

  public async deleteExtensionTariff(contractId, tariffLinkId) {
    const payload = {
      contractId: contractId,
      tariffLinkId: tariffLinkId,
    };
    const result = await marketapi.post("deleteExtensionTariff", payload);
    return result.data.success ? result.data.data : false;
  }

  public async addNewContract(tariffId, name, login = null) {
    const payload = {
      tariffId: tariffId,
      name: name,
      login: login,
    };
    const result = await marketapi.post("addNewContract", payload);
    return result.data.success ? result.data.data : false;
  }

  //TSChannel specific
  public async addNewTransmitter(contractId, name) {
    const result = await marketapi.post("addNewTransmitter", {
      id: contractId,
      value: name,
    });
    return result.data.success ? result.data.data : false;
  }
  public async renameTransmitter(id, name) {
    const result = await marketapi.post("renameTransmitter", {
      id: id,
      name: name,
    });
    return result.data.success ? result.data.data : false;
  }
  public async deleteTransmitter(id) {
    const result = await marketapi.post("deleteTransmitter", {
      id: id,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getReceiversSessions(receivers) {
    const result = await marketapi.post("getReceiversSessions", {
      receivers: receivers,
    });
    return result.data.success ? result.data.data : false;
  }

  public async killReceiverSession(id) {
    await marketapi.post("killReceiverSession", {
      id: id,
    });
  }

  public async addNewReceiver(
    contractId,
    newReceiverUserEmail,
    newReceiverName,
  ) {
    const result = await marketapi.post("addNewReceiver", {
      contractId: contractId,
      email: newReceiverUserEmail,
      name: newReceiverName,
    });
    return result.data.success ? result.data.data : false;
  }

  public async tschannelConnectContractAddReceiver(
    contractId,
    newReceiverName,
  ) {
    const result = await marketapi.post("tschannelConnectContractAddReceiver", {
      contractId: contractId,
      name: newReceiverName,
    });
    return result.data.success ? result.data.data : false;
  }

  public async approveReceiver(receiverId, isBlocked, enabledTo) {
    const result = await marketapi.post("approveReceiver", {
      receiverId: receiverId,
      isBlocked: isBlocked,
      enabledTo: enabledTo,
    });
    return result.data.success ? result.data.data : false;
  }

  public async receiverDelete(receiverId) {
    const result = await marketapi.post("receiverDelete", {
      receiverId: receiverId,
    });
    return result.data.success ? result.data.data : false;
  }

  // Offers tariffs specific

  public async tschannelOfferCreate(
    tschannelContractId,
    data = {},
  ): Promise<any> {
    const result = await marketapi.post("tschannelOfferCreate", {
      tschannelContractId: tschannelContractId,
      data: data,
    });
    return result.data.success ? result.data.data : false;
  }

  public async tschannelSimpleSaleOfferCreate(name = ""): Promise<any> {
    const result = await marketapi.post("tschannelSimpleSaleOfferCreate", {
      name: name,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getOwnTariff(id): Promise<any> {
    const result = await marketapi.get("getOwnTariff/" + id);
    return result.data.success ? result.data.data : false;
  }

  public async saveProperty(objId, type, propname, value, lang = "") {
    const result = await marketapi.post("saveProperty", {
      id: objId,
      type: type,
      prop: propname,
      value: value,
      lang: lang,
    });
    return result.data.success ? result.data.data : false;
  }

  public async uploadPropertyData(type, objId, propname, value, lang) {
    const formData = new FormData();
    formData.set("dataType", type);
    formData.set("id", objId + "");
    formData.set("property", propname);
    formData.set("lang", lang);
    if (value.constructor.name == "FileList") {
      for (let i = 0, l = value.length; i < l; i++) {
        formData.append("value-" + i, value[i]);
      }
    } else {
      formData.append("value", value);
    }

    //console.log(file);
    const result = await marketapi.post("uploadPropertyData", formData);
    return result && result.data && result.data.success
      ? result.data.data
      : false;
  }

  public async dropProperty(
    dataType,
    itemId,
    propertyCode,
    lang = "none",
    propId = null,
  ) {
    const result = await marketapi.post("dropProperty", {
      dataType: dataType,
      id: itemId,
      property: propertyCode,
      lang: lang,
      propId: propId,
    });
    return result.data.success;
  }

  public async requestVerification(objId, type) {
    const result = await marketapi.post("requestVerification", {
      id: objId,
      type: type,
    });
    return result.data.success ? result.data.data : false;
  }
  public async cancelVerification(objId, type) {
    const result = await marketapi.post("cancelVerification", {
      id: objId,
      type: type,
    });
    return result.data.success ? result.data.data : false;
  }

  /* TSCopy && TSSignal */
  public async tscGetUserAccounts(): Promise<any> {
    const result = await marketapi.get("tscGetUserAccounts");
    return result.data.success ? result.data.data : false;
  }
  public async tscGetUserAgents(): Promise<any> {
    console.log("tscGetUserAgents");
    const result = await marketapi.get("tscGetUserAgents");
    return result.data.success ? result.data.data : false;
  }
  public async searchForeignListItems(
    dataType: string,
    propType: string,
    id: any,
    pattern: string,
  ): Promise<any> {
    const result = await marketapi.post("searchForeignListItems", {
      dataType: dataType,
      propType: propType,
      id: id,
      pattern: pattern,
    });
    return result.data.success ? result.data.data : false;
  }

  public async addForeignListItem(
    dataType: string,
    id: any,
    propType: string,
    addId: any,
  ) {
    const result = await marketapi.post("addForeignListItem", {
      dataType: dataType,
      property: propType,
      id: id,
      addId: addId,
    });
    return result.data.success ? result.data.data : false;
  }
  public async dropForeignListItem(
    dataType: string,
    id: any,
    propType: string,
    dropId: any,
  ) {
    const result = await marketapi.post("dropForeignListItem", {
      dataType: dataType,
      property: propType,
      id: id,
      dropId: dropId,
    });
    return result.data.success ? result.data.data : false;
  }

  public async offerGetSubscriptions(
    offerId: number,
    pagination: any,
    filter: any,
  ) {
    const result = await marketapi.post("offerGetSubscriptions", {
      offerId: offerId,
      pagination: pagination,
      filter: filter,
    });
    return result.data.success ? result.data.data : false;
  }

  public async tschannelConnectContractProvideDiscount(
    contractId: number,
    newPrice: any,
  ) {
    const result = await marketapi.post(
      "tschannelConnectContractProvideDiscount",
      {
        id: contractId,
        newPrice: newPrice,
      },
    );
    return result.data.success ? result.data.data : false;
  }

  public async search(
    dataType: any,
    propType: string,
    search_pattern: string,
    listingProps: any = null,
    pagination = null,
  ): Promise<any> {
    const result = await marketapi.post("search", {
      dataType: dataType,
      pagination: pagination,
      propType: propType,
      pattern: search_pattern,
      listingProps: listingProps,
    });
    return result.data.success ? result.data.data : false;
  }

  public async securityTagAdd(
    objectType: string,
    objectId: any,
    prop: string,
    tagId: number,
  ): Promise<any> {
    //console.log(tagId);
    const result = await marketapi.post("securityTagAdd", {
      id: tagId,
      objectType: objectType,
      prop: prop,
      objectId: objectId,
    });
    return result.data.success ? result.data.data : false;
  }

  public async securityTagAddNew(
    objectType: string,
    objectId: any,
    prop: string,
    name: string,
  ): Promise<any> {
    const result = await marketapi.post("securityTagAddNew", {
      name: name,
      objectType: objectType,
      prop: prop,
      objectId: objectId,
    });
    return result.data.success ? result.data.data : false;
  }

  public async securityTagRemove(
    objectType: string,
    objectId: any,
    prop: string,
    id: number,
  ): Promise<any> {
    const result = await marketapi.post("securityTagRemove", {
      id: id,
      prop: prop,
      objectType: objectType,
      objectId: objectId,
    });
    return result.data.success ? result.data.data : false;
  }

  public async searchSecurityTag(pattern: string): Promise<any> {
    const result = await marketapi.post("searchSecurityTag", {
      pattern: pattern,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getPublickProfile(id): Promise<any> {
    const result = await marketapi.get("getPublickProfile/" + id);
    return result.data.success ? result.data.data : false;
  }

  public async getContent(code): Promise<any> {
    const result = await marketapi.get("content/" + code);
    return result.data.success ? result.data.data : false;
  }
}

// export a singleton instance in the global namespace
export const tsMarketplaceService = TSMarketplaceService.Instance;
