const intervalMultipliers = {
  s: 1,
  m: 60,
  h: 3600,
  d: 86400,
  W: 604800,
  M: 18144000,
};

const intervals = [
  { value: 1, unit: "s", favorite: false, selected: false },
  { value: 5, unit: "s", favorite: false, selected: false },
  { value: 15, unit: "s", favorite: false, selected: false },
  { value: 30, unit: "s", favorite: false, selected: false },

  { value: 1, unit: "m", favorite: false, selected: false },
  { value: 3, unit: "m", favorite: false, selected: false },
  { value: 5, unit: "m", favorite: false, selected: false },
  { value: 15, unit: "m", favorite: false, selected: false },
  { value: 30, unit: "m", favorite: false, selected: false },
  { value: 45, unit: "m", favorite: false, selected: false },

  { value: 1, unit: "h", favorite: false, selected: false },
  { value: 2, unit: "h", favorite: false, selected: false },
  { value: 3, unit: "h", favorite: false, selected: false },
  { value: 4, unit: "h", favorite: false, selected: false },

  { value: 1, unit: "d", favorite: false, selected: false },
  { value: 1, unit: "W", favorite: false, selected: false },
  { value: 1, unit: "M", favorite: false, selected: false },
];

const intervalUnitsNames = {
  s: {
    ru: ["секунда", "секунды", "секунд"],
    en: ["second", "seconds", "seconds"],
  },
  m: {
    ru: ["минута", "минуты", "минут"],
    en: ["minute", "minutes", "minutes"],
  },
  h: {
    ru: ["час", "часа", "часов"],
    en: ["hour", "hours", "hours"],
  },
  d: {
    ru: ["день", "дня", "дней"],
    en: ["day", "days", "days"],
  },
  W: {
    ru: ["неделя", "недели", "недель"],
    en: ["week", "weeks", "weeks"],
  },
  M: {
    ru: ["месяц", "месяца", "месяцев"],
    en: ["month", "month", "month"],
  },
};

const intervalUnitsShortNames = {
  s: {
    ru: "c",
    en: "s",
  },
  m: {
    ru: "м",
    en: "m",
  },
  h: {
    ru: "ч",
    en: "h",
  },
  d: {
    ru: "Д",
    en: "D",
  },
  W: {
    ru: "Н",
    en: "W",
  },
  M: {
    ru: "М",
    en: "M",
  },
};

export {
  intervalMultipliers,
  intervals,
  intervalUnitsNames,
  intervalUnitsShortNames,
};
