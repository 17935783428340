<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="700px"
    style="width: auto; position: relative; bottom: 5px; opacity: 1"
  >
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" color="blue lighten-1" small class="helpIcon ml-1"
        >help</v-icon
      >
    </template>

    <v-card>
      <v-card-title
        :class="[
          titleColor +
            ' ' +
            titleTextColor +
            ' headline d-flex justify-space-between',
        ]"
        dark
        primary-title
      >
        <span>{{ $_getLocal(item.name) }}</span>
        <v-btn text dark @click.native="dialog = false" class="pa-0">
          <v-icon dark>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="body-2 pt-3 pb-4">
        <v-skeleton-loader
          :loading="loading"
          transition="fade-transition"
          height="250"
          type="article"
        >
          <div class="help-dialog">
            <vue-markdown :source="$_getLocal(item.text)"></vue-markdown>
          </div>
        </v-skeleton-loader>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="blue darken-1" text @click.native="dialog = false">{{
          $t("common.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { referenceapi } from "@/config";

export default {
  name: "helpDialog",
  data() {
    return {
      loading: true,
      dialog: false,
      item: {
        key: "",
        name: "",
        text: "",
      },
      noData: {
        key: "noData",
        name: {
          ru: "noData",
          en: "noData",
        },
        text: {
          ru: "noData",
          en: "noData",
        },
      },
    };
  },
  props: {
    dataKey: {
      type: String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "ts_blue",
    },
    titleTextColor: {
      type: String,
      default: "white--text",
    },
  },

  watch: {
    async dialog(val) {
      if (val == true) {
        await this.getItemFromApi();
      }
    },
  },

  methods: {
    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },

    async getItemFromApi() {
      this.loading = true;
      const result = await referenceapi.get(this.dataKey);
      if (result.status == 200 && typeof result.data) {
        this.item = result.data;
      } else {
        this.item = this.noData;
      }
      this.loading = false;
    },
  },
};
</script>

<style>
.helpIcon {
  transform: rotate(0deg);
  opacity: 0.6 !important;
}
.helpIcon:hover {
  transform: rotate(0deg);
  opacity: 1 !important;
}
</style>
