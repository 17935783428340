var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-md-editor',{class:[
      _vm.mode == 'editable' ? 'v-md-editable-mode' : '',
      _vm.small ? 'z-md-small' : '' ],style:(_vm.height > 0 ? { 'min-height': _vm.height + 'px' } : {}),attrs:{"disabled-menus":[],"mode":_vm.mode,"toolbar":_vm.customToolbar,"left-toolbar":_vm.toolbar},on:{"change":function (text) { return _vm.change(text); },"blur":function($event){return _vm.blur()},"save":function (text) { return _vm.save(text); }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),(_vm.mode == 'editable')?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"600","height":"600","max-width":"1024"},model:{value:(_vm.attachedImagesDialog),callback:function ($$v) {_vm.attachedImagesDialog=$$v},expression:"attachedImagesDialog"}},[_c('div',{staticClass:"white"},[_c('z-attachments-input',{attrs:{"value":_vm.attachedImages,"disabled":false,"baseURL":_vm.baseURL},on:{"upload":function (files) { return _vm.uploadAttachedImage(files); },"delete":function (file) { return _vm.deleteAttachedImage(file); },"select":function (imageData) { return _vm.insertImage(imageData); }}})],1)]):_vm._e(),(_vm.mode == 'editable')?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"750"},model:{value:(_vm.broadcastDialog),callback:function ($$v) {_vm.broadcastDialog=$$v},expression:"broadcastDialog"}},[_c('div',{staticClass:"white pa-2"},[(!_vm.broadcastLoading)?[(_vm.broadcasts && _vm.broadcasts.length > 0)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.broadcasts),function(broadcast,ind){return _c('div',{key:ind},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('div',{class:[
                  'broadcast-container pointer ma-2',
                  hover ? 'elevation-8' : '' ],style:({ width: '320px', height: 'auto' })},[_c('div',{staticClass:"grey--text text--darken-2 mb-2"},[_vm._v(" "+_vm._s(broadcast.contract.name)+" ")]),_c('broadcast-image',{attrs:{"broadcast":broadcast,"width":300,"height":250},on:{"update":function (b) {
                      broadcast.lastFrameTime = b.lastFrameTime;
                    }}}),_c('div',{staticClass:"caption grey--text text-lighten-1 text-right mt-1 align-self-end"},[_vm._v(" "+_vm._s(_vm.$ztime.localDateTime(broadcast.lastFrameTime))+" ")]),_c('div',{staticClass:"d-flex flex-row justify-space-around mt-2"},[_c('v-text-field',{attrs:{"label":"width","value":broadcast.insertWidth,"outlined":"","width":"150","dense":"","hide-details":"","type":"number","suffix":"px"},on:{"change":function (w) {
                        broadcast.insertWidth = w;
                        if (broadcast.linkDimensions) {
                          broadcast.insertHeight = Math.round(
                            broadcast.height * (w / broadcast.width)
                          );
                        }
                      }}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();broadcast.linkDimensions = !broadcast.linkDimensions}}},[_c('v-icon',[_vm._v(_vm._s(broadcast.linkDimensions ? "mdi mdi-link" : "mdi mdi-link-off"))])],1),_c('v-text-field',{attrs:{"label":"height","value":broadcast.insertHeight,"outlined":"","width":"150","dense":"","hide-details":"","type":"number","suffix":"px"},on:{"change":function (h) {
                        broadcast.insertHeight = h;
                        if (broadcast.linkDimensions) {
                          broadcast.insertWidth = Math.round(
                            broadcast.width * (h / broadcast.height)
                          );
                        }
                      }}})],1),_c('div',{staticClass:"mt-2 d-flex flex-row justify-end"},[_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.insertBroadcast(broadcast)}}},[_vm._v(_vm._s(_vm.$t("tsmarket.btn.insert")))])],1)],1)]}}],null,true)})],1)}),0):_c('v-alert',{staticClass:"ma-4",attrs:{"outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("tsplayer.descriptions.broadcasts-not-exists"))+" ")])]:_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card"}})],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }