var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('div',{style:({
      position: 'relative',
      lineHeight: '0px',
    })},[_c('img',{attrs:{"src":'data:image/png;base64,' + _vm.fileData,"width":"100%","max-width":_vm.broadcastWidth + 'px',"height":"auto"}}),(_vm.showDate)?_c('div',{staticClass:"caption pl-2 pr-2 black--text grey",style:({
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        opacity: 0.7,
      })},[_vm._v(" "+_vm._s(_vm.$ztime.localDateTime(_vm.lastFrameTime))+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }