<template>
  <v-app>
    <header-main></header-main>

    <v-main class="white">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <footer-main></footer-main>
    <ntf></ntf>
  </v-app>
</template>

<script>
import HeaderMain from "@/components/HeaderMain.vue";
import FooterMain from "@/components/FooterMain.vue";
import Notify from "@/components/Notify/Notify.vue";

export default {
  name: "App",

  components: {
    "header-main": HeaderMain,
    "footer-main": FooterMain,
    ntf: Notify,
  },

  created() {
    //console.log("this.$route.params.locale", this.$route.params.locale);
    // if (this.$route.params.locale) {
    //   switch (this.$route.params.locale) {
    //     case "ru":
    //       this.$i18n.locale = "ru-RU";
    //       break;
    //     case "en":
    //       this.$i18n.locale = "en-EN";
    //       break;
    //   }
    // }
    // console.log("point2", this.$route, this.$i18n.locale);
  },
};
</script>
