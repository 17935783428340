<template>
  <v-footer
    v-if="false"
    height="auto"
    app
    class="pa-0 grey lighten-2 d-flex flex-row flex-nowrap justify-start"
  >
    <router-link
      v-slot="{ isActive, navigate }"
      v-for="(item, i) in routes"
      :key="'footeritem' + i"
      :style="{ 'min-width': '60px', height: '60px', cursor: 'pointer' }"
      :to="{ name: item.name }"
    >
      <div
        :class="[
          isActive ? 'grey lighten-4' : '',
          'pa-1 pb-0 d-flex flex-column align-center justify-space-around',
        ]"
        @click.stop="navigate"
      >
        <v-icon :color="isActive ? 'black' : 'grey darken-1'" class="mb-0">{{
          item.icon
        }}</v-icon>
        <div class="text-caption mr-1 ml-1">{{ $t(item.title) }}</div>
      </div>
    </router-link>
    <!--
    <div id="hdeContainer">
      <div id="hdeBtn" @click="toggleChat()">chat</div>
      <div id="hdeBtn" @click="toggleLauncher()">launcher</div>
    </div>
    -->
  </v-footer>
</template>

<script>
import { find, filter, map } from "lodash";

export default {
  name: "FooterMain",
  data() {
    return {
      routes: [],
      chat: false,
      launcher: false,
    };
  },
  computed: {
    ver() {
      return process.env.VERSION;
    },
  },
  methods: {
    toggleChat() {
      this.chat = !this.chat;
      console.log("open fresh state = " + this.chat);
      window.FreshworksWidget(this.chat ? "open" : "close");
    },

    toggleLauncher() {
      this.chat = !this.chat;
      console.log("open fresh state = " + this.chat);
      window.FreshworksWidget(this.chat ? "show" : "hide", "launcher");
    },

    hdeOpen1() {
      //const scr = document.getElementById("hde-chat-widget");
      //scr.setAttribute("data-user-email", "borroda@yandex.ru");
      //data-user-email

      const hdeBtn = document.getElementById("hdeBtn");
      const isOpen = hdeBtn.classList.contains("_open");
      if (isOpen) {
        console.log("try to close");
        //this.hdeChatAdd();
        // закрываем
      } else {
        console.log("try to open");
        // открываем
        this.hdeChatAdd();
      }
      hdeBtn.classList.toggle("_open");
    },
    hdeChatAdd() {
      console.log("hdeChatAdd");

      let hde = document.createElement("script");
      var typeAttr = document.createAttribute("type");
      typeAttr.value = "text/javascript";
      hde.setAttributeNode(typeAttr);

      var srcAttr = document.createAttribute("src");
      srcAttr.value = "//cdn.helpdeskeddy.com/js/modules/chat_widget/hde.js";
      hde.setAttributeNode(srcAttr);

      document.body.appendChild(hde);
      /*
      
      let hde = document.createElement("script");

      var idAttr = document.createAttribute("id");
      idAttr.value = "hde-chat-widget";
      hde.setAttributeNode(idAttr);

      //hde.id = "hde-chat-widget";

      var typeAttr = document.createAttribute("type");
      typeAttr.value = "text/javascript";
      hde.setAttributeNode(typeAttr);

      //hde.type = "text/javascript";

      var langAttr = document.createAttribute("data-lang");
      langAttr.value = "ru";
      hde.setAttributeNode(langAttr);

      var hostAttr = document.createAttribute("data-host");
      //hostAttr.value = "loreal.helpdeskeddy.com";
      hostAttr.value = "tslab.helpdeskeddy.com";
      hde.setAttributeNode(hostAttr);

      // data-custom-container = "hdeContainer"
      // data-custom-button = "hdeBtn"
      var containerAttr = document.createAttribute("data-custom-container");
      containerAttr.value = "hdeContainer";
      hde.setAttributeNode(containerAttr);

      var emailAttr = document.createAttribute("data-user-email");
      emailAttr.value = "borroda@yandex.ru";
      hde.setAttributeNode(emailAttr);

      var btnAttr = document.createAttribute("data-custom-button");
      btnAttr.value = "hdeBtn";
      hde.setAttributeNode(btnAttr);

      //const btn = document.getElementById("hdeContainer");
      //console.log(btn);

      var srcAttr = document.createAttribute("src");
      srcAttr.value = "//cdn.helpdeskeddy.com/js/modules/chat_widget/hde.js";
      hde.setAttributeNode(srcAttr);

      document.body.appendChild(hde);
      */
      /*
       window.addEventListener('load', function() {
setTimeout(function() {
var $btn = jQuery('#chatContainer');jQuery('<script>', {
id: 'hde-chat-widget',
src: '//cdn.helpdeskeddy.com/js/modules/chat_widget/hde.js',
'data-lang': 'ru',
'data-host': 'loreal.helpdeskeddy.com',
'data-custom-container': 'chatContainer',
'data-custom-button': 'chatButton'
}).appendTo(jQuery('body'));$btn.show();$btn.on('click', function() {
$btn.toggleClass('_open');
});
}, 5000)
}); 
*/
    },
  },

  mounted() {
    //this.hdeChatAdd();
  },

  created() {
    const root = find(this.$router.options.routes, { name: "root" });
    //const rootLocale = find(root.children, { name: "root-locale" });
    const personal = find(root.children, { name: "personal" });
    const items = filter(personal.children, r => {
      return (
        r.meta &&
        r.meta.isShownInMenu &&
        r.meta.i18n &&
        (r.meta.allow ? this.$store.getters.roles.includes(r.meta.allow) : true)
      );
    });
    this.routes = map(items, i => {
      return {
        name: i.name,
        title: i.meta.i18n,
        icon: i.meta.icon ? i.meta.icon : "mdi-account",
      };
    });
  },
};
</script>
