const _messages = {
  __amount_exceeds__: {
    type: "error",
    title: {
      ru: "Превышение суммы.",
      en: "The amount exeeds.",
    },
    text: {
      ru:
        "Запрашиваемая сумма вывода превышает доступную сумму на вашем счете.",
      en: "The amount exceeds the available amount.",
    },
  },
  __amount_less_threshold___: {
    type: "error",
    title: {
      ru: "Сумма меньше минимально возможной.",
      en: "Еhe amount is less than the withdrawal threshold",
    },
    text: {
      ru: "Увеличьте сумму вывода до минимально возможной.",
      en: "Increase the withdrawal amount to the minimum possible.",
    },
  },
  __coin_required__: {
    type: "error",
    title: {
      ru: "Не выбрана монета.",
      en: "Coin not selected.",
    },
    text: {
      ru: "Необходимо выбрать монету.",
      en: "Coin is not selected.",
    },
  },
  __blockchain_required__: {
    type: "error",
    title: {
      ru: "Не выбрана сеть вывода.",
      en: "Blockchain is not selected.",
    },
    text: {
      ru: "Необходимо выбрать монету.",
      en: "",
    },
  },
  __copied_to_clipboard__: {
    type: "info", // тип сообщения. info / warning / error
    title: {
      ru: "Скопировано в буфер обмена.",
      en: "Copied to clipboard.",
    },
    text: {
      ru: "",
      en: "",
    },
  },
  __not_implemented__: {
    type: "error", // тип сообщения. info / warning / error
    title: {
      ru: "Не реализовано.",
      en: "Not implemented.",
    },
    text: {
      ru: "Реализация функционала ожидается в ближайших обновлениях.",
      en: "Awaiting for implementation.",
    },
  },

  __data_deleted__: {
    type: "warning",
    title: {
      ru: "Данные удалены.",
      en: "Data removed successfully.",
    },
    text: {
      ru: "",
      en: "",
    },
  },

  __saved_successfully__: {
    type: "info",
    title: {
      ru: "Данные сохранены.",
      en: "Saved successfully.",
    },
    text: {
      ru: "",
      en: "",
    },
  },
  __saved_fail__: {
    type: "error",
    title: {
      ru: "Данные не удалось сохранить.",
      en: "Saved fail.",
    },
    text: {
      ru: "",
      en: "",
    },
  },

  __out_receivers_license__: {
    type: "error",
    title: {
      ru: "Лицензия недостаточна.",
      en: "The license is insufficient.",
    },
    text: {
      ru:
        "Достигнуто максимальное количество активных Приемников для этого канала.",
      en:
        "The maximum number of active Receivers for this channel has been reached.",
    },
  },

  __payload_too_large__: {
    type: "error",
    title: {
      ru: "Файл не загружен.",
      en: "The file was'n uploaded.",
    },
    text: {
      ru:
        "Загружаемый файл слишком большой. Уменьшите его размер и попробуйте снова.",
      en: "The file is too large. You can reduce its size and try again.",
    },
  },

  __profile_edit_already_published_error__: {
    type: "error",
    title: {
      ru: "Опубликованный профиль не может быть отредактирован.",
      en: "The published profile cannot be edited",
    },
    text: {
      ru:
        "Отмените публикацию своего профиля для внесения изменений. По окончании редактирования вы сможете опубликовать его снова.",
      en:
        "Cancel the publication of your profile to make changes. When you finish editing, you can publish it again.",
    },
  },

  __unknown_error__: {
    type: "error",
    title: {
      ru: "Ошибка.",
      en: "Error.",
    },
    text: {
      ru:
        "Произошла непредвиденная ошибка. Повторите действие и, в случае повторения, сообщите службе технической поддержки.",
      en:
        "Something went wrong. Please try again or let our support know if the error persists.",
    },
  },
};
export { _messages };
