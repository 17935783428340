import {
  VuexModule,
  Module,
  Action,
  MutationAction,
  Mutation,
} from "vuex-module-decorators";

import { tsMarketplaceService } from "@/services/tsmarketplaceservice";

export interface TSPlayerState {
  _tsplayers: any[];
  _loading: boolean;
  loaded: boolean;
}

@Module({
  namespaced: true,
})
class TSPlayer extends VuexModule implements TSPlayerState {
  public _tsplayers = [];
  public _loading = false;
  public loaded = false;

  get tsplayers(): any[] {
    return this._tsplayers;
  }

  get loading(): boolean {
    return this._loading;
  }

  @Mutation
  public destroy() {
    this._tsplayers = [];
    this._loading = false;
    this.loaded = false;
  }

  @Mutation
  loadingStart() {
    this._loading = true;
  }

  @Mutation
  loadingEnd(tsplayers = null) {
    this._loading = false;
    this.loaded = tsplayers ? true : false;
    this._tsplayers = tsplayers;
  }

  @Action
  public async tsplayersFetch() {
    //this.context.commit("mutationName", payload) and this.context.getters["getterName"]
    if (!this.context.getters["loading"]) {
      this.context.commit("loadingStart");
      const result = await tsMarketplaceService.tsplayerGetList();
      if (result) {
        this.context.commit("loadingEnd", result);
      }
    }
  }
}

export default TSPlayer;
