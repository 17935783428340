<template>
  <v-dialog v-model="dialog" persistent hide-overlay>
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind:on="on" v-bind="attrs">
        <v-btn
          text
          icon
          :color="color"
          :disabled="disabled"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon small>{{ icon }}</v-icon>
        </v-btn>
      </slot>
    </template>

    <v-card class="z-confirm-dialog">
      <v-card-title :class="[titleColor, titleText, 'headline']">{{
        titleData
      }}</v-card-title>

      <v-card-text class="mt-2">
        <z-vmd-editor
          :height="250"
          :value="textData"
          :mode="'preview'"
          :small="small"
        ></z-vmd-editor>
      </v-card-text>

      <v-card-actions class="pb-3 pr-3">
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="dialog = false">{{
          cancelBtnText
        }}</v-btn>
        <v-btn
          :class="[titleColor, titleText, ' ml-4 ']"
          dark
          @click="confirm()"
          >{{ confirmBtnText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import { _request } from "@/utils/utils.ts";
import Vue from "vue";
import { tsMarketplaceService } from "@/services/tsmarketplaceservice";

export default Vue.extend({
  data() {
    return {
      dialog: false,
      loading: false,

      titleData: "",
      textData: "",

      item: {},
    };
  },
  props: {
    title: {
      type: String,
      default: "Вы уверены?",
    },
    text: {
      type: String,
      default: "Подтвердите, что хотите выполнить это действие!",
    },

    confirmBtnText: {
      type: String,
      default() {
        return this.$i18n.locale.substr(0, 2) == "ru"
          ? "Подтверждаю"
          : "Confirm";
      },
    },
    cancelBtnText: {
      type: String,
      default() {
        return this.$i18n.locale.substr(0, 2) == "ru" ? "Отмена" : "Cancel";
      },
    },

    icon: {
      type: String,
      default: "fas fa-trash",
    },
    color: {
      type: String,
      default: "red",
    },
    titleColor: {
      type: String,
      default: "red",
    },
    titleText: {
      type: String,
      default: "white--text",
    },
    content: {
      type: String,
      default: null,
    },

    onConfirm: {
      type: Function,
      default: () => {
        return 0;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    confirm() {
      this.dialog = false;
      this.$emit("onConfirm");
    },
  },

  async created() {
    if (this.content) {
      this.loading = true;
      const result = await tsMarketplaceService.getContent(this.content);
      if (result) {
        this.item = result;
        this.titleData = this.$_getLocal(this.item.name);
        this.textData = this.$_getLocal(this.item.content);
      } else {
        this.titleData = this.title;
        this.textData = this.text;
      }
      this.loading = false;
    } else {
      this.titleData = this.title;
      this.textData = this.text;
    }
  },
});
</script>

<style lang="scss"></style>
