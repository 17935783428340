import { Route } from "vue-router";
import { User } from "oidc-client";
import {
  VuexModule,
  Module,
  Action,
  Mutation,
  MutationAction,
} from "vuex-module-decorators";

import i18n from "@/i18n";
import { userManager } from "@/config";
import { referalService } from "@/services/referalservice";

export interface ClientState {
  user: User | null;
}

@Module
class Client extends VuexModule implements ClientState {
  public user: User | null = null;
  public referalDiscount: 0;

  @Mutation
  public setUser(user: User): void {
    this.user = user;
  }

  @Action({ rawError: true })
  public async signin(route?: Route) {
    try {
      const data = {
        route: route ? route.name : undefined,
        params: route ? route.params : undefined,
      };

      await userManager.signinRedirect({
        data,
        extraQueryParams: {
          ui_locales: i18n.locale,
        },
      });
    } catch (error) {
      //console.error("client signin", error);
    }
  }

  @Action({ rawError: true })
  public async signinProfile(): Promise<any> {
    try {
      const user = await userManager.signinSilent();
      this.context.commit("setUser", user);
      return user;
    } catch (e) {
      //console.error("signinProfile", e);
    }

    this.context.commit("setUser", null);
    return null;
  }

  @Action({ rawError: true })
  public async signinPopup(): Promise<void> {
    try {
      await userManager.signinPopup();
    } catch (error) {
      //console.error(error);
    }

    this.context.commit("setUser", null);
  }

  @Action({ rawError: true })
  public async removeUser(): Promise<void> {
    try {
      await userManager.removeUser();
    } catch (error) {
      //console.error(error);
    }

    this.context.commit("setUser", null);
  }

  @Action({ rawError: true })
  public async signout(): Promise<void> {
    try {
      await userManager.signoutRedirect({
        extraQueryParams: {
          ui_locales: i18n.locale,
        },
      });
    } catch (error) {
      //console.error(error);
    }

    this.context.commit("setUser", null);
  }

  //store.dispatch('checkUser')
  @Action({ rawError: true })
  public async checkUser(): Promise<boolean> {
    //console.log("isLoggedIn", this.context.getters.isLoggedIn);
    //console.log("isExpired", this.context.getters.isExpired);
    if (this.context.getters.isLoggedIn && !this.context.getters.isExpired) {
      return true;
    }

    let user: User = null;
    try {
      user = await userManager.getUser();
      if (user) {
        this.context.commit("setUser", user);
      }
    } catch (error) {
      //console.error("register", error);
    }
    //console.log(user);
    return user !== null;
  }

  @Action({ rawError: true })
  public async register(): Promise<boolean> {
    this.context.commit("setUser", null);
    let user: User = null;
    try {
      user = await userManager.getUser();
      if (user) {
        this.context.commit("setUser", user);
      }
    } catch (error) {
      //console.error("register", error);
    }

    return user !== null;
  }

  @MutationAction({
    mutate: ["referalDiscount"],
  })
  public async discountFetch() {
    if (!this.getters.isLoggedIn || this.getters.isExpired) {
      return { referalDiscount: 0 };
    }

    try {
      const result = await referalService.discountFetch();
      return { referalDiscount: result ? result : 0 };
    } catch (error) {
      //console.error("discount fetch", error);
    }
    return { referalDiscount: 0 };
  }

  get userInfo(): User {
    return this.user;
  }

  get userName(): string {
    if (this.user != null && !this.user.expired) {
      return this.user.profile.name;
    }
    return "";
  }

  get userEmail(): string {
    if (this.user != null && !this.user.expired) {
      return this.user.profile.email;
    }
    return "";
  }

  get userAvatarinitials(): string {
    let initials = "";
    if (this.user != null && !this.user.expired) {
      this.user.profile.name.split(" ").forEach(w => {
        initials += w[0] == " " ? "" : w[0];
      });
    }
    return initials;
  }

  get fullName(): string {
    if (this.user != null && !this.user.expired) {
      return `${this.user.profile.given_name} ${this.user.profile.family_name}`;
    }
    return "";
  }

  get isLoggedIn(): boolean {
    return this.user !== null;
  }

  get isTester(): boolean {
    return (
      this.user !== null &&
      this.user.profile &&
      this.user.profile.role &&
      this.user.profile.role.includes("test")
    );
  }

  get isExpired(): boolean {
    return this.user !== null ? this.user.expired : true;
  }

  get configAuthorization(): string {
    return this.user ? `${this.user.token_type} ${this.user.access_token}` : "";
  }

  get enterTime(): string {
    if (this.user != null && !this.user.expired) {
      const date = new Date(this.user.profile.auth_time);

      return date.toLocaleDateString();
    }

    return "";
  }

  get roles(): any {
    if (this.user !== null && !this.user.expired) {
      return this.user.profile.role ? this.user.profile.role : [];
    }

    return [];
  }

  get subjectId(): string {
    if (this.user !== null && !this.user.expired) {
      return this.user.profile.sub;
    }

    return "";
  }

  get discount(): number {
    return this.referalDiscount;
  }
}

export default Client;
