import Vue from "vue";
import Vuex, { createLogger } from "vuex";

import Client from "@/store/modules/client";
import Progress from "@/store/modules/progress";
import navObject from "@/store/modules/nav";
// import Logs from "@/store/modules/logs";
// import Rates from "@/store/modules/rates";
// import Agent from "@/store/modules/agent";
// import Faces from "@/store/modules/faces";
// import Points from "@/store/modules/points";
// import Accounts from "@/store/modules/accounts";
// import AccountFaces from "./modules/accountfaces";
// import ShopTariffs from "@/store/modules/shoptariffs";
// import AccountPoints from "@/store/modules/accountpoints";
// import SizePoints from "@/store/modules/sizepoints";
// import ColumnPoints from "@/store/modules/columnpoints";
// import SummaryPoints from "@/store/modules/summarypoints";
// import Contract from "@/store/modules/contract";
// import Contracts from "@/store/modules/contracts";
// import Agents from "@/store/modules/agents";
// import History from "@/store/modules/history";
// import Payment from "@/store/modules/payment";
// import Brokers from "@/store/modules/brokers";
// import Tariffs from "@/store/modules/tariffs";
// import Strategy from "@/store/modules/strategy";
// import Investors from "@/store/modules/investors";
// import Strategies from "@/store/modules/strategies";
// import Connection from "@/store/modules/connection";
// import Connections from "@/store/modules/connections";
// import Subscription from "@/store/modules/subscription";
// import Subscriptions from "@/store/modules/subscriptions";
import Preferences from "@/store/modules/preferences";
//import TSChannel from "@/store/modules/tschannel";
import TSWallet from "@/store/modules/tswallet";
import Offer from "@/store/modules/offer";
import TSPlayer from "@/store/modules/tsplayer";

// import createSignalWSPlugin from "./plugins/signals";
// import createNotificationWSPlugin from "./plugins/notifications";

//import Web3 from "@/store/modules/web3";

//import { isDebug } from "@/config";

Vue.use(Vuex);

// const notificationWSPlugin = createNotificationWSPlugin();
// const signalWSPlugin: any = createSignalWSPlugin();
const store = new Vuex.Store({
  modules: {
    Client,
    Progress,
    navObject,
    // Logs,
    // Rates,
    // Agent,
    // Agents,
    // Accounts,
    // AccountFaces,
    // AccountPoints,
    // Contract,
    // Contracts,
    // Faces,
    // Points,
    // History,
    // Brokers,
    // Tariffs,
    // Strategy,
    // Investors,
    // Strategies,
    // SizePoints,
    // ShopTariffs,
    // ColumnPoints,
    // SummaryPoints,
    // Subscription,
    // Subscriptions,
    // Connection,
    // Connections,
    Preferences,
    // Payment,
    //TSChannel,
    TSWallet,
    Offer,
    TSPlayer,
    //Web3,
  },
  //strict: isDebug,
  /*
  plugins: isDebug
    ? [createLogger({}), notificationWSPlugin, signalWSPlugin]
    : [notificationWSPlugin, signalWSPlugin],
    */
});

export default store;
