// This file is auto generated by build/build-entry.js
import Component from "./preview.vue";
import xss from "./utils/xss/index";
import Lang from "./lang/";
// font css
import "./assets/css/font.css";

const version = "1.7.11";

const install = Vue => {
  Vue.component(Component.name, Component);
};

Component.version = version;
Component.install = install;
Component.xss = xss;
Component.lang = Lang;

Component.use = function (optionsOrInstall, opt) {
  if (typeof optionsOrInstall === "function") {
    optionsOrInstall(Component, opt);
  } else {
    optionsOrInstall.install(Component, opt);
  }

  return Component;
};

export default Component;
