<template>
  <div>
    <v-md-editor
      v-model="text"
      @change="text => change(text)"
      @blur="blur()"
      @save="text => save(text)"
      :disabled-menus="[]"
      :mode="mode"
      :toolbar="customToolbar"
      :left-toolbar="toolbar"
      :style="height > 0 ? { 'min-height': height + 'px' } : {}"
      :class="[
        mode == 'editable' ? 'v-md-editable-mode' : '',
        small ? 'z-md-small' : '',
      ]"
    ></v-md-editor>

    <v-dialog
      v-if="mode == 'editable'"
      v-model="attachedImagesDialog"
      transition="dialog-bottom-transition"
      width="600"
      height="600"
      max-width="1024"
    >
      <div class="white">
        <z-attachments-input
          :value="attachedImages"
          @upload="files => uploadAttachedImage(files)"
          @delete="file => deleteAttachedImage(file)"
          @select="imageData => insertImage(imageData)"
          :disabled="false"
          :baseURL="baseURL"
        ></z-attachments-input>
      </div>
    </v-dialog>

    <v-dialog
      v-if="mode == 'editable'"
      v-model="broadcastDialog"
      transition="dialog-bottom-transition"
      width="750"
    >
      <div class="white pa-2">
        <template v-if="!broadcastLoading">
          <div
            v-if="broadcasts && broadcasts.length > 0"
            class="d-flex flex-wrap"
          >
            <div v-for="(broadcast, ind) in broadcasts" :key="ind">
              <v-hover v-slot="{ hover }">
                <div
                  :style="{ width: '320px', height: 'auto' }"
                  :class="[
                    'broadcast-container pointer ma-2',
                    hover ? 'elevation-8' : '',
                  ]"
                >
                  <div class="grey--text text--darken-2 mb-2">
                    {{ broadcast.contract.name }}
                  </div>
                  <broadcast-image
                    :broadcast="broadcast"
                    :width="300"
                    :height="250"
                    @update="
                      b => {
                        broadcast.lastFrameTime = b.lastFrameTime;
                      }
                    "
                  >
                  </broadcast-image>
                  <div
                    class="caption grey--text text-lighten-1 text-right mt-1 align-self-end"
                  >
                    {{ $ztime.localDateTime(broadcast.lastFrameTime) }}
                  </div>
                  <div class="d-flex flex-row justify-space-around mt-2">
                    <v-text-field
                      label="width"
                      :value="broadcast.insertWidth"
                      outlined
                      width="150"
                      dense
                      hide-details
                      type="number"
                      suffix="px"
                      @change="
                        w => {
                          broadcast.insertWidth = w;
                          if (broadcast.linkDimensions) {
                            broadcast.insertHeight = Math.round(
                              broadcast.height * (w / broadcast.width),
                            );
                          }
                        }
                      "
                    ></v-text-field>
                    <v-btn
                      icon
                      @click.stop="
                        broadcast.linkDimensions = !broadcast.linkDimensions
                      "
                      ><v-icon>{{
                        broadcast.linkDimensions
                          ? "mdi mdi-link"
                          : "mdi mdi-link-off"
                      }}</v-icon></v-btn
                    >
                    <v-text-field
                      label="height"
                      :value="broadcast.insertHeight"
                      outlined
                      width="150"
                      dense
                      hide-details
                      type="number"
                      suffix="px"
                      @change="
                        h => {
                          broadcast.insertHeight = h;
                          if (broadcast.linkDimensions) {
                            broadcast.insertWidth = Math.round(
                              broadcast.width * (h / broadcast.height),
                            );
                          }
                        }
                      "
                    ></v-text-field>
                  </div>
                  <div class="mt-2 d-flex flex-row justify-end">
                    <v-btn
                      color="success"
                      small
                      @click.stop="insertBroadcast(broadcast)"
                      >{{ $t("tsmarket.btn.insert") }}</v-btn
                    >
                  </div>
                </div>
              </v-hover>
            </div>
          </div>
          <v-alert
            v-else
            outlined
            type="warning"
            prominent
            border="left"
            class="ma-4"
          >
            {{ $t("tsplayer.descriptions.broadcasts-not-exists") }}
          </v-alert>
        </template>
        <v-skeleton-loader
          v-else
          class="mx-auto"
          type="card"
        ></v-skeleton-loader>
      </div>
    </v-dialog>
  </div>
</template>

<script>
//import i18n from "@/i18n";
import Vue from "vue";

import VMdEditor from "./v-md-editor/codemirror-editor";
import "@kangc/v-md-editor/lib/style/codemirror-editor.css";
import vuepressTheme from "./v-md-editor/theme/vuepress";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";

import createTipPlugin from "./v-md-editor/plugins/tip/index";
//import createTipPlugin from "@/components/markdown/plugins/tip/index";
import "@kangc/v-md-editor/lib/plugins/tip/tip.css";
VMdEditor.use(createTipPlugin());

import createEmojiPlugin from "./v-md-editor/plugins/emoji/index";
import "./v-md-editor/plugins/emoji/emoji.css";
VMdEditor.use(createEmojiPlugin());

import broadcastImageVue from "@/components/TSPlayer/broadcast-image.vue";

import Prism from "prismjs";

// Resources for the codemirror editor
import Codemirror from "codemirror";
// mode
import "codemirror/mode/markdown/markdown";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/vue/vue";
// edit
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/edit/matchbrackets";
// placeholder
import "codemirror/addon/display/placeholder";
// active-line
import "codemirror/addon/selection/active-line";
// scrollbar
import "codemirror/addon/scroll/simplescrollbars";
import "codemirror/addon/scroll/simplescrollbars.css";
// style
import "codemirror/lib/codemirror.css";

VMdEditor.Codemirror = Codemirror;

VMdEditor.use(vuepressTheme, {
  Prism,
  config: {
    attrs: {
      allowedAttributes: [],
    },
  },
});
import ruRu from "./ru.ts";
VMdEditor.lang.use("ru-RU", ruRu);
import zAttachmentsInput from "@/components/controls/z-attachments-input.vue";
import { tsMarketplaceService } from "@/services/tsmarketplaceservice";
import { editor_mediaInsertMaxWidth } from "@/config";
Vue.use(VMdEditor);

export default Vue.extend({
  name: "z-vmd-editor",
  data() {
    return {
      text: "",
      changeCounter: 0,
      attachedImagesDialog: false,
      broadcastDialog: false,
      broadcasts: [],
      editor: null,
      broadcastLoading: false,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "editable",
    },
    toolbar: {
      type: String,
      default:
        "h bold italic strikethrough | ul ol | link | hr code table tipToolbar emoji | broadcastToolBar attachedImagesToolBar | save",
    },
    // "bold italic strikethrough | ul ol | hr emoji | save ",
    height: {
      type: Number,
      default: 0,
    },
    baseURL: {
      // адрес для приложенных картинок, если используется сервис отличный от маркетплейса
      type: String,
      default: process.env.VUE_APP_MARKETPLACE_PICTURES_BASE,
    },
    attachedImages: {
      type: Array,
      default: () => {
        return null;
      },
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change() {
      //console.log(this.changeCounter);
      if (this.changeCounter > 0) this.$emit("change", this.text);
      this.changeCounter++;
    },
    blur() {
      console.log("blur");
      this.$emit("blur", this.text);
    },
    save() {
      this.$emit("save", this.text);
    },

    deleteAttachedImage(file) {
      this.$emit("deleteAttachedImage", file);
    },

    uploadAttachedImage(files) {
      this.$emit("uploadAttachedImage", files);
    },

    insertImage(imageData) {
      const image = imageData;
      const baseURL = this.baseURL;
      function insert(selected) {
        return {
          text:
            "\n![" +
            image.name +
            "](" +
            baseURL +
            image.url +
            ")" +
            (image.width ? " {width=" + image.width + "}" : ""),
          selected: null,
        };
      }
      this.editor.insert(insert);
      this.attachedImagesDialog = false;
    },

    insertBroadcast(broadcastData) {
      const broadcast = broadcastData;
      const width = broadcastData.insertWidth
        ? broadcastData.insertWidth
        : editor_mediaInsertMaxWidth;
      const k =
        broadcastData.insertWidth && broadcastData.insertWidth > width
          ? broadcastData.insertWidth / width
          : 1;
      const height = broadcastData.insertHeight
        ? broadcastData.insertHeight / k
        : width * 0.75;
      function insert(selected) {
        return {
          text:
            "\n/b/" +
            broadcast.id +
            " {width=" +
            width +
            "}" +
            " {height=" +
            height +
            "}",
          selected: null,
        };
      }
      this.editor.insert(insert);
      this.broadcastDialog = false;
    },

    attachedImagesDialogOpen(editor) {
      this.editor = editor;
      this.attachedImagesDialog = true;
    },

    broadcastDialogOpen(editor) {
      this.editor = editor;
      this.broadcastDialog = true;
    },

    async fetchBroadcasts() {
      this.broadcastLoading = true;
      const result = await tsMarketplaceService.broadcastGetList();
      if (result) {
        result.items.forEach(b => {
          b.insertWidth = b.width * 1;
          b.insertHeight = b.height * 1;
          b.linkDimensions = true;
        });
        this.broadcasts = result.items;
      }
      this.broadcastLoading = false;
    },
  },
  watch: {
    async broadcastDialog(val) {
      if (val) {
        await this.fetchBroadcasts();
      }
    },
    value(newVal) {
      this.text = newVal;
    },
  },
  created() {
    this.text = this.value;
  },
  computed: {
    customToolbar: function () {
      var attachedImagesDialogOpen = this.attachedImagesDialogOpen;
      var broadcastDialogOpen = this.broadcastDialogOpen;
      return {
        broadcastToolBar: {
          icon: "mdi mdi-broadcast",
          title: "Broadcast",
          action(editor) {
            broadcastDialogOpen(editor);
          },
        },
        attachedImagesToolBar: {
          icon: "mdi mdi-image",
          title: "AttachedImages",
          action(editor) {
            attachedImagesDialogOpen(editor);
          },
        },
        tipToolbar: {
          title: this.$t("vmdeditor.term-accent"),
          icon: "v-md-icon-tip",
          menus: [
            {
              name: "accent",
              text: this.$t("vmdeditor.term-accent"),
              action(editor) {
                editor.insert(function (selected) {
                  const prefix = "::: tip Title\n";
                  const suffix = "\n:::";
                  const placeholder = "text"; //this.$t("vmdeditor.placeholder-text");
                  const content = selected || placeholder;

                  return {
                    text: `${prefix}${content}${suffix}`,
                    selected: content,
                  };
                });
              },
            },
            {
              title: "warning",
              text: this.$t("vmdeditor.term-warning"),
              action(editor) {
                editor.insert(function (selected) {
                  const prefix = "::: warning Title\n";
                  const suffix = "\n:::";
                  const placeholder = "text"; //this.$t("vmdeditor.placeholder-text");
                  const content = selected || placeholder;

                  return {
                    text: `${prefix}${content}${suffix}`,
                    selected: content,
                  };
                });
              },
            },
            {
              title: "danger",
              text: this.$t("vmdeditor.term-danger"),
              action(editor) {
                editor.insert(function (selected) {
                  const prefix = "::: danger Title\n";
                  const suffix = "\n:::";
                  const placeholder = "text"; //this.$t("vmdeditor.placeholder-text");
                  const content = selected || placeholder;

                  return {
                    text: `${prefix}${content}${suffix}`,
                    selected: content,
                  };
                });
              },
            },
            {
              title: "details",
              text: this.$t("vmdeditor.term-details"),
              action(editor) {
                editor.insert(function (selected) {
                  const prefix = "::: details Title\n";
                  const suffix = "\n:::";
                  const placeholder = "text"; //this.$t("vmdeditor.placeholder-text");
                  const content = selected || placeholder;

                  return {
                    text: `${prefix}${content}${suffix}`,
                    selected: content,
                  };
                });
              },
            },
          ],
        },
      };
    },
  },
  components: {
    "z-attachments-input": zAttachmentsInput,
    "broadcast-image": broadcastImageVue,
  },
});
</script>

<style lang="scss">
.v-md-editable-mode {
  min-height: 500px;
}

.broadcast-container {
  border-radius: 4px;
  border: 1px #b4b4b4 solid;
  padding: 8px 6px 6px 6px;
  position: relative;
}

.z-md-small .vuepress-markdown-body h1 {
  font-size: 16px !important;
  margin: 7px 0px 3px 0px !important;
}
.z-md-small .vuepress-markdown-body ul {
  margin: 3px 0px 3px 0px !important;
}
.z-md-small .vuepress-markdown-body p {
  margin: 3px 0px 3px 0px !important;
}
.z-md-small .vuepress-markdown-body {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
