import Vue, { CreateElement, VNode } from "vue";
import * as Oidc from "oidc-client";

// import "@/registerServiceWorker";
import "@/plugins/highcharts";
import "@/plugins/clipboard";

import "@/styles/controls.css";

import i18n from "@/i18n";
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import { config } from "vuex-module-decorators";

import intervalControl from "@/components/controls/intervalControl.vue";
import zValueControl from "@/components/controls/z-value.vue";
import zform from "@/components/zform.vue";
import helpDialog from "@/components/dialogs/help-dialog.vue";
import pagePath from "@/components/pagePath.vue";
import vueMarkdown from "@/components/vuemarkdown.vue";
import confirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import fileInput from "@/components/controls/z-file-input.vue";

import contractStatusControl from "@/components/controls/contract-status-control.vue";
import zTextToggle from "@/components/controls/z-text-toggle.vue";
import zFavMenuItem from "@/components/controls/z-fav-menu-item.vue";

import zFilterCombobox from "@/components/controls/z-filter-combobox.vue";
import zCombobox from "@/components/controls/z-combobox.vue";

import VMdEditor from "@/components/markdown/v-md-editor.vue";

import linkShareControl from "@/components/linkShareControl.vue";

import lightweightChartPreview from "@/components/TradingView/lightweight-chart-preview.vue";

import { EventBus } from "@/config";
Vue.prototype.$notify = event => {
  EventBus.$emit("notify", event);
};

//import SignalRVue from "signalr-vue";

//import { isDebug } from "@/config";

//import VMdEditor from "@/markdown/VMdEditor";
//Vue.use(VMdEditor);

import ztime from "@/utils/ztime";
Vue.prototype.$ztime = ztime;

import { debounce, throttle } from "lodash";
Vue.prototype.$debounce = debounce;
Vue.prototype.$throttle = throttle;

import { _getLocal } from "@/utils/utils";
Vue.prototype.$_getLocal = _getLocal;

Oidc.Log.logger = window.console;
Oidc.Log.level = Oidc.Log.WARN;

// enable devtools
//Vue.config.devtools = isDebug;
//Vue.config.productionTip = !isDebug;

// Set rawError to true by default on all @Action decorators
config.rawError = true;

Vue.component("interval-control", intervalControl);
Vue.component("z-form", zform);
Vue.component("help-dialog", helpDialog);
Vue.component("page-path", pagePath);
Vue.component("vue-markdown", vueMarkdown);
Vue.component("confirm-dialog", confirmDialog);
Vue.component("z-value", zValueControl);
Vue.component("z-file-input", fileInput);

Vue.component("z-text-toggle", zTextToggle);
Vue.component("z-fav-menu-item", zFavMenuItem);

Vue.component("z-vmd-editor", VMdEditor);

Vue.component("contract-status", contractStatusControl);
Vue.component("link-share-control", linkShareControl);

Vue.component("lightweight-chart-preview", lightweightChartPreview);

Vue.component("z-filter-combobox", zFilterCombobox);
Vue.component("z-combobox", zCombobox);

store.dispatch("preferencesLoad");

new Vue({
  i18n,
  router,
  store,
  vuetify,
  el: "#app",
  render: (h: CreateElement): VNode => h(App),
}).$mount("#app");
