var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-1",style:({
    'border-radius': '50%',
    'background-color': _vm.hover
      ? _vm.dark
        ? 'rgba(255, 255, 255, 0.15)'
        : 'rgba(0, 0, 0, 0.03)'
      : 'transparent',
  }),on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){$event.stopPropagation();_vm.active = !_vm.active}}},[(_vm.active)?_c('v-icon',{attrs:{"color":_vm.dark
        ? 'yellow lighten-3'
        : _vm.hover
        ? 'yellow darken-2'
        : 'yellow darken-1'}},[_vm._v("mdi-star")]):_c('v-icon',{attrs:{"color":_vm.hover ? (_vm.dark ? 'grey lighten-2' : 'grey') : 'transparent'}},[_vm._v("mdi-star-outline")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }