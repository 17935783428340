export default {
  svg: [],
  altGlyph: [],
  altGlyphDef: [],
  altGlyphItem: [],
  animate: [],
  animateColor: [],
  animateMotion: [],
  animateTransform: [],
  circle: [],
  clipPath: [],
  "color-profile": [],
  cursor: [],
  "definition-src": [],
  defs: [],
  desc: [],
  ellipse: [],
  feBlend: [],
  feColorMatrix: [],
  feComponentTransfer: [],
  feComposite: [],
  feConvolveMatrix: [],
  feDiffuseLighting: [],
  feDisplacementMap: [],
  feDistantLight: [],
  feFlood: [],
  feFuncA: [],
  feFuncB: [],
  feFuncG: [],
  feFuncR: [],
  feGaussianBlur: [],
  feImage: [],
  feMerge: [],
  feMergeNode: [],
  feMorphology: [],
  feOffset: [],
  fePointLight: [],
  feSpecularLighting: [],
  feSpotLight: [],
  feTile: [],
  feTurbulence: [],
  filter: [],
  font: [],
  foreignObject: [],
  g: [],
  glyph: [],
  glyphRef: [],
  hkern: [],
  image: [],
  line: [],
  linearGradient: [],
  marker: [],
  mask: [],
  metadata: [],
  "missing-glyph": [],
  mpath: [],
  path: [],
  pattern: [],
  polygon: [],
  polyline: [],
  radialGradient: [],
  rect: [],
  set: [],
  stop: [],
  style: [],
  switch: [],
  symbol: [],
  text: [],
  textPath: [],
  title: [],
  tref: [],
  tspan: [],
  use: [],
  view: [],
  vkern: [],
};
