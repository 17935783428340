import Vue from "vue";
//import { reject, slice, isEmpty } from "lodash";
//import { pathToRegexp, compile } from "path-to-regexp";
import VueRouter, { RouteConfig } from "vue-router";

import i18n, { loadLocaleMessage } from "@/i18n";
import store from "@/store";
//import { locales } from "@/config";
import Shell from "@/components/Shell.vue";
import Dashboard from "@/views/Dashboard.vue";
import { setTitle /*, loadLocaleMessage */ } from "@/utils";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // eslint-disable-next-line prettier/prettier
  { //name: "signin-redirect",
    name: "signin-redirect",
    path: "/signin-redirect",
    component: () => import("@/views/SigninRedirect.vue"),
    meta: {
      isShownInMenu: false,
    },
  },
  {
    name: "root",
    path: "/:locale?",
    redirect: { name: "market-showcase-tschannel" },
    component: () =>
      import(/* webpackChunkName: "tsverse" */ "@/views/Home.vue"),
    children: [
      /* market-showcase */
      {
        name: "market-showcase",
        path: "showcase",
        component: () =>
          import(/* webpackChunkName: "tschannel" */ "@/components/Shell.vue"),
        meta: {
          i18n: "tsmarket.nav.showcase",
          title: "tsmarket.nav.showcase",
          isShownInMenu: true,
          requiresAuth: false,
        },
        children: [
          /* market-showcase-tschannel */
          {
            name: "market-showcase-tschannel",
            path: "robots",
            component: () =>
              import(
                /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Showcase/tschannel-card-list.vue"
              ),
            meta: {
              i18n: "tsmarket.nav.showcase",
              title: "tsmarket.nav.showcase",
              isShownInMenu: true,
              requiresAuth: false,
              shareLink: true,
            },
            children: [
              {
                name: "market-product-tschannel",
                path: ":tariffId",
                component: () =>
                  import(
                    /* webpackChunkName: "tschannel"*/ "@/views/TSMarket/Product/channel-connect-tariff.vue"
                  ),
                meta: {
                  isShownInMenu: false,
                  highlightItemName: "channels-list",
                  requiresAuth: false,
                  shareLink: true,
                },
              },
            ],
          },
          /* market-showcase-tslab */
          {
            name: "market-showcase-tslab",
            path: "tslab",
            component: () =>
              import(
                /* webpackChunkName: "tsmarketplace" */ "@/views/TSMarket/Showcase/tslab.vue"
              ),
            meta: {
              i18n: "tsmarket.nav.tslabShowcase",
              title: "tsmarket.nav.tslabShowcase",
              isShownInMenu: true,
              requiresAuth: false,
              shareLink: true,
            },
            children: [
              {
                name: "market-product-tslab",
                path: ":id",
                component: () =>
                  import(
                    /* webpackChunkName: "tsmarketplace" */ "@/views/TSMarket/Product/data-provider.vue"
                  ),
                meta: {
                  i18n: "tsmarket.nav.tslabProductTitle",
                  title: "tsmarket.nav.tslabProductTitle",
                  isShownInMenu: false,
                  requiresAuth: false,
                  shareLink: true,
                },
              },
            ],
          },
        ],
      },
      /* community */
      {
        name: "community",
        path: "community",
        //redirect: { name: "profile-about" },
        component: () =>
          import(
            /* webpackChunkName: "community" */ "@/views/Profile/publick-list.vue"
          ),
        meta: {
          i18n: "tsmarket.terms.community",
          title: "tsmarket.terms.community",
          isShownInMenu: true,
          shareLink: true,
        },
        children: [
          {
            name: "publick-profile",
            path: ":id/:tab?",
            // redirect: {
            //   name: "publick-profile-about",
            // },
            component: () =>
              import(
                /* webpackChunkName: "community" */ "@/views/Profile/publick-profile.vue"
              ),
            meta: {
              i18n: "profile.terms.profile",
              title: "profile.terms.profile",
              isShownInMenu: true,
              shareLink: true,
            },
            // children: [
            //   {
            //     name: "publick-profile-about",
            //     path: "about",
            //     component: () =>
            //       import(
            //         /* webpackChunkName: "community" */ "@/views/Profile/publick-profile-about.vue"
            //       ),
            //     meta: {
            //       i18n: "profile.terms.profile",
            //       title: "profile.terms.profile",
            //       isShownInMenu: true,
            //       shareLink: true,
            //     },
            //   },
            //   {
            //     name: "publick-profile-showcase",
            //     path: "showcase",
            //     component: () =>
            //       import(
            //         /* webpackChunkName: "community" */ "@/views/Profile/publick-profile-showcase.vue"
            //       ),
            //     meta: {
            //       i18n: "profile.terms.profile",
            //       title: "profile.terms.profile",
            //       isShownInMenu: true,
            //       shareLink: true,
            //     },
            //   },
            // ],
          },
        ],
      },
      /* personal */
      {
        name: "personal",
        path: "personal",
        redirect: {
          name: "tschannels",
          params: { locale: i18n.locale },
        },
        component: Shell,
        meta: {
          i18n: "navigation.portfolio.title",
          requiresAuth: true,
        },
        children: [
          /* tsplayer */
          {
            name: "tsplayer",
            path: "tsplayer",
            component: () =>
              import(
                /* webpackChunkName: "tsplayer" */ "@/views/TSPlayer/tsplayer-home.vue"
              ),
            meta: {
              i18n: "tsmarket.titles.my-agents",
              title: "tsmarket.titles.my-agents",
              allow: "test",
              isShownInMenu: false,
              requiresAuth: true,
            },
            children: [
              {
                name: "tsplayer-agent",
                path: ":apiKey",
                component: () =>
                  import(
                    /* webpackChunkName: "tsplayer" */ "@/views/TSPlayer/tsplayer-agent.vue"
                  ),
                meta: {
                  isShownInMenu: false,
                  requiresAuth: true,
                },
              },
            ],
          },
          /* broadcasts */
          {
            name: "tsplayer-broadcasts",
            path: "broadcasts",
            component: () =>
              import(
                /* webpackChunkName: "broadcasts" */ "@/views/TSPlayer/broadcasts.vue"
              ),
            meta: {
              i18n: "tsmarket.titles.broadcasts",
              title: "tsmarket.titles.broadcasts",
              allow: "test",
              isShownInMenu: false,
              requiresAuth: true,
            },
            children: [
              {
                name: "tsplayer-broadcast",
                path: ":broadcastId",
                component: () =>
                  import(
                    /* webpackChunkName: "broadcasts" */ "@/views/TSPlayer/broadcast.vue"
                  ),
                meta: {
                  isShownInMenu: false,
                  requiresAuth: true,
                },
              },
            ],
          },
          /* my account / wallet */
          {
            name: "my-account",
            path: "account",
            redirect: {
              name: "my-account-info",
              params: { locale: i18n.locale },
            },
            component: () =>
              import(
                /* webpackChunkName: "account" */ "@/views/MyAccount/main.vue"
              ),
            meta: {
              i18n: "navigation.portfolio.account",
              title: "navigation.portfolio.account",
              isShownInMenu: false,
              requiresAuth: true,
            },
            children: [
              {
                name: "my-account-info",
                path: "info",
                component: () => import("@/views/MyAccount/info.vue"),
                meta: {
                  isShownInMenu: false,
                  highlightItemName: "my-account",
                  requiresAuth: true,
                },
              },
              {
                name: "my-account-replenishment",
                path: "replenishment",
                component: () => import("@/views/MyAccount/replenishment.vue"),
                meta: {
                  isShownInMenu: false,
                  highlightItemName: "my-account",
                  requiresAuth: true,
                },
              },
              {
                name: "my-account-statement",
                path: "statement",
                component: () => import("@/views/MyAccount/statement.vue"),
                meta: {
                  isShownInMenu: false,
                  highlightItemName: "my-account",
                  requiresAuth: true,
                },
              },
              {
                name: "my-account-withdraw",
                path: "withdraw",
                component: () => import("@/views/MyAccount/withdraw.vue"),
                meta: {
                  isShownInMenu: false,
                  highlightItemName: "my-account",
                  requiresAuth: true,
                },
              },
            ],
          },

          // {
          //   name: "showcase-strategy",
          //   path: "showcase/:id",
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "showcase" */ "@/views/StrategyInfo.vue"
          //     ),
          //   meta: {
          //     i18n: "navigation.portfolio.showcase",
          //     title: "navigation.portfolio.showcase",
          //     allow: "test",
          //     isShownInMenu: false,
          //     requiresAuth: true,
          //   },
          // },
          // {
          //   name: "dashboard",
          //   path: "dashboard",
          //   component: Dashboard,
          //   redirect: { name: "showcase" },
          //   meta: {
          //     i18n: "navigation.portfolio.dashboard",
          //     title: "navigation.portfolio.dashboard",
          //     icon: "mdi-home",
          //     isShownInMenu: false,
          //     favorite: true,
          //     favoriteAllow: true,
          //     allow: "test",
          //   },
          // },

          // {
          //   name: "portfolio-agents-old",
          //   path: "agents",
          //   component: () =>
          //     import(/* webpackChunkName: "agents" */ "@/views/Agents.vue"),
          //   meta: {
          //     i18n: "navigation.portfolio.agents",
          //     title: "navigation.portfolio.agents",
          //     icon: "mdi-robot-outline",
          //     allow: "test",
          //     isShownInMenu: false,
          //     favorite: true,
          //     favoriteAllow: true,
          //   },
          // },

          // {
          //   name: "portfolio-agent",
          //   path: "agents/:id",
          //   redirect: { name: "agent-analitics" },
          //   component: () =>
          //     import(/* webpackChunkName: "agent" */ "@/views/Agent.vue"),
          //   meta: {
          //     /* i18n: "navigation.portfolio.agent", */
          //     /* title: "navigation.portfolio.agent", */
          //     isShownInMenu: false,
          //     allow: "test",
          //   },
          //   children: [
          //     {
          //       name: "agent-analitics",
          //       path: "analitics",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "agent" */ "@/views/Agent/Analytics.vue"
          //         ),
          //       meta: {
          //         isShownInMenu: false,
          //         allow: "test",
          //       },
          //     },
          //     {
          //       name: "agent-logs",
          //       path: "logs",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "agent" */ "@/views/Agent/Logs.vue"
          //         ),
          //       meta: {
          //         isShownInMenu: false,
          //         allow: "test",
          //       },
          //     },
          //   ],
          // },
          // {
          //   name: "portfolio-strategies",
          //   path: "strategies",
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "strategies" */ "@/views/Strategies.vue"
          //     ),
          //   meta: {
          //     i18n: "navigation.portfolio.strategies",
          //     title: "navigation.portfolio.strategies",
          //     icon: "mdi-chart-line-stacked",
          //     isShownInMenu: false,
          //     favorite: true,
          //     favoriteAllow: true,
          //     allow: "test",
          //   },
          // },
          // {
          //   name: "portfolio-strategy",
          //   path: "strategies/:id",
          //   redirect: { name: "strategy-analitics" },
          //   component: () =>
          //     import(/* webpackChunkName: "strategy" */ "@/views/Strategy.vue"),
          //   meta: {
          //     isShownInMenu: false,
          //     allow: "test",
          //   },
          //   children: [
          //     {
          //       name: "strategy-analitics",
          //       path: "analitics",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "strategy" */ "@/views/Strategy/Analytics.vue"
          //         ),
          //       meta: {
          //         isShownInMenu: false,
          //         allow: "test",
          //       },
          //     },
          //     {
          //       name: "strategy-settings",
          //       path: "settings",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "strategy" */ "@/views/Strategy/Settings.vue"
          //         ),
          //       meta: {
          //         isShownInMenu: false,
          //         allow: "test",
          //       },
          //     },
          //     {
          //       name: "strategy-investors",
          //       path: "investors",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "strategy" */ "@/views/Strategy/Investors.vue"
          //         ),
          //       meta: {
          //         isShownInMenu: false,
          //         allow: "test",
          //       },
          //     },
          //   ],
          // },
          // {
          //   name: "portfolio-subscriptions",
          //   path: "subscriptions",
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "subscriptions" */ "@/views/Subscriptions.vue"
          //     ),
          //   meta: {
          //     i18n: "navigation.portfolio.subscriptions",
          //     title: "navigation.portfolio.subscriptions",
          //     icon: "mdi-finance",
          //     isShownInMenu: false,
          //     favorite: true,
          //     favoriteAllow: true,
          //     allow: "test",
          //   },
          // },
          // {
          //   name: "portfolio-subscription",
          //   path: "subscriptions/:id",
          //   redirect: { name: "subscription-statistics" },
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "subscription" */ "@/views/Subscription.vue"
          //     ),
          //   meta: {
          //     //i18n: "navigation.portfolio.subscription",
          //     //title: "navigation.portfolio.subscription",
          //     allow: "test",
          //     isShownInMenu: false,
          //   },
          //   children: [
          //     {
          //       name: "subscription-settings",
          //       path: "settings",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "subscription" */ "@/views/Subscription/Settings.vue"
          //         ),
          //       meta: {
          //         //h1: "navigation.portfolio.subscription.settings",
          //         //i18n: "navigation.portfolio.subscription.settings",
          //         allow: "test",
          //         isShownInMenu: false,
          //       },
          //     },
          //     {
          //       name: "subscription-statistics",
          //       path: "statistics",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "subscription" */ "@/views/Subscription/Statistics.vue"
          //         ),
          //       meta: {
          //         //h1: "navigation.portfolio.subscription.statistics",
          //         //i18n: "navigation.portfolio.subscription.statistics",
          //         allow: "test",
          //         isShownInMenu: false,
          //       },
          //     },
          //     {
          //       name: "subscription-logs",
          //       path: "logs",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "subscription" */ "@/views/Subscription/Logs.vue"
          //         ),
          //       meta: {
          //         //h1: "navigation.portfolio.subscription.logs",
          //         //i18n: "navigation.portfolio.subscription.logs",
          //         allow: "test",
          //         isShownInMenu: false,
          //       },
          //     },
          //     {
          //       name: "subscription-orders",
          //       path: "orders",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "subscription" */ "@/views/Subscription/Orders.vue"
          //         ),
          //       meta: {
          //         //h1: "navigation.portfolio.subscription.orders",
          //         //i18n: "navigation.portfolio.subscription.orders",
          //         allow: "test",
          //         isShownInMenu: false,
          //       },
          //     },
          //     {
          //       name: "subscription-trades",
          //       path: "trades",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "subscription" */ "@/views/Subscription/Trades.vue"
          //         ),
          //       meta: {
          //         //h1: "navigation.portfolio.subscription.trades",
          //         //i18n: "navigation.portfolio.subscription.trades",
          //         allow: "test",
          //         isShownInMenu: false,
          //       },
          //     },
          //   ],
          // },

          // {
          //   name: "portfolio-connections",
          //   path: "connections",
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "connections" */ "@/views/Connections.vue"
          //     ),
          //   meta: {
          //     i18n: "navigation.portfolio.connections",
          //     title: "navigation.portfolio.connections",
          //     icon: "mdi-connection",
          //     isShownInMenu: false,
          //     favorite: false,
          //     favoriteAllow: true,
          //     allow: "test",
          //   },
          // },

          {
            name: "tschannels",
            path: "tschannels",
            //redirect: { name: "tschannels-list" },
            component: () =>
              import(
                /* webpackChunkName: "tschannel" */ "@/views/TSChannel/main.vue"
              ),
            meta: {
              i18n: "tschannel.name",
              title: "tschannel.nav.list",
              icon: "mdi-lan-connect",
              isShownInMenu: true,
              favorite: true,
              favoriteAllow: true,
            },
            children: [
              /* tschannels-list */
              {
                name: "tschannels-list",
                path: "tschannels",
                component: () =>
                  import(
                    /* webpackChunkName: "tschannel" */ "@/views/TSMarket/TSChannel/tschannelsList.vue"
                  ),
                meta: {
                  i18n: "tschannel.nav.list",
                  title: "tschannel.nav.list",
                  isShownInMenu: true,
                },
                children: [
                  {
                    name: "tschannel-main-page",
                    path: ":contractId",
                    component: () =>
                      import(
                        /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Contracts/contract.vue"
                      ),
                    meta: {
                      isShownInMenu: false,
                      highlightItemName: "channels-list",
                    },
                  },
                ],
              },

              /* tschannel-offers-list */
              {
                name: "tschannel-offers-list",
                path: "offers",
                component: () =>
                  import(
                    /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Product/offersList.vue"
                  ),
                meta: {
                  i18n: "tschannel.nav.list",
                  title: "tschannel.nav.list",
                  isShownInMenu: true,
                },
                children: [
                  {
                    name: "tschannel-offer",
                    path: ":offerId",
                    //redirect: { name: "tschannel-offer-main" },
                    component: () =>
                      import(
                        /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Product/offer.vue"
                      ),
                    meta: {
                      isShownInMenu: false,
                    },
                    // children: [
                    //   {
                    //     name: "tschannel-offer-main",
                    //     path: "main",
                    //     component: () =>
                    //       import(
                    //         /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Product/offer-main.vue"
                    //       ),
                    //     meta: {
                    //       isShownInMenu: false,
                    //     },
                    //   },
                    //   {
                    //     name: "tschannel-offer-description",
                    //     path: "description",
                    //     component: () =>
                    //       import(
                    //         /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Product/offer-description.vue"
                    //       ),
                    //     meta: {
                    //       isShownInMenu: false,
                    //     },
                    //   },
                    //   {
                    //     name: "tschannel-offer-files",
                    //     path: "files",
                    //     component: () =>
                    //       import(
                    //         /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Product/offer-files.vue"
                    //       ),
                    //     meta: {
                    //       isShownInMenu: false,
                    //     },
                    //   },
                    //   {
                    //     name: "tschannel-offer-reference",
                    //     path: "reference",
                    //     component: () =>
                    //       import(
                    //         /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Product/offer-reference.vue"
                    //       ),
                    //     meta: {
                    //       isShownInMenu: false,
                    //     },
                    //   },
                    //   {
                    //     name: "tschannel-offer-subscribers",
                    //     path: "subscribers",
                    //     component: () =>
                    //       import(
                    //         /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Product/offer-subscribers.vue"
                    //       ),
                    //     meta: {
                    //       isShownInMenu: false,
                    //     },
                    //   },
                    // ],
                  },
                ],
              },

              /* receivers-list */
              {
                name: "receivers-list",
                path: "receivers",
                component: () =>
                  import(
                    /* webpackChunkName: "tschannel" */ "@/views/TSMarket/TSChannel/receiversList.vue"
                  ),
                meta: {
                  i18n: "tschannel.nav.receiverslist",
                  title: "tschannel.nav.receiverslist",
                  isShownInMenu: true,
                },
              },

              /* tschannel-subscriptions-list */
              {
                name: "tschannel-subscriptions-list",
                path: "tschannel-subscriptions-list",
                component: () =>
                  import(
                    /* webpackChunkName: "tsmarketplace" */ "@/views/TSMarket/TSChannel/TSchannelSubscriptionsList.vue"
                  ),
                meta: {
                  i18n: "tsmarket.nav.tschannel-connect-contracts-list",
                  title: "tsmarket.nav.tschannel-connect-contracts-list",
                  isShownInMenu: true,
                  requiresAuth: true,
                },
                children: [
                  {
                    name: "tschannel-connect-contract",
                    path: ":contractId",
                    component: () =>
                      import(
                        /* webpackChunkName: "tsmarketplace"  "@/components/Shell.vue"*/
                        /* webpackChunkName: "tsmarketplace" */ "@/views/TSMarket/tschannelConnectContract/contract.vue"
                      ),
                    meta: {
                      i18n: "tsmarket.nav.tschannel-connect-contract",
                      title: "tsmarket.nav.tschannel-connect-contract",
                      isShownInMenu: false,
                      parent: "market-contracts",
                    },
                  },
                ],
              },
            ],
          },
          {
            name: "tslab",
            path: "tslab",
            redirect: { name: "market-showcase-tslab" },
            component: () =>
              import(
                /* webpackChunkName: "tsmarketplace" */ "@/views/TSMarket/main.vue"
              ),
            meta: {
              i18n: "tsmarket.nav.tslab",
              title: "tsmarket.nav.tslab",
              icon: "mdi-desktop-classic", //"mdi-store",
              isShownInMenu: true,
              favorite: true,
              favoriteAllow: true,
              requiresAuth: true,
            },
            children: [
              {
                name: "tslab-contracts",
                path: "licenses",
                component: () =>
                  import(
                    /* webpackChunkName: "tsmarketplace" */ "@/views/TSMarket/TSLab/list.vue"
                  ),
                meta: {
                  i18n: "tsmarket.nav.myTSLabContracts",
                  title: "tsmarket.nav.myTSLabContracts",
                  isShownInMenu: true,
                  requiresAuth: true,
                },
                children: [
                  {
                    name: "tslab-contract",
                    path: ":contractId",
                    component: () =>
                      import(
                        /* webpackChunkName: "tsmarketplace" */ "@/views/TSMarket/Contracts/contract.vue"
                      ),
                    meta: {
                      i18n: "tsmarket.nav.tslabShowcase",
                      title: "tsmarket.nav.tslabShowcase",
                      isShownInMenu: false,
                      parent: "market-contracts",
                    },
                  },
                ],
              },
            ],
          },
          /* reports */
          {
            name: "reports",
            path: "reports",
            redirect: { name: "market-contracts" },
            component: () =>
              import(
                /* webpackChunkName: "tsmarketplace" */ "@/views/TSMarket/main.vue"
              ),
            meta: {
              i18n: "tsmarket.nav.reports",
              title: "tsmarket.nav.reports",
              icon: "mdi-file-chart-outline", //"mdi-store",
              isShownInMenu: true,
              favorite: true,
              favoriteAllow: true,
              requiresAuth: true,
            },
            children: [
              {
                name: "market-contracts",
                path: "contracts",
                component: () =>
                  import(
                    /* webpackChunkName: "tsmarketplace" */ "@/views/TSMarket/Contracts/list.vue"
                  ),
                meta: {
                  i18n: "tsmarket.nav.myContracts",
                  title: "tsmarket.nav.myContracts",
                  isShownInMenu: true,
                  requiresAuth: true,
                },
              },
            ],
          },
          // {
          //   name: "portfolio",
          //   path: "portfolio",
          //   redirect: { name: "portfolio-accounts" },
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "tscopy" */ "@/views/TSMarket/main.vue"
          //     ),
          //   meta: {
          //     i18n: "tsmarket.nav.portfolio",
          //     title: "tsmarket.nav.portfolio",
          //     icon: "mdi-briefcase-variant-outline",
          //     isShownInMenu: false,
          //     favorite: true,
          //     favoriteAllow: true,
          //     isHidden: true,
          //   },
          //   children: [
          //     {
          //       name: "portfolio-accounts",
          //       path: "accounts",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "tscopy" */ "@/views/TSCopy/accountsList.vue"
          //         ),
          //       meta: {
          //         i18n: "tsmarket.nav.portfolio-accounts",
          //         title: "tsmarket.nav.portfolio-accounts",
          //         icon: "mdi-finance",
          //         isShownInMenu: true,
          //         favorite: true,
          //         favoriteAllow: true,
          //       },
          //     },
          //     {
          //       name: "portfolio-agents",
          //       path: "agents",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "tscopy" */ "@/views/TSCopy/agent/list.vue"
          //         ),
          //       meta: {
          //         i18n: "tsmarket.nav.portfolio-agents",
          //         title: "tsmarket.nav.portfolio-agents",
          //         icon: "mdi-finance",
          //         isShownInMenu: true,
          //         favorite: true,
          //         favoriteAllow: true,
          //       },
          //     },
          //   ],
          // },

          // {
          //   name: "tschannels",
          //   path: "tschannels",
          //   redirect: { name: "tschannels-list" },
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "tschannel" */ "@/views/TSChannel/main.vue"
          //     ),
          //   meta: {
          //     i18n: "tschannel.name",
          //     title: "tschannel.nav.list",
          //     icon: "mdi-lan-connect",
          //     isShownInMenu: true,
          //     favorite: true,
          //     favoriteAllow: true,
          //   },
          //   children: [
          //     {
          //       name: "tschannels-list",
          //       path: "tschannels",
          //       component: () =>
          //         import(
          //           /* webpackChunkName: "tschannel" */ "@/views/TSMarket/TSChannel/tschannelsList.vue"
          //         ),
          //       meta: {
          //         i18n: "tschannel.nav.list",
          //         title: "tschannel.nav.list",
          //         isShownInMenu: true,
          //       },
          //       children: [
          //         {
          //           name: "tschannel-main-page",
          //           path: "/portfolio/tschannels/tschannels/:contractId",
          //           component: () =>
          //             import(
          //               /* webpackChunkName: "tschannel" */ "@/views/TSMarket/Contracts/contract.vue"
          //             ),
          //           meta: {
          //             isShownInMenu: false,
          //             highlightItemName: "channels-list",
          //           },
          //         },
          //       ],
          //     },
          //   ],
          // },

          /* referal-programs-list */
          {
            name: "referal-programs-list",
            path: "referal",
            component: () =>
              import(
                /* webpackChunkName: "referal" */ "@/views/Referal/programs.vue"
              ),
            meta: {
              i18n: "referal.nav.my-programs-list",
              title: "referal.nav.my-programs-list",
              isShownInMenu: true,
            },
            children: [],
          },

          /* profile */
          {
            name: "profile",
            path: "my-profile",
            redirect: { name: "profile-about" },
            component: () =>
              import(
                /* webpackChunkName: "my-profile" */ "@/views/Profile/main.vue"
              ),
            meta: {
              i18n: "profile.terms.profile",
              title: "profile.terms.profile",
              isShownInMenu: true,
            },
            children: [
              {
                name: "profile-settings",
                path: "settings",
                component: () =>
                  import(
                    /* webpackChunkName: "my-profile" */ "@/views/Profile/settings.vue"
                  ),
                meta: {
                  i18n: "profile.terms.main-settings",
                  title: "profile.terms.main-settings",
                  isShownInMenu: true,
                },
              },
              {
                name: "profile-about",
                path: "about",
                component: () =>
                  import(
                    /* webpackChunkName: "my-profile" */ "@/views/Profile/about.vue"
                  ),
                meta: {
                  i18n: "profile.terms.about",
                  title: "profile.terms.about",
                  isShownInMenu: true,
                },
              },
              // {
              //   name: "statement-vidgets",
              //   path: "statement-vidgets",
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "profile" */ "@/views/Profile/statement-vidgets.vue"
              //     ),
              //   meta: {
              //     i18n: "profile.terms.statement-vidgets",
              //     title: "profile.terms.statement-vidgets",
              //     isShownInMenu: true,
              //   },
              // },
              {
                name: "profile-statement",
                path: "statement/:id",
                component: () =>
                  import(
                    /* webpackChunkName: "my-profile" */ "@/views/Profile/statement.vue"
                  ),
                meta: {
                  i18n: "profile.terms.statement-vidgets",
                  title: "profile.terms.statement-vidgets",
                  isShownInMenu: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  /*
  {
    name: "home",
    path: "/home",
    beforeEnter: () => {
      window.location.href = process.env.VUE_APP_HOME;
    },
    meta: {
      isShownInMenu: false,
    },
  },
  */
  {
    name: "login",
    path: "/login",
  },
  {
    name: "logout",
    path: "/logout",
    beforeEnter: async () => {
      await store.dispatch("signout");
    },
    meta: {
      isShownInMenu: false,
    },
  },
  {
    name: "manage",
    path: "/manage",
    beforeEnter: () => {
      window.location.href = `${process.env.VUE_APP_ACCOUNT_BASE}/${process.env.VUE_APP_ACCOUNT_MANAGE}`;
    },
    meta: {
      isShownInMenu: false,
    },
  },
  {
    name: "pay",
    path: "/pay",
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      requiresAuth: false,
      i18n: "errors.not_found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.onReady(() => {
  loadLocaleMessage(i18n.locale);
  setTitle(router.currentRoute);
});

router.beforeEach(async (to, from, next) => {
  //console.log("router.beforeEach", to);

  if (to.params.locale) {
    switch (to.params.locale) {
      case "ru":
        i18n.locale = "ru-RU";
        break;
      case "en":
        i18n.locale = "en-EN";
        break;
    }
    loadLocaleMessage(i18n.locale);
  } else {
    switch (i18n.locale) {
      case "ru-RU":
        to.params.locale = "ru";
        break;
      case "en-EN":
      default:
        to.params.locale = "en";
        break;
    }
    next(to);
  }

  const isHidden: boolean = to.matched.some(record => record.meta.isHidden);
  if (isHidden) {
    next({ name: "root" });
  }
  const getters: any = store.getters as any;
  const isAuthenticated: boolean = getters.isLoggedIn && !getters.isExpired;
  const isNeedLogout: boolean = isAuthenticated && to.name === "login";
  const isNeedLogin: boolean = to.matched.some(
    record => record.meta.requiresAuth,
  );

  if (to.name === "pay") {
    window.location.href = to.params.link;
  } else if (to.name === "home") {
    window.open(process.env.VUE_APP_HOME);
  } else if (to.name === "manage") {
    // управление аккаунтом в SSO
    window.location.href = `${process.env.VUE_APP_ACCOUNT_BASE}/${process.env.VUE_APP_ACCOUNT_MANAGE}`;
  } else if (!isAuthenticated && to.name === "login") {
    await store.dispatch("signin");
  } else if (to.name === "logout") {
    await store.dispatch("signout");
  } else if (isNeedLogin) {
    if (!isAuthenticated) {
      await store.dispatch("signin", to);
    } else if (isNeedLogout) {
      next({ name: "logout" });
    } else {
      next();
    }
  } else {
    next();
  }
});

/* router.beforeEach(async (to, from, next) => {
  const regexp = pathToRegexp(`/(${locales.join("|")})/(.*)`);
  if (regexp.test(to.fullPath)) {
    if (to.params.locale && i18n.locale !== to.params.locale) {
      i18n.locale = to.params.locale;

      setTitle(to);

      loadLocaleMessage(i18n.locale);
    }
  } else {
    const regexpParams = pathToRegexp(`/:locale(${locales.join("|")})/(.*)`);
    const items = regexpParams.exec(to.fullPath);

    const toPath = compile("/:segments+");
    if (!items) {
      const pathPieces = reject(to.fullPath.split("/"), isEmpty);

      if (pathPieces.length > 0) {
        const path = toPath({ segments: [i18n.locale, ...pathPieces] });
        next(path);
      } else {
        const path = toPath({ segments: [i18n.locale] });
        next(path);
      }
    } else {
      const segments = slice(items, to.params.locale ? 2 : 1);
      const path = toPath({ segments: [i18n.locale, ...segments] });
      next(path);
    }
  }

  next();
}); */

export default router;
