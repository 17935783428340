import { referalapi /*, isDebug */ } from "@/config";

//import moment from "moment";
//import { find } from "lodash";

class ReferalService {
  private static instance: ReferalService;

  public static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this.instance || (this.instance = new this());
  }

  public async getReferalPrograms(): Promise<any> {
    const result = await referalapi.get("getReferalPrograms");
    return result.data.success ? result.data.data : false;
  }

  public async createToken(newTokenProgramId, newTokenName, newTokenProfit) {
    const result = await referalapi.post("createToken", {
      referalProgramId: newTokenProgramId,
      name: newTokenName,
      profit: newTokenProfit,
    });
    return result.data.success ? result.data.data : false;
  }

  public async tokenRename(id, name) {
    const result = await referalapi.post("tokenRename", {
      id: id,
      name: name,
    });
    return result.data.success ? result.data.data : false;
  }

  public async tokenRemove(id) {
    const result = await referalapi.post("tokenRemove", {
      id: id,
    });
    return result.data.success ? result.data.data : false;
  }

  public async discountFetch() {
    const result = await referalapi.get("discount");
    return result.data.success ? result.data.data : false;
  }

  /*
  public async referal(token) {
    const result = await referalapi.post("referal", {
      ref: token,
    });
    return result.data.success ? result.data.data : false;
  }
  */
}

// export a singleton instance in the global namespace
export const referalService = ReferalService.Instance;
