export const attrWhiteList = ["style", "align", "class", "id"];

export const prefixAttrWhiteList = ["data-"];

export const tags = {
  input: ["type"],
  ol: ["reversed", "start", "type"],
  button: ["type"],
  summary: [],
  iframe: [
    "src",
    "width",
    "height",
    "broadcastid",
    "frameborder",
    "scrolling",
    "class",
    "id",
    "name",
  ],
};
