<template>
  <div>
    <div
      ref="chartContainer"
      :style="{ width: width + 'px', height: height + 'px' }"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";
import { createChart } from "lightweight-charts";

export default Vue.extend({
  name: "lightweight-chart-preview",
  data() {
    return {
      chart: null,
    };
  },
  props: {
    series: {
      type: Array,
      default: () => {
        return [];
        /*
        return [
          { time: "2018-12-22", value: 32.51 },
          { time: "2018-12-23", value: 31.11 },
          { time: "2018-12-24", value: 27.02 },
          { time: "2018-12-25", value: 27.32 },
          { time: "2018-12-26", value: 25.17 },
          { time: "2018-12-27", value: 28.89 },
          { time: "2018-12-28", value: 25.46 },
          { time: "2018-12-29", value: 23.92 },
          { time: "2018-12-30", value: 22.68 },
          { time: "2018-12-31", value: 22.67 },
        ];
        */
      },
    },
    width: {
      type: Number,
      default: 210,
    },
    height: {
      type: Number,
      default: 35,
    },
  },
  methods: {
    createChart() {
      console.log("createChart", this.$refs.chartContainer);
      this.chart = createChart(this.$refs.chartContainer, {
        width: this.width,
        height: this.height,
        leftPriceScale: { visible: false },
        rightPriceScale: { visible: false },
        timeScale: {
          visible: false,
          borderVisible: false,
          barSpacing:
            this.series.length > 0
              ? this.width / this.series.length
              : this.width,
        },
      });
      const areaSeries = this.chart.addAreaSeries();
      areaSeries.setData(this.series);
    },
  },
  watch: {
    data(oldVal, newVal) {
      if (!oldVal.length && newVal.length) {
        //this.createChart();
      }
    },
  },
  mounted() {
    this.createChart();
  },
  computed: {},
});
</script>

<style lang="sass"></style>
