import "moment/locale/ru";
import moment from "moment";
import axios from "axios";
import { Route } from "vue-router";
import { getCookie, setCookie } from "tiny-cookie";

import i18n, { CookieLangName } from "@/i18n";

export function setTitle(to: Route) {
  document.title = process.env.VUE_APP_TITLE;
  const routeTitle = to.meta.title
    ? to.meta.title
    : to.meta.i18n
    ? to.meta.i18n
    : null;
  const title = to.meta.title
    ? i18n.t(to.meta.title).toString()
    : to.meta.i18n
    ? i18n.t(to.meta.i18n).toString()
    : null;
  if (title !== routeTitle) {
    document.title = title;
  }
}

/*
function setCookieLang(val: string) {
  const langFromCookie = getCookie(CookieLangName) as string;
  if (langFromCookie) {
    setCookie(CookieLangName, `c=${val}|uic=${val}`, {
      domain: ".tslab.pro",
    });
  }
}

export function loadLocaleMessage(locale: string) {
  try {
    const isDebug: boolean = process.env.NODE_ENV !== "production";

    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `${process.env.VUE_APP_HOME}${process.env.VUE_APP_RESOURCES}lang/${
        locale === "ru" ? "ru-RU" : "en-US"
      }.json?${isDebug ? Date.now() : process.env.VERSION}`,
    );
    xhr.onloadend = () => {
      const json = JSON.parse(xhr.responseText);

      axios.defaults.headers.common["Accept-Language"] = locale;
      i18n.setLocaleMessage(i18n.locale, json);

      setCookieLang(i18n.locale);

      moment.locale(i18n.locale);
    };
    xhr.send();
  } catch (error) {
    console.error(error);
  }
}
*/

export function toTimestamp(value: string) {
  const date = new Date(value);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date.getTime();
}
