import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface navObjectState {
  navObjName: string;
  navObjId: any;
}

@Module({
  namespaced: true,
})
class navObject extends VuexModule implements navObjectState {
  public navObjName = null;
  public navObjId = null;

  get navObjectName(): string {
    //console.log(this.navObjName);
    return this.navObjName;
  }

  @Mutation
  public updateNavObject(navObject: any): void {
    this.navObjName = navObject.name ? navObject.name : null;
    this.navObjId = navObject.id ? navObject.id : null;
  }

  @Action
  public setNavObject(navObject: any) {
    this.context.commit("updateNavObject", navObject);
  }

  @Action
  public dropNavObject() {
    this.context.commit("updateNavObject", {});
  }
}

export default navObject;
