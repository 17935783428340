var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-app-bar',{staticClass:"grey darken-4",attrs:{"dense":"","flat":"","app":"","clipped-left":_vm.$vuetify.breakpoint.lgAndUp}},[_c('router-link',{staticClass:"d-flex flex-row align-center pointer mr-3",attrs:{"tag":"div","to":{ name: 'root', params: { locale: _vm.$i18n.locale.substring(0, 2) } }}},[_c('v-img',{staticClass:"flex-grow-0",attrs:{"contain":"","height":"37","width":"37","src":"/tsverse-points-logo-3.svg"}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"yellow--text1 grey--text text--lighten-2 font-weight-bold ml-2"},[_vm._v(" TSVerse ")]):_vm._e()],1),(!_vm.detailsMenu || (_vm.isLoggedIn && _vm.$vuetify.breakpoint.smAndUp))?_c('v-btn',{style:({ 'text-transform': 'none', 'font-weight': 400 }),attrs:{"dark":"","to":{
        name: 'market-showcase-tschannel',
        params: { locale: _vm.$i18n.locale.substring(0, 2) },
      }}},[_vm._v(_vm._s(_vm.$t("tsmarket.terms.trading-robots")))]):_vm._e(),(!_vm.detailsMenu || (_vm.isLoggedIn && _vm.$vuetify.breakpoint.smAndUp))?_c('v-btn',{staticClass:"mr-3",style:({ 'text-transform': 'none', 'font-weight': 400 }),attrs:{"text":"","dark":"","to":{
        name: 'market-showcase-tslab',
        params: { locale: _vm.$i18n.locale.substring(0, 2) },
      }}},[_vm._v(_vm._s(_vm.$t("tsmarket.terms.tslab-licenses")))]):_vm._e(),(!_vm.detailsMenu || (_vm.isLoggedIn && _vm.$vuetify.breakpoint.smAndUp))?_c('v-divider',{attrs:{"vertical":"","dark":""}}):_vm._e(),(!_vm.detailsMenu && _vm.isLoggedIn)?_c('div',{staticClass:"d-flex flex-row"},[_c('v-menu',{attrs:{"bottom":"","nudge-top":"-30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",style:({ 'text-transform': 'none', 'font-weight': 400 }),attrs:{"text":"","dark":"","dense":""}},on),[_vm._v(_vm._s(_vm.$t("tsmarket.titles.traders-cabinet"))),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,57177338)},[_c('div',{staticClass:"white"},[_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[(_vm.isLoggedIn)?_c('v-list-item',{attrs:{"to":{
                name: 'tschannels-list',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tschannel.nav.list")))])],1)],1):_vm._e(),(_vm.isLoggedIn)?_c('v-list-item',{attrs:{"to":{
                name: 'receivers-list',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tschannel.nav.receiverslist")))])],1)],1):_vm._e(),_c('v-divider'),(_vm.isLoggedIn)?_c('v-list-item',{attrs:{"to":{
                name: 'tschannel-offers-list',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.nav.my-sales")))])],1)],1):_vm._e(),_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{attrs:{"to":{
                name: 'tsplayer-broadcasts',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsplayer.titles.broadcasts")))])],1)],1),_c('v-list-item',{attrs:{"to":{
                name: 'tsplayer',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.titles.my-agents")))])],1)],1)],1)],1)]),(_vm.isLoggedIn)?_c('v-menu',{attrs:{"bottom":"","nudge-top":"-30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 mr-2",style:({ 'text-transform': 'none', 'font-weight': 400 }),attrs:{"text":"","dark":"","dense":""}},on),[_vm._v(_vm._s(_vm.$t("tsmarket.terms.my-purchases"))),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,526659113)},[_c('div',{staticClass:"white"},[_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[_c('v-list-item',{attrs:{"to":{
                name: 'tschannel-subscriptions-list',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.terms.trading-robots")))])],1)],1),_c('v-list-item',{attrs:{"to":{
                name: 'tslab-contracts',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.nav.myTSLabContracts")))])],1)],1)],1)],1)]):_vm._e()],1):_vm._e(),_c('v-spacer'),(!_vm.detailsMenu || (_vm.isLoggedIn && _vm.$vuetify.breakpoint.smAndUp))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openTSChannelDocs()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-help-circle-outline")])],1):_vm._e(),(_vm.isLoggedIn)?_c('div',{staticClass:"d-flex flex-row align-center"},[(!_vm.detailsMenu)?_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({style:({ 'text-transform': 'none', 'font-weight': 400 }),attrs:{"dark":"","outlined":"","color":"grey lighten-2","small":""}},on),[_vm._v(" "+_vm._s(_vm.walletAmount)+" "+_vm._s(_vm.walletCurrency)+" ")])]}}],null,false,3382837769)},[_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"exact":"","to":{
                name: 'my-account',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',{staticClass:"pl-4"},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('div',[_c('div',{staticClass:"caption grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.$t("tsmarket.terms.wallet-amount"))+": ")]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.walletAmount))]),_vm._v(" "+_vm._s(_vm.walletCurrency)+" ")])]),(_vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-0 ml-3 mr-1",attrs:{"small":"","icon":"","dense":"","elevation":"0"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.walletFetch()}}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}],null,false,3574604930)},[_c('span',[_vm._v("Обновить баланс")])]):_vm._e()],1)])],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"to":{
                name: 'my-account-replenishment',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',{staticClass:"pl-4"},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("wallet.nav.replenishmentTab")))])],1)],1),_c('v-list-item',{attrs:{"to":{
                name: 'my-account-withdraw',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',{staticClass:"pl-4"},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("wallet.nav.withdrawTab")))])],1)],1),_c('v-list-item',{attrs:{"to":{
                name: 'my-account-statement',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-content',{staticClass:"pl-4"},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("wallet.nav.statementTab")))])],1)],1)],1)],1)],1):_vm._e(),(_vm.isLoggedIn)?_c('v-menu',{attrs:{"bottom":"","nudge-width":"250","nudge-top":"-30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"ml-3 pointer",attrs:{"color":"white","size":"32"}},on),[_c('span',{staticClass:"font-weight-black blue--text text--darken-2"},[_vm._v(_vm._s(_vm.userAvatarinitials))])])]}}],null,false,2202964833)},[_c('div',{staticClass:"white"},[_c('div',{staticClass:"white d-flex flex-row justify-start align-center pa-3"},[_c('v-avatar',{attrs:{"color":"blue darken-1","size":"41"}},[_c('span',{staticClass:"font-weight-black white--text"},[_vm._v(_vm._s(_vm.userAvatarinitials))])]),_c('div',{staticClass:"ml-3 d-flex flex-column justify-space-around"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.userName))]),_c('div',{staticClass:"caption grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.userEmail)+" ")])])],1),_c('v-divider'),_c('v-menu',{attrs:{"nudge-left":"50","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return _c('div',_vm._g({class:[
                    'd-flex flex-row caption grey--text text--darken-3 font-weight-bold pointer pa-2 align-center mt-2 mb-2',
                    hover ? 'grey lighten-4' : '' ]},on),[_c('v-img',{staticClass:"ml-1 mr-4",attrs:{"lazy-src":_vm.selectedLocale ? _vm.selectedLocale.flag : '',"max-height":"18","max-width":"25","src":_vm.selectedLocale ? _vm.selectedLocale.flag : ''}}),_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.selectedLocale ? _vm.selectedLocale.name : "")+" ")])],1)}}],null,true)})]}}],null,false,2707412175)},[_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',_vm._l((_vm.locales),function(item,ind){return _c('v-list-item',{key:ind,on:{"click":function($event){return _vm.selectLocale(item)}}},[_c('v-list-item-icon',{staticClass:"pl-1"},[_c('v-img',{attrs:{"lazy-src":item.flag,"max-height":"18","max-width":"25","src":item.flag}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1)}),1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"to":{
                  name: 'my-account',
                  params: { locale: _vm.$i18n.locale.substring(0, 2) },
                }}},[_c('v-list-item-icon',{staticClass:"pl-1"},[_c('v-icon',[_vm._v("fas fa-wallet")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v(_vm._s(_vm.walletAmount))]),_vm._v(" "+_vm._s(_vm.walletCurrency))])],1)],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"to":{
                  name: 'referal-programs-list',
                  params: { locale: _vm.$i18n.locale.substring(0, 2) },
                }}},[_c('v-list-item-icon',{staticClass:"pl-1"},[_c('v-icon',[_vm._v("mdi-handshake")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("referal.nav.referal-programs")))])],1)],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"to":{ name: 'profile' }}},[_c('v-list-item-icon',{staticClass:"pl-1"},[_c('v-icon',[_vm._v("far fa-user-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("profile.titles.my-profile")))])],1)],1),_c('v-list-item',{attrs:{"to":{
                  name: 'logout',
                  params: { locale: _vm.$i18n.locale.substring(0, 2) },
                }}},[_c('v-list-item-icon',{staticClass:"pl-1"},[_c('v-icon',[_vm._v("fas fa-sign-out-alt")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("sso.logout.name")))])],1)],1)],1)],1)],1)]):_vm._e()],1):_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-btn',{style:({ 'text-transform': 'none', 'font-weight': 500 }),attrs:{"text":"","dark":"","to":{
          name: 'login',
          params: { locale: _vm.$i18n.locale.substring(0, 2) },
        }}},[_vm._v(_vm._s(_vm.$t("tsmarket.terms.sign-in")))]),_c('v-btn',{style:({ 'text-transform': 'none', 'font-weight': 400 }),attrs:{"dark":"","outlined":""},on:{"click":function($event){return _vm.register()}}},[_vm._v(_vm._s(_vm.$t("tsmarket.terms.create-account")))])],1),(_vm.detailsMenu)?_c('div',[_c('v-menu',{attrs:{"bottom":"","nudge-width":"250","nudge-top":"-30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","icon":"","dark":"","dense":""}},on),[_c('v-icon',{attrs:{"dark":"","color":"white"}},[_vm._v("mdi-menu")])],1)]}}],null,false,1686728449)},[_c('div',{staticClass:"white"},[_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[(!(_vm.isLoggedIn && _vm.$vuetify.breakpoint.smAndUp))?_c('v-list-item',{attrs:{"to":{
                name: 'market-showcase-tschannel',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              },"prepend-icon":"mdi-arrow-left-right-bold-outline"}},[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.terms.trading-robots")))])],1)],1):_vm._e(),(!(_vm.isLoggedIn && _vm.$vuetify.breakpoint.smAndUp))?_c('v-list-item',{attrs:{"to":{
                name: 'market-showcase-tslab',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              },"prepend-icon":"mdi-arrow-left-right-bold-outline"}},[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.terms.tslab-licenses")))])],1)],1):_vm._e(),(!(_vm.isLoggedIn && _vm.$vuetify.breakpoint.smAndUp))?_c('v-divider'):_vm._e(),(_vm.isLoggedIn)?_c('v-list-group',{attrs:{"value":true,"prepend-icon":"mdi-cart-variant"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.terms.my-purchases")))])]},proxy:true}],null,false,4172715044)},[_c('v-list-item',{attrs:{"to":{
                  name: 'tschannel-subscriptions-list',
                  params: { locale: _vm.$i18n.locale.substring(0, 2) },
                }}},[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.terms.trading-robots")))])],1)],1),_c('v-list-item',{attrs:{"to":{
                  name: 'tslab-contracts',
                  params: { locale: _vm.$i18n.locale.substring(0, 2) },
                }}},[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.nav.myTSLabContracts")))])],1)],1)],1):_vm._e(),(_vm.isLoggedIn)?_c('v-divider'):_vm._e(),(_vm.isLoggedIn)?_c('v-list-item',{attrs:{"to":{
                name: 'tschannel-offers-list',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"blue--text text--darken-2"},[_vm._v("mdi-hand-coin-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.nav.my-sales")))])],1)],1):_vm._e(),(_vm.isLoggedIn)?_c('v-divider'):_vm._e(),_c('v-list-group',{attrs:{"value":true,"prepend-icon":"mdi-arrow-left-right-bold-outline"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tschannel.name")))])]},proxy:true}],null,false,706172214)},[(_vm.isLoggedIn)?_c('v-list-item',{attrs:{"to":{
                  name: 'tschannels-list',
                  params: { locale: _vm.$i18n.locale.substring(0, 2) },
                },"prepend-icon":"mdi-arrow-left-right-bold-outline"}},[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tschannel.nav.list")))])],1)],1):_vm._e(),(_vm.isLoggedIn)?_c('v-list-item',{attrs:{"to":{
                  name: 'receivers-list',
                  params: { locale: _vm.$i18n.locale.substring(0, 2) },
                }}},[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tschannel.nav.receiverslist")))])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.openTSChannelDocs()}}},[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("tsmarket.nav.tschannel-external-docs"))+" ")])],1)],1)],1),(_vm.isLoggedIn)?_c('v-divider'):_vm._e(),_c('v-list-item',{attrs:{"to":{
                name: 'tsplayer-broadcasts',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"blue--text text--darken-2"},[_vm._v("mdi-broadcast")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsplayer.titles.broadcasts")))])],1)],1),_c('v-list-item',{attrs:{"to":{
                name: 'tsplayer',
                params: { locale: _vm.$i18n.locale.substring(0, 2) },
              }}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"blue--text text--darken-2"},[_vm._v("mdi-laptop")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tsmarket.titles.my-agents")))])],1)],1)],1)],1)])],1):_vm._e(),_c('div',[_c('v-menu',{attrs:{"nudge-top":"-30","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return _c('div',_vm._g({class:[
                'd-flex flex-row caption grey--text text--darken-3 font-weight-bold pointer align-center ml-1',
                hover ? '' : '' ]},on),[_c('v-img',{attrs:{"lazy-src":_vm.selectedLocale ? _vm.selectedLocale.flag : '',"max-height":"18","max-width":"25","src":_vm.selectedLocale ? _vm.selectedLocale.flag : ''}})],1)}}],null,true)})]}}])},[_c('v-list',{staticClass:"ma-0 pl-0 pr-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',_vm._l((_vm.locales),function(item,ind){return _c('v-list-item',{key:ind,on:{"click":function($event){return _vm.selectLocale(item)}}},[_c('v-list-item-icon',{staticClass:"pl-1"},[_c('v-img',{attrs:{"lazy-src":item.flag,"max-height":"18","max-width":"25","src":item.flag}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }