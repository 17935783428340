import {
  VuexModule,
  Module,
  Action,
  MutationAction,
  Mutation,
} from "vuex-module-decorators";

import { tsWalletService } from "@/services/tswalletservice";

const walletCurrency = "у.е.";

export interface TSWalletState {
  amount: number;
  reserved: number;
  currency: string;
  amountsInCurrencies: any[];
  loaded: boolean;
}

@Module({
  namespaced: true,
})
class TSWallet extends VuexModule implements TSWalletState {
  public amount = 0;
  public reserved = 0;
  public currency = walletCurrency;
  public amountsInCurrencies = [];
  public loaded = false;

  get walletAmount(): number {
    return this.amount;
  }

  get walletReserve(): number {
    return this.reserved;
  }

  get walletCurrency(): string {
    //return "TPRT";
    return walletCurrency;
    //this.currency;
  }

  get walletLoaded(): boolean {
    return this.loaded;
  }

  get walletAmountsInCurrencies(): any[] {
    return this.amountsInCurrencies;
  }

  @Mutation
  public setAmount(amount: number) {
    this.amount = this.amount + amount;
  }

  @Mutation
  public destroy() {
    this.amount = 0;
    this.reserved = 0;
    this.currency = walletCurrency;
    this.amountsInCurrencies = [];
    this.loaded = false;
  }

  @MutationAction({
    mutate: ["amount", "reserved", "currency", "amountsInCurrencies", "loaded"],
  })
  public async walletFetch() {
    try {
      const result = await tsWalletService.getWallet();
      if (!result) {
        return {
          amount: 0,
          reserved: 0,
          currency: walletCurrency,
          amountsInCurrencies: [],
          loaded: false,
        };
      }
      result.loaded = true;
      result.currency = walletCurrency; // remove it
      return result;
    } catch (error) {
      //console.error(error);
    }
  }

  @Action({ commit: "setAmount" })
  public update(amount: number) {
    return amount;
  }
}

export default TSWallet;
