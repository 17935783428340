import Vue from "vue";

import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import stockInit from "highcharts/modules/stock";
import highchartsMore from "highcharts/highcharts-more";

import options from "@/config/charts/options.json";

/* Highcharts.setOptions({
  lang: {
    loading: "Загрузка...",
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    shortMonths: ["Янв", "Фев", "Март", "Апр", "Май", "Июнь", "Июль", "Авг", "Сент", "Окт", "Нояб", "Дек"],
    rangeSelectorFrom: "С",
    rangeSelectorTo: "По",
    rangeSelectorZoom: "Период",
    downloadPNG: "Скачать PNG",
    downloadJPEG: "Скачать JPEG",
    downloadPDF: "Скачать PDF",
    downloadSVG: "Скачать SVG",
    printChart: "Напечатать график",
  },
}); */

// Load the fonts
Highcharts.createElement(
  "link",
  {
    href: "https://fonts.googleapis.com/css?family=Dosis:400,600",
    rel: "stylesheet",
    type: "text/css",
  },
  null,
  document.getElementsByTagName("head")[0],
);

const timezone = new Date().getTimezoneOffset();

options.time.timezoneOffset = timezone;

// Apply the theme
Highcharts.setOptions(options);

stockInit(Highcharts);
highchartsMore(Highcharts);

Vue.use(HighchartsVue, {
  highcharts: Highcharts,
});
