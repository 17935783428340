var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"z-file-input-container pointer",style:({
      width: _vm.mode == 'image' ? _vm.width * 1 + 12 : 'auto',
      height: _vm.mode == 'image' ? _vm.height * 1 + 12 + 2 : 41,
    })},[(_vm.label.length > 0)?_c('div',{staticClass:"z-file-input-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.value)?_c('div',{staticClass:"d-flex flex-column justify-center align-center",style:({
        width: _vm.width - 4 + 'px',
        height: _vm.mode == 'image' ? _vm.height - 4 + 'px' : '41px',
      })},[(_vm.value)?_c('a',{attrs:{"target":"_blank","href":_vm.url + '/' + _vm.value.name}},[(_vm.mode == 'image')?_c('v-img',{attrs:{"contain":_vm.contain,"src":_vm.url,"width":_vm.width - 2,"height":_vm.height - 2}}):_c('div',{style:({ 'padding-right': '55px' })},[_vm._v(" "+_vm._s(_vm.value.name)+" ")])],1):_vm._e(),(_vm.value)?_c('div',{staticClass:"d-flex flex-row align-center"},[(!_vm.disabled)?_c('confirm-dialog',{staticClass:"z-file-input-drop",attrs:{"icon":"mdi-delete","color":"red","title":_vm.$t('tsmarket.titles.drop-file-confirm-title'),"text":_vm.$t('tsmarket.messages.drop-file-confirm-text')},on:{"onConfirm":function () {
              _vm.$emit('delete');
            }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"z-file-input-drop-btn",attrs:{"fab":"","color":"error","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,false,1958033353)}):_vm._e()],1):_vm._e()]):_c('div',{ref:"dropZone",class:[
        'imageDropZone d-flex align-center justify-space-around',
        _vm.dragOver ? 'hover' : '' ],style:({
        width: _vm.width - 4 + 'px',
        height: _vm.mode == 'image' ? _vm.height - 4 + 'px' : '41px',
      }),attrs:{"draggable":true},on:{"click":function($event){return _vm.$refs['file-input'].click()},"drop":function (event) {
          _vm.emitUpload(event);
        },"dragover":function (event) {
          event.preventDefault();
          event.dataTransfer.dropEffect = 'copy';
          _vm.dragOver = true;
        },"dragleave":function (event) {
          event.preventDefault();
          _vm.dragOver = false;
        }}},[(_vm.mode == 'image')?_c('v-icon',{attrs:{"color":"green darken-1"}},[_vm._v("mdi-plus")]):_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("tsmarket.headers.field-file-drag-to-upload")))])],1)]),_c('input',{ref:"file-input",staticClass:"z-file-input-file-input",attrs:{"type":"file","name":"file-input"},on:{"change":function($event){return _vm.onFileChoosen()}}}),_c('v-spacer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }