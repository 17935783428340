export const bold = "bold";
export const clear = "clear";
export const code = "code";
export const fullscreen = "fullscreen";
export const h1 = "h1";
export const h2 = "h2";
export const h3 = "h3";
export const h4 = "h4";
export const h5 = "h5";
export const h6 = "h6";
export const hr = "hr";
export const image = "image";
export const italic = "italic";
export const link = "link";
export const ol = "ol";
export const quote = "quote";
export const redo = "redo";
export const strikethrough = "strikethrough";
export const syncScroll = "sync-scroll";
export const table = "table";
export const ul = "ul";
export const undo = "undo";
