import { generatorText } from "../utils/util";

export { strikethrough as name } from "../utils/constants/command.js";

export default function (editor) {
  editor.insert(selected => {
    const prefix = "~~";
    const suffix = "~~";
    const { placeholder } = editor.langConfig.strikethrough;

    const selectedGetter = selected => selected || placeholder;
    const { insertContent, newSelected } = generatorText({
      selected,
      InsertGetter: selected => `${prefix}${selectedGetter(selected)}${suffix}`,
      selectedGetter,
    });

    return {
      text: insertContent,
      selected: newSelected,
    };
  });
}
