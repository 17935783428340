import Vue from "vue";
//import { split } from "lodash";
//import moment from "moment";
import axios from "axios";
import VueI18n from "vue-i18n";
import { getCookie, setCookie } from "tiny-cookie";
import { locales /*, isDebug */ } from "@/config";

Vue.use(VueI18n);

export const CookieLangName = ".AspNetCore.Culture";

export function getCookieLang() {
  try {
    const langFromCookie = getCookie(CookieLangName) as string;
    return langFromCookie ? langFromCookie : undefined;
  } catch (error) {
    console.error(error);
  }
  return undefined;
}

export function loadLocaleMessage(locale: string) {
  try {
    const isDebug: boolean = process.env.NODE_ENV !== "production";
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `${process.env.VUE_APP_HOME}${process.env.VUE_APP_RESOURCES}lang/${
        locale === "ru" || locale === "ru-RU" ? "ru-RU" : "en-US"
      }.json?${isDebug ? Date.now() : process.env.VERSION}`,
    );
    xhr.onloadend = () => {
      const json = JSON.parse(xhr.responseText);
      axios.defaults.headers.common["Accept-Language"] = locale;
      i18n.setLocaleMessage(i18n.locale, json);
    };
    xhr.send();
  } catch (error) {
    console.error(error);
  }
}

let userLang: string =
  getCookieLang() ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language;

if (userLang == "ru") userLang = "ru-RU";
if (userLang != "ru-RU" && userLang != "en-EN") userLang = "en-EN";

const i18n: VueI18n = new VueI18n({
  locale: userLang ? userLang : locales[0].id,
  missing: (l, k) => "",
  fallbackLocale: locales[0].id,
  silentTranslationWarn: true, //!isDebug,
});

function setCookieLang(locale: string) {
  const param = {
    domain: `${process.env.VUE_APP_DOMAIN}`,
    SameSite: "strict",
  };
  setCookie(CookieLangName, locale, param);
}

export function setLocale(locale) {
  loadLocaleMessage(locale);
  i18n.locale = locale;
  setCookieLang(locale);

  const freeScout = document.querySelector("#fsw-iframe");
  if (freeScout) {
    const src = freeScout.getAttribute("src");
    //const newSrc = src.replace(/locale=\w{2}/, "locale=" + locale);
    //console.log(src.match(/locale=\w{2}/));
    console.log(
      src.replace(/locale=\w{2}/, "locale=" + locale),
      locale.substring(0, 2),
    );
    //https://support.dev.tsverse.pro/chat/widget/form/1135484107?color=%230068bd&position=br&locale=ru&require=name%2Cemail&id=1135484107 index.ts:67
  }
}

export default i18n;
