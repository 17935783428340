<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        dark
        v-bind="attrs"
        v-on="on"
        :disabled="title.length < 3"
      >
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 success white--text">
        {{ $t("tsmarket.titles.link-share-title") }}
      </v-card-title>

      <v-card-text>
        <template v-if="isLoggedIn && !isExpired">
          <v-radio-group
            v-if="tokensAvailable"
            v-model="reftoken"
            hide-details
            dense
            :label="$t('tsmarket.titles.link-share-use-token') + ':'"
            class="mt-4"
          >
            <v-radio
              :label="$t('tsmarket.titles.do-not-use') + ':'"
              value="none"
            ></v-radio>
            <template v-for="(p, pi) in referalPrograms">
              <v-radio
                v-for="(t, ti) in p.tokens.items"
                :key="pi + ' ' + ti"
                :label="t.name + ' (' + t.id + ')'"
                :value="t.id"
              ></v-radio>
            </template>
          </v-radio-group>
          <v-alert v-else outlined type="info" class="mt-4" text>
            {{ $t("tsmarket.messages.use-referal-tokens-advice") }}
            <router-link :to="{ name: 'referal-programs-list' }">{{
              $t("tsmarket.messages.setup-referal-program")
            }}</router-link>
          </v-alert>
        </template>
        <v-alert v-else outlined type="info" class="mt-4" text>
          {{ $t("tsmarket.messages.use-referal-tokens-advice") }}
          <router-link :to="{ name: 'referal-programs-list' }">{{
            $t("tsmarket.btn.go-to-sign-up")
          }}</router-link>
        </v-alert>

        <!-- кнопки соц сетей -->
        <div class="text-h6 mt-3">
          {{ $t("tsmarket.titles.share-on-socials") }}
        </div>
        <div class="d-flex flex-row">
          <template v-for="(social, ind) in socials">
            <div
              :key="ind"
              v-if="social.locales.indexOf($i18n.locale.substring(0, 2)) >= 0"
              class="pointer"
              @click="openSocial(social)"
            >
              <img :src="social.icon" width="48" height="48" />
            </div>
          </template>
        </div>

        <div class="text-h6 mt-3">
          {{ $t("tsmarket.titles.get-link-code") }}
        </div>
        <v-radio-group class="mb-4" v-model="codeType" hide-details dense>
          <v-radio
            :label="$t('tsmarket.titles.link-html')"
            value="html"
          ></v-radio>
          <v-radio
            :label="$t('tsmarket.titles.link-url-only')"
            value="url"
          ></v-radio>
        </v-radio-group>
        <div
          v-if="codeType == 'url'"
          class="code-container pointer"
          @click="copyCode()"
        >
          {{ linkURL }}
        </div>
        <div v-else class="code-container pointer" @click="copyCode()">
          {{ linkCode }}
        </div>

        <div class="d-flex flex-row justify-end pb-4">
          <div
            class="caption pointer light-blue--text text--darken-3 text-underline"
            @click="copyCode()"
          >
            скопировать
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{
          $t("tsmarket.btn.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { referalService } from "@/services/referalservice";
import { EventBus } from "@/config";
//import i18n from "@/i18n";
export default Vue.extend({
  name: "link-share-control",
  data() {
    return {
      dialog: false,
      codeType: "html",
      referalCode: "none",
      referalPrograms: [],
      reftoken: "none",
      socials: {
        tg: {
          locales: ["ru", "en"],
          name: {
            ru: "Telegram",
            en: "Telegram",
          },
          icon: "/social/telegram.svg",
          url: "https://t.me/share/url?url=",
          shareUrl: "https://t.me/share/url?url={url}&text={title}",
        },
        vk: {
          locales: ["ru"],
          name: {
            ru: "ВКонтакте",
            en: "ВКонтакте",
          },
          icon: "/social/vk.svg",
          url: "https://vk.com/",
          shareUrl: "https://vk.com/share.php?url={url}",
        },
        fb: {
          locales: ["ru", "en"],
          name: {
            ru: "Facebook",
            en: "Facebook",
          },
          icon: "/social/facebook.svg",
          url: "https://www.facebook.com/",
          shareUrl: "https://www.facebook.com/sharer/sharer.php?u={url}",
        },
        tw: {
          locales: ["ru", "en"],
          name: {
            ru: "Twitter",
            en: "Twitter",
          },
          icon: "/social/twitter.svg",
          url: "https://twitter.com/",
          shareUrl: "https://twitter.com/intent/tweet?url={url}&text={title}",
        },
        gp: {
          locales: ["ru", "en"],
          name: {
            ru: "Currents",
            en: "Currents",
          },
          icon: "/social/currents.png",
          url: "https://currents.google.com/",
          shareUrl: "https://currents.google.com/share?url={url}",
        },
      },
    };
  },
  props: {
    url: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  methods: {
    copyCode() {
      navigator.clipboard
        .writeText(this.linkCode)
        .then(() => {
          // Получилось!
          console.log("Получилось!");
        })
        .catch(err => {
          console.log("Something went wrong", err);
        });
      EventBus.$emit("notify", "__copied_to_clipboard__");
    },
    openSocial(social) {
      // размеры окна
      var width = 800,
        height = 500;
      // центруем окно
      var left = (window.screen.width - width) / 2;
      var top = (window.screen.height - height) / 2;
      // открываем окно
      const social_window = window.open(
        social.shareUrl
          .replace("{url}", encodeURIComponent(this.linkURL))
          .replace("{title}", encodeURIComponent(this.title)),
        "share_window",
        "height=" +
          height +
          ",width=" +
          width +
          ",top=" +
          top +
          ",left=" +
          left,
      );
      // устанавливаем на окно фокус
      social_window.focus();
    },
  },
  watch: {},
  computed: {
    tokensAvailable() {
      let count = 0;
      this.referalPrograms.forEach(p => {
        count += p.tokens.items.length;
      });
      return count > 0;
    },

    linkCode() {
      return this.codeType == "html"
        ? '<a href="' + this.linkURL + '">' + this.title + "</a>"
        : this.linkURL;
    },

    computedURL() {
      return this.url ? this.url : this.$router.currentRoute.path;
    },

    linkURL() {
      return (
        process.env.VUE_APP_HOME +
        this.computedURL +
        (this.reftoken != "none" ? "?ref=" + this.reftoken : "")
      );
    },
    ...mapGetters(["isLoggedIn", "isExpired"]),
  },

  async created() {
    if (this.isLoggedIn && !this.isExpired) {
      const result = await referalService.getReferalPrograms();
      if (result) this.referalPrograms = result.items;
    }
  },
});
</script>

<style>
.code-container {
  padding: 9px 15px;
  color: white;
  background-color: black;
  border-radius: 7px;
  position: relative;
}

.copy-code-btn {
  position: absolute;
  bottom: 0;
  right: 200px;
}
</style>
