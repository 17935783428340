<template>
  <div
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click.stop="toggle"
    :class="[
      'pointer pa-1 text--caption',
      hover ? 'grey lighten-3' : '',
      value ? 'blue--text text--darken-2' : 'grey--text text--darken-4',
    ]"
  >
    <template v-if="icon.length == 0">
      {{ text }}
    </template>
    <v-icon v-else>{{ icon }}</v-icon>
  </div>
</template>

<script>
export default {
  name: "fav-toggle",
  data() {
    return {
      hover: false,
    };
  },
  props: {
    text: {
      type: String,
      default: "...",
    },
    icon: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    allowOff: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggle(e) {
      this.$emit("click", e);
      const newState = this.value && this.allowOff ? false : true;
      //console.log('aaaa',newState);
      if (newState != this.value) this.$emit("input", newState);
      if (newState) this.$emit("switch-on");
      else this.$emit("switch-off");
    },
  },
};
</script>
