<template>
  <div class="d-flex">
    <div
      :style="{
        position: 'relative',
        lineHeight: '0px',
      }"
    >
      <img
        :src="'data:image/png;base64,' + fileData"
        width="100%"
        :max-width="broadcastWidth + 'px'"
        height="auto"
      />
      <div
        v-if="showDate"
        class="caption pl-2 pr-2 black--text grey"
        :style="{
          position: 'absolute',
          bottom: '0px',
          right: '0px',
          opacity: 0.7,
        }"
      >
        {{ $ztime.localDateTime(lastFrameTime) }}
      </div>
    </div>
  </div>
  <!--<v-img
    contain
    :src="'data:image/png;base64,' + fileData"
    :max-width="broadcastWidth"
    height="auto"
  >
  </v-img>
  -->
</template>

<script>
import Vue from "vue";
import { tsMarketplaceService } from "@/services/tsmarketplaceservice";

export default Vue.extend({
  name: "broadcast-image",
  data() {
    return {
      timer: null,
      fileData: null,
      interval: 5,
      lastFrameTime: "",
      broadcastWidth: 320,
      broadcastHeight: 240,
    };
  },
  props: {
    broadcast: {
      type: Object,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      if (this.timer) clearTimeout(this.timer);
      const result = await tsMarketplaceService.broadcastFetch(
        this.broadcast.id,
      );
      if (result) {
        this.fileData = result.fileData;
        this.interval = result.interval;
        this.lastFrameTime = result.lastFrameTime;
        if (!this.width) this.broadcastWidth = result.width;
        if (!this.height) this.broadcastHeight = result.height;
        this.timer = setTimeout(this.fetch, this.interval * 1000);
        this.$emit("update", {
          lastFrameTime: result.lastFrameTime,
          width: result.width,
          height: result.height,
          interval: result.interval,
        });
      }
      this.loading = false;
    },
  },
  async created() {
    this.fileData = this.broadcast.fileData;
    this.interval = this.broadcast.interval;
    this.lastFrameTime = this.broadcast.lastFrameTime;
    this.broadcastWidth = this.width ? this.width : this.broadcast.width;
    this.broadcastHeight = this.height ? this.height : this.broadcast.height;
    await this.fetch();
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
  },
  computed: {
    // computedWidth() {
    //   return this.width ? this.width : this.broadcastWidth;
    // },
    // computedHeight() {
    //   return this.height ? this.height : this.broadcastHeight;
    // },
  },
});
</script>

<style scoped></style>
