import {
  VuexModule,
  Module,
  // Action,
  MutationAction,
  Mutation,
} from "vuex-module-decorators";

import { tsMarketplaceService } from "@/services/tsmarketplaceservice";
import { _getLocal } from "@/utils/utils";
export interface offerState {
  item: any;
  loaded: boolean;
}

@Module({
  namespaced: true,
})
class CurrentOfferState extends VuexModule implements offerState {
  public item = undefined;
  public loaded = false;

  get offer(): any {
    if (this.loaded) {
      return this.item;
    } else {
      return undefined;
    }
  }

  @Mutation
  public destroy() {
    this.item = null;
  }

  @MutationAction({
    mutate: ["item", "loaded"],
  })
  public async fetch(id: number) {
    const result = await tsMarketplaceService.getOffer(id, "details");
    if (!result) {
      return {
        item: undefined,
        loaded: false,
      };
    }
    //console.log("result", result.tslabProviders.items);
    result.tslabProviders.items.forEach(p => {
      p.text = _getLocal(p.name);
      p.value = p.id;
      p.disabled = false;
    });
    return {
      item: result,
      loaded: true,
    };
  }
}

export default CurrentOfferState;
