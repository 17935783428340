var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[(_vm.value)?_vm._l((_vm.value),function(item,ind){return _c('div',{key:ind},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{class:[
            'z-file-input-container ma-2',
            _vm.selectEnable ? 'pointer' : '',
            hover && _vm.selectEnable ? 'elevation-8' : '' ],on:{"click":function($event){$event.stopPropagation();return _vm.select(item)}}},[_c('v-img',{attrs:{"src":_vm.baseURL + item.url + '/' + item.name,"width":_vm.itemWidth - 14,"height":_vm.itemHeight - 14}}),(_vm.copyURLenable)?_c('v-btn',{staticClass:"z-file-input-copyurl-btn",attrs:{"fab":"","color":"info","small":"","dark":""},on:{"click":function($event){return _vm.copyURL(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-link")])],1):_vm._e(),_c('confirm-dialog',{staticClass:"z-file-input-drop",attrs:{"icon":"mdi-delete","color":"red","title":_vm.$t('tsmarket.titles.drop-file-confirm-title'),"text":_vm.$t('tsmarket.messages.drop-file-confirm-text')},on:{"onConfirm":function () {
                _vm.drop(item);
              }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"z-file-input-drop-btn",attrs:{"fab":"","color":"error","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)]}}],null,true)})],1)}):_vm._e(),_c('div',{ref:"dropZone",class:[
      'imageDropZone d-flex align-center justify-space-around ma-2',
      _vm.dragOver ? 'hover' : '' ],style:({
      width: _vm.itemWidth + 'px',
      height: _vm.mode == 'image' ? _vm.itemHeight + 'px' : '41px',
    }),attrs:{"draggable":true},on:{"click":function($event){return _vm.$refs['file-input'].click()},"drop":function (event) {
        //console('aaaa');
        _vm.emitUpload(event);
      },"dragover":function (event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
        _vm.dragOver = true;
      },"dragleave":function (event) {
        event.preventDefault();
        _vm.dragOver = false;
      }}},[(_vm.mode == 'image')?_c('v-icon',{attrs:{"color":"green darken-1"}},[_vm._v("mdi-plus")]):_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("tsmarket.headers.field-file-drag-to-upload")))])],1),_c('input',{ref:"file-input",staticClass:"z-file-input-file-input",attrs:{"type":"file","name":"file-input","multiple":""},on:{"change":function($event){return _vm.onFileChoosen()}}}),_c('v-spacer')],2)}
var staticRenderFns = []

export { render, staticRenderFns }