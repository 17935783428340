var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.hideMenu),expression:"hideMenu"}],staticClass:"v-md-editor__toolbar-item",class:[
    _vm.icon,
    ("v-md-editor__toolbar-item-" + _vm.name),
    {
      'v-md-editor__toolbar-item--active': _vm.active || _vm.menuActive,
    },
    {
      'v-md-editor__toolbar-item--menu': _vm.hasMenu,
    } ],on:{"mousedown":function($event){$event.preventDefault();},"mouseleave":_vm.handleHideTooltip,"mousemove":_vm.showTooltip,"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-md-tooltip',{ref:"tooltip",attrs:{"text":_vm.title}}),(_vm.hasMenu)?_c('v-md-menu',{ref:"menu",attrs:{"mode":_vm.menuMode,"menus":_vm.menuItems,"item-width":_vm.menus.itemWidth,"row-num":_vm.menus.rowNum,"visible":_vm.menuActive},on:{"update:visible":function($event){_vm.menuActive=$event},"item-click":function($event){return _vm.$emit('menu-click', arguments[0])}}}):_vm._e(),(_vm.hasMenu)?_c('i',{ref:"menuCtrl",staticClass:"v-md-icon-arrow-down v-md-editor__menu-ctrl"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }