<template>
  <div
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click.stop="active = !active"
    :style="{
      'border-radius': '50%',
      'background-color': hover
        ? dark
          ? 'rgba(255, 255, 255, 0.15)'
          : 'rgba(0, 0, 0, 0.03)'
        : 'transparent',
    }"
    class="pa-1"
  >
    <v-icon
      v-if="active"
      :color="
        dark
          ? 'yellow lighten-3'
          : hover
          ? 'yellow darken-2'
          : 'yellow darken-1'
      "
      >mdi-star</v-icon
    >
    <v-icon
      v-else
      :color="hover ? (dark ? 'grey lighten-2' : 'grey') : 'transparent'"
      >mdi-star-outline</v-icon
    >
  </div>
</template>

<script>
export default {
  name: "fav-toggle",
  data() {
    return {
      hover: false,
      active: false,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    active() {
      this.$emit("input", this.active);
    },
  },
  created() {
    this.active = this.value;
  },
};
</script>
