import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface ProgressState {
  loading: boolean;
}

@Module
class Progress extends VuexModule implements ProgressState {
  public loading = false;

  get isLoading(): boolean {
    return this.loading;
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @Action
  public updateLoading(loading: boolean) {
    this.context.commit("setLoading", loading);
  }
}

export default Progress;
