import { walletapi /*, isDebug */ } from "@/config";

import moment from "moment";
//import { find } from "lodash";

class TSWalletService {
  private static instance: TSWalletService;

  public static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this.instance || (this.instance = new this());
  }

  public async getWallet(): Promise<any> {
    //if (isDebug) {
    const result = await walletapi.get("wallet/?r=" + moment().valueOf());
    return result.data.success ? result.data.data : false;
    //} else return false;
  }

  public async getPayments(pagination = {}): Promise<any> {
    //if (isDebug) {
    const result = await walletapi.post("payments", {
      pagination: pagination,
    });
    return result.data.success ? result.data.data : false;
    //} else return false;
  }

  public async getWithdrawRequests(pagination = {}): Promise<any> {
    const result = await walletapi.post("getWithdrawRequests", {
      pagination: pagination,
    });
    return result.data.success ? result.data.data : false;
  }

  public async cancelWithdrawRequest(id): Promise<any> {
    const result = await walletapi.post("cancelWithdrawRequest", {
      id: id,
    });
    return result.data.success ? result.data.data : false;
  }

  public async createWithdrawRequest(
    amount,
    coin,
    blockchain,
    address,
  ): Promise<any> {
    const result = await walletapi.post("createWithdrawRequest", {
      amount: amount,
      coin: coin,
      blockchain: blockchain,
      address: address,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getReplenishmentCoins() {
    const result = await walletapi.get("replenishmentCoins");
    //console.log("bbbbbbbbbb", result);
    return result.data.success ? result.data.data.items : [];
  }

  public async getBlockchains(coinId) {
    const result = await walletapi.get(
      "blockchains" + (coinId && coinId.length >= 3 ? "/" + coinId : ""),
    );
    return result.data.success ? result.data.data.items : [];
  }

  public async getDepositAddress(coinId, blockchainId) {
    const result = await walletapi.get(
      "depositAddress" + "/" + blockchainId + "/" + coinId,
    );
    return result.data.success ? result.data.data : false;
  }

  public async getTransactions(
    depositAddressId,
    coinId,
    blockchainId,
    pagination = {},
  ): Promise<any> {
    const result = await walletapi.post("getTransactions", {
      address: depositAddressId,
      pagination: pagination,
      blockchain: blockchainId,
      coin: coinId,
    });
    return result.data.success ? result.data.data : false;
  }

  public async getRates(): Promise<any> {
    const result = await walletapi.get("rates/?r=" + moment().valueOf());
    return result.data.success ? result.data.data : false;
  }
}

// export a singleton instance in the global namespace
export const tsWalletService = TSWalletService.Instance;
