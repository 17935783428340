import { sortBy, parseInt } from "lodash";
const intervalMultipliers = {
  s: 1,
  m: 60,
  h: 3600,
  d: 86400,
  W: 604800,
  M: 18144000,
};

const state: any = {
  state: {
    preferences: {
      isLoaded: false,
      subjectId: -1,
      favorites: {
        interval: [],
      },
    },
  },
};

const mutations = {
  preferencesIntervalAdd(state: any, interval: string): void {
    if (state.state.preferences.favorites.interval.indexOf(interval) === -1) {
      state.state.preferences.favorites.interval.push(interval);
    }
    state.state.preferences.favorites.interval = sortBy(
      state.state.preferences.favorites.interval,
      o => {
        return (
          parseInt(o.slice(0, o.length - 1)) *
          intervalMultipliers[o[o.length - 1]]
        );
      },
    );
  },
  preferencesIntervalDrop(state: any, interval: string): void {
    const indexToRemove = state.state.preferences.favorites.interval.indexOf(
      interval,
    );
    if (indexToRemove >= 0)
      state.state.preferences.favorites.interval = state.state.preferences.favorites.interval.splice(
        indexToRemove,
        1,
      );
  },
  preferencesSet(state: any, preferences: any) {
    if (
      typeof preferences.favorites === undefined ||
      !(preferences.favorites instanceof Object)
    ) {
      preferences.favorites = {
        interval: [],
      };
    }
    if (
      typeof preferences.favorites.interval === undefined ||
      !(preferences.favorites.interval instanceof Array)
    ) {
      preferences.favorites.interval = [];
    }

    state.state.preferences = preferences;
  },
};

const actions = {
  preferencesIntervalAdd({ commit, dispatch }, interval: any): void {
    commit("preferencesIntervalAdd", interval);
    dispatch("preferencesSave");
  },
  preferencesIntervalDrop({ commit, dispatch }, interval: any): void {
    commit("preferencesIntervalDrop", interval);
    dispatch("preferencesSave");
  },
  preferencesSave() {
    localStorage.setItem("tsprefs", JSON.stringify(state.state.preferences));
  },
  preferencesLoad({ commit }) {
    let prefs = localStorage.getItem("tsprefs");
    if (prefs === null) {
      commit("preferencesSet", { isLoaded: true });
    } else {
      prefs = JSON.parse(prefs);
      (prefs as any).isLoaded = true;
      commit("preferencesSet", prefs);
    }
  },
  /*
  async getUser({ commit, state }){
    const adminUser = await state.state.authService.getUser();
    if (adminUser) {
      commit('set_admin', adminUser);
    }
  }
  */
};

const getters = {
  preferences(state) {
    return state.state.preferences;
  },
  preferencesIntervalFavorits(state) {
    return state.state.preferences.favorites.interval
      ? state.state.preferences.favorites.interval
      : [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
