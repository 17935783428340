import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
import i18n from "@/i18n";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import VuetifyConfirm from "vuetify-confirm";

import colors from "vuetify/es5/util/colors";

import tslabIcon from "@/icons/tslab";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      tslab: tslabIcon,
      tslabPath:
        "M24.4,34.7V48.6H14.1V34.7Zm41.7-7V48.6H55.5V27.7Zm-55.6,0V48.6H0V27.7Zm27.8-3.4V48.6H27.9V24.3Zm20.2-6.7v5.2a7.57,7.57,0,0,0,1.5.1c1.8,0,3.5-.7,3.5-2.6s-1.6-2.6-3.5-2.6H58.5ZM15.3,30.3l.4-1.5a6.2,6.2,0,0,0,3.2.9c1.9,0,2.9-1,2.9-2.4s-.8-2-2.6-2.8c-2.2-.8-3.7-2-3.7-3.9,0-2.2,1.8-3.8,4.5-3.8a6.7,6.7,0,0,1,3.1.7L22.6,19a5.94,5.94,0,0,0-2.6-.7c-1.9,0-2.6,1.1-2.6,2.1,0,1.3.8,2,2.8,2.6,2.4,1,3.5,2.1,3.5,4.1s-1.6,4-4.9,4A6.23,6.23,0,0,1,15.3,30.3ZM58.5,11.9v4.4h1.6c1.8,0,3-1,3-2.3,0-1.6-1.2-2.2-3-2.2A9.15,9.15,0,0,0,58.5,11.9ZM56.8,24.1V10.7a17.33,17.33,0,0,1,3.2-.3,5.49,5.49,0,0,1,3.7,1,2.64,2.64,0,0,1,1.1,2.4,3.21,3.21,0,0,1-2.3,3h0a3.61,3.61,0,0,1,2.8,3.4A4,4,0,0,1,64.1,23c-.9.9-2.5,1.3-4.7,1.3A24.62,24.62,0,0,1,56.8,24.1Zm-52.4.2V12H.2V10.5H10.4V12H6.2V24.3Zm25-3.5V6.8h1.8V19.3h6v1.5Z",
    },
  },
  customProperties: true,
  lang: {
    t: (key: any, ...params: any[]) => i18n.t(key, params) as string,
  },
  theme: {
    disable: false,
    themes: {
      light: {
        ts_blue: "#406e89",
        ts_orange: "#ffb400",
        ts_special: "2e99ea",
        success: colors.green.lighten1,
      },
      dark: {
        success: colors.green.darken1,
        ts_blue: "#406e89",
        ts_orange: "#ffb400",
        ts_special: "2e99ea",
      },
    },
  },
});

Vue.use(VuetifyConfirm, { vuetify });
export default vuetify;
