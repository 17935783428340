// allow iframes in markdown
//
"use strict";

module.exports = function iframe_plugin(md, options) {
  var attrs = [];
  options = options || {};

  if (options.renderIframe == null) {
    options.renderIframe = true;
  }

  if (options.allowfullscreen) {
    attrs.push(["allowfullscreen", true]);
  }
  attrs.push(["frameborder", options.frameborder || 0]);

  if (options.width) {
    attrs.push(["width", options.width]);
  }
  if (options.height) {
    attrs.push(["height", options.height]);
  }

  function iframe(state, startLine, endLine, silent) {
    var ch, token;

    var pos = state.bMarks[startLine] + state.tShift[startLine],
      max = state.eMarks[startLine];

    ch = state.src.substring(pos, pos + 3);

    if (ch !== "/b/" || pos + 4 >= max) {
      return false;
    }

    state.line = startLine + 1;

    var content = state.src.slice(pos + 3, max).trim();
    const broadcastId = content.match(/^\w*/g)[0];

    var width = 640;
    var height = 480;
    const attrsFound = content.match(/\{[a-z=0-9]*\}/g);
    attrsFound?.forEach(a => {
      const res = a.match(/\b\w+/g);
      if (res[0] == "width") width = res[1];
      else if (res[0] == "height") height = res[1];
    });

    // if (content.indexOf(":") < 0) {
    //   return false;
    // }

    /* istanbul ignore else */
    // <iframe
    //   id="ts-broadcast-widget"
    //   src="http://marketplace.local.tsverse.pro/widgets/broadcast.html"
    //   broadcastid="d92dcdccda77"
    //   title="broadcast"
    //   frameborder="0"
    //   scrolling="no"
    //   width="844px"
    //   height="490px"
    // >
    //   Ð’Ð°Ñˆ Ð±Ñ€Ð°ÑƒÐ·ÐµÑ€ Ð½Ðµ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶Ð¸Ð²Ð°ÐµÑ‚ Ð¿Ð»Ð°Ð²Ð°ÑŽÑ‰Ð¸Ðµ
    //   Ñ„Ñ€ÐµÐ¹Ð¼Ñ‹!
    // </iframe>;
    if (!silent) {
      if (options.renderIframe) {
        token = state.push("div_open", "div", 1);
        token.attrs = [["class", "iframe-container"]];
        token = state.push("iframe_open", "iframe", 1);
        token.markup = "/b/";
        token.attrs = attrs.concat([
          [
            "src",
            "" +
              process.env.VUE_APP_MARKETPLACE_API_BASE +
              "widgets/broadcast?id=" +
              broadcastId +
              "&width=" +
              width +
              "&height=" +
              height +
              "&t=" +
              Date.now(),
          ],
          ["frameborder", "0"],
          ["scrolling", "no"],
          ["width", width],
          ["height", height],
        ]);
        token.map = [startLine, state.line];

        token = state.push("iframe_close", "iframe", -1);
        token.markup = "/b/";
        token = state.push("div_close", "div", -1);
      } else {
        token = state.push("paragraph_open", "p", 1);
        token.markup = "/b/";

        token = state.push("text", "", 0);
        token.content =
          "iFrame rendering can be buggy, so please only render iframes when the URL is complete\n";

        token = state.push("text", "", 0);
        token.content = ch + broadcastId;

        token = state.push("paragraph_close", "p", -1);
        token.markup = "/b/";
      }
    }

    return true;
  }

  md.block.ruler.before("paragraph", "iframe", iframe);
};
