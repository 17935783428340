<template>
  <div
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click.stop="toggle()"
    :class="[
      'pointer d-flex flex-row flex-nowrap justify-space-between align-center',
      hover ? (selected ? 'blue darken-1 white--text' : 'grey lighten-4') : '',
      selected ? 'blue darken-1 white--text' : 'white',
    ]"
  >
    <div class="ml-2 ma-3 d-flex flex-row flex-nowrap">
      <v-icon :color="selected ? 'white' : 'grey darken-1'">{{ icon }}</v-icon>
      <div class="ml-2">{{ text }}</div>
    </div>
    <z-fav-toggle
      v-model="isFavorite"
      :dark="selected"
      class="mr-1 ml-4"
    ></z-fav-toggle>
  </div>
</template>

<script>
import favtoggle from "@/components/controls/z-fav-toggle";

export default {
  name: "fav-toggle",
  data() {
    return {
      hover: false,
      isFavorite: null,
      //isSelected:  false,
    };
  },
  props: {
    text: {
      type: String,
      default: "...",
    },
    icon: {
      type: String,
      default: "",
    },
    //keyname: {
    //  type: String,
    //  default: '',
    //},
    selected: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    isFavorite(newVal, oldVal) {
      if (newVal != oldVal && oldVal !== null) {
        this.$emit("update:favorite", newVal);
      }
    },
    /*
    favorite(newVal){
        if(this.isFavorite != newVal)
            this.isFavorite = newVal;
    },
    */
  },

  //computed:  {
  //isSelected(){
  //    return this.selected == this.keyname;
  //},
  //},

  methods: {
    toggle() {
      this.$emit("update:selected", this.selected);
    },
  },
  created() {
    this.isFavorite = this.favorite;
  },
  components: {
    "z-fav-toggle": favtoggle,
  },
};
</script>
