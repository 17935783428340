<template>
  <div class="d-flex flex-row flex-nowrap">
    <div class="d-flex flex-row flex-nowrap">
      <z-text-toggle
        v-for="(item, i) in choosed"
        :key="'choosed' + i"
        :text="item.value + _text(unitsShortNames[item.unit])"
        v-model="item.selected"
        @switch-on="setSelected(item)"
      ></z-text-toggle>
    </div>
    <v-menu top close-on-click v-model="menu">
      <template v-slot:activator="{ on }">
        <z-text-toggle
          allowOff
          icon="mdi-chevron-down"
          v-on="on"
        ></z-text-toggle>
      </template>
      <div class="white d-flex flex-column align-stretch pt-1">
        <z-fav-menu-item
          v-for="item in items"
          :key="'intervalmenu' + item.value + item.unit"
          :favorite.sync="item.favorite"
          @update:favorite="saveFavorite(item)"
          :selected.sync="item.selected"
          @update:selected="setSelected(item)"
          :text="
            item.value +
            ' ' +
            _numerals(item.value, _text(unitsNames[item.unit]))
          "
        ></z-fav-menu-item>
      </div>
    </v-menu>
  </div>
</template>

<script>
//import zTextToggle from "@/components/controls/z-text-toggle";
//import zFavMenuItem from "@/components/controls/z-fav-menu-item";
import { filter } from "lodash";
import {
  intervals,
  intervalUnitsNames,
  intervalUnitsShortNames,
} from "@/data/ui-data";

export default {
  name: "interval-control",
  data() {
    return {
      loading: false,
      items: intervals,

      unitsNames: intervalUnitsNames,

      unitsShortNames: intervalUnitsShortNames,

      default: ["1m"],
      preferences: [],

      menu: false,
    };
  },
  props: {
    value: {
      type: String,
      default: "1m",
    },
  },

  methods: {
    setSelected(selectedItem) {
      this.items.forEach(item => {
        item.selected = selectedItem == item;
      });
      //console.log("setSelected()", selectedItem.value + selectedItem.unit);
      this.$emit("input", selectedItem.value + selectedItem.unit);
    },

    saveFavorite(item) {
      if (item.favorite)
        this.$store.dispatch("preferencesIntervalAdd", item.value + item.unit);
      else
        this.$store.dispatch("preferencesIntervalDrop", item.value + item.unit);
    },

    _numerals(num, vars) {
      const cases = [2, 0, 1, 1, 1, 2];
      return vars[
        num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
      ];
    },
    _text(langObj) {
      return langObj[this.$i18n.locale];
    },
  },
  computed: {
    choosed() {
      let result = filter(this.items, item => {
        return item.favorite;
      });
      let seleted = filter(this.items, obj => {
        return !obj.favorite && obj.selected;
      });
      if (seleted.length > 0) {
        result.push(seleted[0]);
      }
      return result;
    },
  },

  created() {
    this.preferences = this.$store.getters.preferencesIntervalFavorits;
    this.items.forEach(function (item) {
      item.selected = item.value + item.unit === this.value;
      item.favorite = this.preferences.indexOf(item.value + item.unit) !== -1;
    }, this);
  },

  components: {
    // "z-text-toggle": zTextToggle,
    //"z-fav-menu-item": zFavMenuItem,
  },
};
</script>
