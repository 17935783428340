<template>
  <v-snackbar
    v-model="display"
    :bottom="true"
    :multi-line="true"
    :timeout="4000"
    :color="color"
  >
    <div>
      <div class="font-weight-bold pr-2">{{ title }}</div>
      <div>{{ text }}</div>
    </div>
    <v-btn color="white" text @click="display = false"> Close </v-btn>
  </v-snackbar>
</template>

<script>
import { EventBus } from "@/config";
import { _messages } from "@/components/Notify/messages.ts";
import i18n from "@/i18n";

export default {
  name: "ntf",

  data() {
    return {
      display: false,
      title: "default title",
      text: "default message",
      color: "error",
    };
  },

  components: {},

  computed: {},

  methods: {
    getMessage(errors) {
      let message = false;
      if (errors && Array.isArray(errors)) {
        let key = i18n.locale.substr(0, 2);
        errors.forEach(m => {
          if (typeof m === "string" && _messages[m]) {
            message = {
              title: this.$_getLocal(_messages[m].title),
              message: this.$_getLocal(_messages[m].text),
              type: this._messages[m].type,
            };
            return true;
          } else if (m.lang && m.lang == key) {
            message = m;
            return true;
          }
        });
      }
      return message;
    },
    notify(errors) {
      if (errors && Array.isArray(errors)) {
        const message = this.getMessage(errors);
        if (message) {
          this.title = message.title;
          this.text = message.message;
          this.color = message.type
            ? message.type == "info"
              ? "info"
              : message.type == "warning"
              ? "orange"
              : message.type == "error"
              ? "error"
              : "error"
            : "error";
          this.display = true;
        }
      }
    },

    // _notify(error) {
    //   if (typeof error == "string" && typeof _messages[error] == "object") {
    //     error = _messages[error];
    //   }

    //   if (!error.type) error = _messages["__unknown_error__"];

    //   if (error.type) {
    //     switch (error.type) {
    //       case "warning":
    //         this.title = this.$_getLocal(error.title);
    //         this.text = this.$_getLocal(error.text);
    //         this.color = "orange";
    //         this.display = true;
    //         break;
    //       case "info":
    //         this.title = this.$_getLocal(error.title);
    //         this.text = this.$_getLocal(error.text);
    //         this.color = "info";
    //         this.display = true;
    //         break;
    //       case "error":
    //         this.title = this.$_getLocal(error.title);
    //         this.text = this.$_getLocal(error.text);
    //         this.color = "error";
    //         this.display = true;
    //         break;
    //       case "debug":
    //         console.debug("Notify debug: " + error.text);
    //         break;
    //       default:
    //         console.debug("Notify default: " + error);
    //         break;
    //     }
    //   } else {
    //     console.debug("Notify: " + error);
    //   }
    // },
  },

  created() {
    EventBus.$on("notify", error => {
      this.notify(error);
    });
  },
};
</script>
