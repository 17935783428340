//import axios from "axios";
import i18n from "@/i18n";
/*
const _mediaMixin = {
  methods: {
    _graphics(url) {
      return process.env.VUE_APP_PICTURES + url;
    },
  },
};
*/

/*
const _langMixin = {
  methods: {
    _contentPath(data) {
      return `${process.env.VUE_APP_CONTENT_API_BASE}/content/?${
        this._content(data).path
      }`;
    },

    _content(data) {
      //console.log(i18n.locale, data[i18n.locale]);
      if (data[i18n.locale]) {
        return data[i18n.locale];
      }
      switch (i18n.locale) {
        case "ru":
          return data["ru-RU"];
        case "en":
          return data["en-US"];
      }
      if (data.length > 0) {
        return data[0];
      } else {
        return false;
      }
    },

    _getText(langData: any): string {
      if (langData == undefined) return "undefined";

      const key = i18n.locale.substr(0, 2);

      if (typeof langData[key] !== "undefined") {
        return typeof langData[key].value !== "undefined"
          ? langData[key].value
          : langData[key];
      } else if (typeof langData["ru"] !== "undefined") {
        return typeof langData["ru"].value !== "undefined"
          ? langData["ru"].value
          : langData["ru"];
      } else {
        if (langData.length > 0) {
          langData.forEach(langItem => {
            return langItem;
          });
        }
      }
      return "undefined";
    },

    _formatTPRT(sum, zero = true) {
      if (!sum) {
        sum = 0;
      }

      if (!zero && sum === 0) {
        return "";
      }

      sum = Math.round(sum * 100) / 100;
      return this._number_format(sum, 2);
    },

    _formatSum(currency, sum, zero = true) {
      if (!sum) {
        sum = 0;
      }

      if (!zero && sum === 0) {
        return "";
      }

      switch (currency) {
        case "BTC":
          sum = Math.round(sum * 100000000) / 100000000;
          return this._number_format(sum, 5);
          break;
        default:
          sum = Math.round(sum * 100) / 100;
          return this._number_format(sum, 2);
          break;
      }
    },

    _smartFormatSum(currency: string, sum: number, zero = true) {
      if (!sum) {
        sum = 0;
      }

      if (!zero && sum === 0) {
        return "";
      }
      let f = sum.toString().includes(".")
        ? sum.toString().split(".").pop().length
        : 0;

      switch (currency) {
        case "BTC":
          return this._number_format(sum, f, ".", " "); // this._number_format(sum,5);
          break;
        default:
          if (f > 0) {
            f = 2;
          }
          sum = Math.round(sum * 100) / 100;
          return this._number_format(sum, f, ".", " ");
          break;
      }
    },

    _number_format(
      num: any,
      decimals: number,
      decPoint: string,
      separator: any,
    ) {
      num = (num + "").replace(/[^0-9+\-Ee.]/g, "");
      const n = !isFinite(+num) ? 0 : +num;
      const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
      const sep = typeof separator === "undefined" ? "," : separator;
      const dec = typeof decPoint === "undefined" ? "." : decPoint;
      let s = [];
      const toFixedFix = (n, prec) => {
        const k = Math.pow(10, prec);
        return "" + (Math.round(n * k) / k).toFixed(prec);
      };
      // Фиксим баг в IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
};
*/
/*
const _dateMixin = {
  methods: {
    _getDate(value) {
      const date = new Date(value);
      // date.setMinutes( date.getMinutes() - date.getTimezoneOffset() );
      return date;
    },

    _printDate(value, seconds_mode = false) {
      if (seconds_mode) {
        value *= 1000;
      }
      return this._getDate(value).toLocaleDateString();
    },

    _printTime(value, seconds_mode = false) {
      if (seconds_mode) {
        value *= 1000;
      }
      return this._getDate(value).toLocaleTimeString();
    },

    _printDateTime(value, seconds_mode = false) {
      if (seconds_mode) {
        value *= 1000;
      }
      return this._getDate(value).toLocaleString();
    },
    _getISODate(dateObj) {
      const m = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const d = ("0" + dateObj.getDate()).slice(-2);
      return dateObj.getFullYear() + "-" + m + "-" + d;
    },
  },
};
*/
/*
const _tableMixin = {
  data() {
    return {
      message: "hello",
      foo: "abc",
    };
  },

  methods: {
    _changeSort(pagination, columnData) {
      if (columnData.value) {
        if (pagination.sortBy === columnData.value) {
          pagination.descending = !pagination.descending;
        } else {
          pagination.sortBy = columnData.value;
          pagination.descending = false;
        }
      }
    },
  },
};
*/
/*
export { _dateMixin, _tableMixin, _mediaMixin };
*/
/*
const _partner = {
  data() {
    return {
      auth: false,
      partner: {
        discount: 0,
        tokens_quota: 0,
        tokens_usedCount: 0,
        tokens_availableCount: 0,
      },
    };
  },

  methods: {
    setAuth(auth) {
      if (!this.auth) {
        this.auth = auth;
      }
    },
    async getPartner() {
      if (!this.auth) {
        this.setAuth(this.$store.getters.configAuthorization);
      }
      _request.setAuth(this.auth);
      const result = await _request.do({ action: "getPartner" });
      if (result) {
        this.partner = result;
        this.partner.tokens_quota *= 1;
        this.partner.tokens_usedCount = 1 * this.partner.activeTokens;
        this.partner.tokens_availableCount =
          this.partner.tokens_quota - this.partner.tokens_usedCount;
      } else {
        this.partner = {
          isValid: false,
        };
      }
    },
  },
};
export { _partner };
*/
/*
const _user: Vue = new Vue({
    data: {
        auth: false,
        wallet: false,
    },
    methods: {
        setAuth(auth){
            if(!this.auth)
                this.auth = auth;
        },
        async getPartner() {
            if(!this.auth)
                this.setAuth(this.$store.getters.configAuthorization);
            _request.setAuth(this.auth);
            const result = await _request.do( { action: 'getPartner' } );
            if (result) {
                this.partner = result;
                this.partner.tokens_quota *= 1;
                this.partner.tokens_usedCount = 1 * this.partner.activeTokens;
                this.partner.tokens_availableCount = this.partner.tokens_quota - this.partner.tokens_usedCount;
            } else {
                this.partner = {
                    isValid: false,
                };
            }
        },
    },
});

export { _user };
*/
/*
import { EventBus } from "@/config";

class z_request {
  private axios;

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_PARTNER_API_BASE,
      timeout: 5000,
    });
  }

  public setAuth(auth) {
    this.axios.defaults.headers.common.Authorization = auth;
  }

  public forceFileDownload(response, content) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", content.name);
    document.body.appendChild(link);
    link.click();
  }

  public async download(content) {
    const config = {
      headers: {
        vstId: content.vstId,
        url_from: content.from,
      },
    };

    this.axios
      .get(`${process.env.VUE_APP_CONTENT_API_BASE}${content.path}`, config)
      .then(response => {
        this.forceFileDownload(response, content);
      })
      .catch(() => console.debug("error occured"));
  }

  public async get(path) {
    const config = {
      //    headers: {
      //        vstId: content.vstId,
      //        url_from: content.from,
      //    }
    };

    return this.axios
      .get(path, config)
      .then(response => {
        if (response.data.success === true) {
          if (response.data.message) {
            EventBus.$emit("notify", response.data.message);
          }
          return response.data.data;
        } else {
          EventBus.$emit("notify", response.data.data);
          return false;
        }
      })
      .catch(error => {
        EventBus.$emit("notify", error);
        return false;
      });
  }

  public async do(params) {
    return this.axios
      .post("", params)
      .then(response => {
        if (response.data.success === true) {
          if (response.data.message) {
            EventBus.$emit("notify", response.data.message);
          }
          return response.data.data;
        } else {
          EventBus.$emit("notify", response.data.data);
          return false;
        }
      })
      .catch(error => {
        EventBus.$emit("notify", error);
        return false;
      });
  }
}

const _request = new z_request();

export { _request };
*/

export function getObjectValueByPath(
  obj: any,
  path: string,
  fallback?: any,
): any {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== "string") {
    return fallback;
  }
  if (obj[path] !== undefined) {
    return obj[path];
  }
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  return getNestedValue(obj, path.split("."), fallback);
}

export function getNestedValue(
  obj: any,
  path: Array<string | number>,
  fallback?: any,
): any {
  const last = path.length - 1;

  if (last < 0) {
    return obj === undefined ? fallback : obj;
  }

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) {
    return fallback;
  }

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function _getLocal(
  langData: any,
  defaultData = { ru: "Без названия", en: "unnamed" },
): string {
  if (langData == undefined) return undefined;

  let key = i18n.locale.substr(0, 2);
  if (typeof langData[key] === "undefined") key = "en";
  if (!langData[key]) {
    if (defaultData[key]) {
      langData[key] = defaultData[key];
    } else return undefined;
  }
  return langData[key].value ? langData[key].value : langData[key];
}
