var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-md-editor",class:[
    ("v-md-editor--" + _vm.mode),
    {
      'v-md-editor--fullscreen': _vm.fullscreen,
      'v-md-editor--left-area-reverse': _vm.leftAreaReverse,
    } ],style:({ height: _vm.heightGetter })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPreviewMode),expression:"!isPreviewMode"}],staticClass:"v-md-editor__left-area",style:({
      width: _vm.leftAreaVisible ? _vm.leftAreaWidth : 0,
      borderWidth: _vm.leftAreaVisible ? '1px' : 0,
    })},[_c('div',{staticClass:"v-md-editor__left-area-title",style:({
        height: (_vm.toolbarHeight + "px"),
        lineHeight: (_vm.toolbarHeight + "px"),
      })},[_vm._v(" "+_vm._s(_vm.leftAreaTitle)+" ")]),_c('div',{staticClass:"v-md-editor__left-area-body"},[_vm._t("left-area")],2)]),_c('div',{staticClass:"v-md-editor__right-area"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPreviewMode),expression:"!isPreviewMode"}],ref:"toolbarWrapper",staticClass:"v-md-editor__toolbar"},[_c('editor-toolbar',{staticClass:"v-md-editor__toolbar-left",attrs:{"groups":_vm.leftToolbarGroup,"toolbars":_vm.toolbars,"disabled-menus":_vm.disabledMenus},on:{"item-click":_vm.handleToolbarItemClick,"toolbar-menu-click":_vm.handleToolbarMenuClick}}),_c('editor-toolbar',{staticClass:"v-md-editor__toolbar-right",attrs:{"groups":_vm.rightToolbarGroup,"toolbars":_vm.toolbars,"disabled-mens":_vm.disabledMenus},on:{"item-click":_vm.handleToolbarItemClick,"toolbar-menu-click":_vm.handleToolbarMenuClick}})],1),_c('div',{staticClass:"v-md-editor__main"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPreviewMode),expression:"!isPreviewMode"}],ref:"editorWrapper",staticClass:"v-md-editor__editor-wrapper",on:{"click":_vm.handleEditorWrapperClick}},[_vm._t("editor")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditMode),expression:"!isEditMode"}],ref:"previewWrapper",staticClass:"v-md-editor__preview-wrapper"},[_vm._t("preview")],2),_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }