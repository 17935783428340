import { DateTime } from "luxon";
//import i18n from "@/i18n";

//dt.setLocale('ru').toFormat('d MMMM tt - ZZZZZ');
//=> "18 декабря 21:46:55 - Москва, стандартное время"

//const timezone = new Date().getTimezoneOffset();

const ztime = {
  fromInput(stringDate, isDateInUTC) {
    let dt = DateTime.fromSQL(stringDate, {
      setZone: isDateInUTC,
      zone: isDateInUTC ? "UTC" : "",
    });
    if (!dt.isValid) {
      dt = DateTime.fromISO(stringDate, {
        setZone: isDateInUTC,
        zone: isDateInUTC ? "UTC" : "",
      });
    }
    return dt;
  },
  localDateTime: (stringDate, isDateInUTC = true) => {
    let dt = ztime.fromInput(stringDate, isDateInUTC);
    if (isDateInUTC) dt = dt.toLocal();
    return (
      dt.toLocaleString(DateTime.DATE_FULL) +
      " " +
      dt.toLocaleString(DateTime.TIME_SIMPLE)
    );
  },
  localDate: (stringDate, isDateInUTC = true) => {
    let dt = DateTime.fromSQL(stringDate, {
      setZone: isDateInUTC,
      zone: isDateInUTC ? "UTC" : "",
    });
    if (isDateInUTC) dt = dt.toLocal();
    return dt.toLocaleString(DateTime.DATE_FULL);
  },
  localTime: (stringDate, isDateInUTC = true) => {
    let dt = DateTime.fromSQL(stringDate, {
      setZone: isDateInUTC,
      zone: isDateInUTC ? "UTC" : "",
    });
    if (isDateInUTC) dt = dt.toLocal();
    return dt.toLocaleString(DateTime.TIME_SIMPLE);
  },
};

export default ztime;
