<template>
  <div v-if="value || value == 0">{{ value }}</div>
  <v-skeleton-loader
    v-else
    :class="sceletonClass"
    :max-width="sceletonMaxWidth"
    :min-width="sceletonMinWith"
    :type="sceletonType"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: "z-value-with-sceleton",
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    sceletonType: {
      type: String,
      default: "text",
    },
    sceletonClass: {
      type: String,
      default: "mx-auto",
    },
    sceletonMaxWidth: {
      type: [String, Number],
      default: 300,
    },
    sceletonMinWith: {
      type: [String, Number],
      default: 250,
    },
  },
};
</script>
