"use strict";

exports.__esModule = true;
exports.default = void 0;
const _default = {
  undo: {
    toolbar: "Отменить",
  },
  redo: {
    toolbar: "Повторить",
  },
  clear: {
    toolbar: "Очистить",
  },
  h: {
    toolbar: "Заголовки",
  },
  h1: {
    toolbar: "Заголовок 1",
    placeholder: "Заголовок 1",
  },
  h2: {
    toolbar: "Заголовок 2",
    placeholder: "Заголовок 2",
  },
  h3: {
    toolbar: "Заголовок 3",
    placeholder: "Заголовок 3",
  },
  h4: {
    toolbar: "Заголовок 4",
    placeholder: "Заголовок 4",
  },
  h5: {
    toolbar: "Заголовок 5",
    placeholder: "Заголовок 5",
  },
  h6: {
    toolbar: "Заголовок 6",
    placeholder: "Заголовок 6",
  },
  bold: {
    toolbar: "Жирный",
    placeholder: "Жирный",
  },
  italic: {
    toolbar: "Курсив",
    placeholder: "Курсив",
  },
  strikethrough: {
    toolbar: "Зачеркнутый",
    placeholder: "Зачеркнутый",
  },
  quote: {
    toolbar: "Цитата",
    placeholder: "Цитата",
  },
  ul: {
    toolbar: "Неупорядоченный список",
    placeholder: "Неупорядоченный список",
  },
  ol: {
    toolbar: "Упорядоченный список",
    placeholder: "Упорядоченный список",
  },
  table: {
    toolbar: "Вставить таблицу",
  },
  hr: {
    toolbar: "Разделитель",
  },
  link: {
    toolbar: "Вставить ссылку",
    descPlaceholder: "Ссылка",
  },
  image: {
    toolbar: "Вставить изображение",
  },
  imageLink: {
    toolbar: "Ссылка на изображение",
  },
  uploadImage: {
    toolbar: "Загрузить изображение",
  },
  code: {
    toolbar: "Вставить блок кода",
  },
  save: {
    toolbar: "Сохранить",
  },
  preview: {
    enabled: "Включить просмотр",
    disabled: "Отключить просмотр",
  },
  toc: {
    title: "Навигация по разделам",
    enabled: "Включить навигацию",
    disabled: "Отключить навигацию",
  },
  syncScroll: {
    enabled: "Включить синхронизацию прокрутки",
    disabled: "Отключить синхронизацию прокрутки",
  },
  fullscreen: {
    enabled: "Полный экран (Нажмите ESC для выхода)",
    disabled: "Выйти из полноэкранного режима",
  },
  tip: {
    tip: "tip",
    warning: "warning",
    danger: "danger",
    details: "details",
    stop: "stop",
    enabled: "tip",
    disabled: "tip",
  },
};
exports.default = _default;
