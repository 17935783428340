<template>
  <div
    :class="[
      'ml-3 font-weight-bold',
      contract.isBlocked
        ? 'error--text'
        : contract.isActive
        ? 'success--text'
        : 'grey--text',
    ]"
  >
    {{
      contract.isRemoved
        ? $t("tsmarket.status.contract-status-removed")
        : contract.isBlocked
        ? $t("tsmarket.status.contract-status-blocked")
        : contract.isActive
        ? $t("tsmarket.status.contract-status-switch-on")
        : $t("tsmarket.status.contract-status-switch-off")
    }}
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "contract-status-control",
  data() {
    return {};
  },
  props: {
    contract: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
});
</script>
