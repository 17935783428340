import Vue from "vue";
import router from "@/router";
import axios, { AxiosInstance } from "axios";
import {
  UserManagerSettings,
  UserManager,
  WebStorageStateStore,
  User,
  Log as OIDCLog,
} from "oidc-client";

import store from "@/store";

OIDCLog.logger = console;
OIDCLog.level = 4;

const isDebug: boolean = process.env.NODE_ENV !== "production";

const oidcConfig: UserManagerSettings = {
  client_id: "tss",
  authority: process.env.VUE_APP_ACCOUNT_BASE,
  response_type: "id_token token",
  scope: "openid profile roles cloudapi shopapi email",
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 60,

  // отзывает access_token в соответствии со стандартом https://tools.ietf.org/html/rfc7009
  revokeAccessTokenOnSignout: true,

  // this will allow all the OIDC protocol claims to be visible in the window. normally a client app
  // wouldn"t care about them or want them taking up space
  filterProtocolClaims: true,

  // допустимая погрешность часов на клиенте и серверах, нужна для валидации токенов, по умолчанию 300
  // https://github.com/IdentityModel/oidc-client-js/blob/1.3.0/src/JoseUtil.js#L95
  clockSkew: 300,
  // делать ли запрос к UserInfo endpoint для того, чтоб добавить данные в профиль пользователя
  loadUserInfo: true,

  userStore: new WebStorageStateStore({
    store: isDebug ? window.localStorage : window.sessionStorage,
  }),

  // Адрес страницы, на которую будет перенаправлен браузер после прохождения пользователем аутентификации
  // и получения от пользователя подтверждений - в соответствии с требованиями OpenId Connect
  redirect_uri: `${window.location.origin}/signin-redirect`,
  silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
  // Страница, на которую нужно перенаправить пользователя в случае инициированного им логаута
  post_logout_redirect_uri: `${window.location.origin}/`,

  // следить за состоянием сессии на IdentityServer, по умолчанию true
  monitorSession: true,

  // интервал в миллисекундах, раз в который нужно проверять сессию пользователя, по умолчанию 2000
  checkSessionInterval: 2000,
};

const userManager: UserManager = new UserManager(oidcConfig);

// переменная для хранения запроса токена
let refreshTokenRequest = null;

function buildHeaderAuthorization(user: User): string {
  return user ? `${user.token_type} ${user.access_token}` : "";
}

// запросить валидный аксесс токен
async function requestValidAccessToken() {
  // сначала запоминаем текущий accessToken из хранилища
  let user = store.getters.userInfo;

  // временное решение. Пока не заработает silent-renew
  //if (store.getters.isExpired == true) {
  //signin-redirect
  //  router.push({ name: "router" });
  //}

  if (store.getters.isExpired == true) {
    // если не было запроса на обновление
    // создаем запрос и запоминаем его в переменную
    // для избежания race condition
    if (refreshTokenRequest === null) {
      refreshTokenRequest = store.dispatch("signinProfile");
    }

    // а теперь резолвим этот запрос
    user = await refreshTokenRequest;

    // и очищаем переменную
    refreshTokenRequest = null;
  }

  // возвращаем рабочий accessToken
  return user;
}

const openstoragesignalapi: AxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_STORAGESIGNALS_API_BASE}${process.env.VUE_APP_OPEN_STORAGE}`,
  // ошибки со статусом кода меньше 500 обрабатываем на фронте
  validateStatus: status => status < 500,
  timeout: 10000,
});

openstoragesignalapi.interceptors.request.use(
  response => {
    store.dispatch("updateLoading", true);
    return response;
  },
  error => Promise.reject(error),
);

openstoragesignalapi.interceptors.response.use(
  response => {
    store.dispatch("updateLoading", false);
    return response;
  },
  error => Promise.reject(error),
);

const storagesignalapi: AxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_STORAGESIGNALS_API_BASE}${process.env.VUE_APP_STORAGE}`,
  // ошибки со статусом кода меньше 500 обрабатываем на фронте
  validateStatus: status => status < 500,
  timeout: 10000,
});

storagesignalapi.interceptors.request.use(
  async config => {
    store.dispatch("updateLoading", true);
    // запрашиваем валидный accessToken
    const user = await requestValidAccessToken();

    const header = buildHeaderAuthorization(user);
    config.headers = {
      Authorization: header,
    };

    return config;
  },
  error => Promise.reject(error),
);

storagesignalapi.interceptors.response.use(
  async config => {
    store.dispatch("updateLoading", false);

    const {
      data: { errors },
      status,
    } = config;

    // если пришла 401, разлогиниваем пользователя
    if (status === 401 || status === 403 || status === 419) {
      await store.dispatch("signout");
    } else if (errors) {
      // показываем ошибки сервера для фронта, если нет указаний пропустить их вывод
      console.error(errors);
    }

    return config;
  },
  error => Promise.reject(error),
);

// const tradestoragesignalapi: AxiosInstance = axios.create({
//   baseURL: `${process.env.VUE_APP_STORAGESIGNALS_API_BASE}${process.env.VUE_APP_TRADE_STORAGE}`,
//   // ошибки со статусом кода меньше 500 обрабатываем на фронте
//   validateStatus: status => status < 500,
//   timeout: 10000,
// });

// tradestoragesignalapi.interceptors.request.use(
//   async config => {
//     store.dispatch("updateLoading", true);

//     // запрашиваем валидный accessToken
//     const user = await requestValidAccessToken();

//     const header = buildHeaderAuthorization(user);
//     config.headers = {
//       Authorization: header,
//     };

//     return config;
//   },
//   error => Promise.reject(error),
// );

// tradestoragesignalapi.interceptors.response.use(
//   async config => {
//     store.dispatch("updateLoading", false);

//     const {
//       data: { errors },
//       status,
//     } = config;

//     // если пришла 401, разлогиниваем пользователя
//     if (status === 401 || status === 403 || status === 419) {
//       await store.dispatch("signout");
//     } else if (errors) {
//       // показываем ошибки сервера для фронта, если нет указаний пропустить их вывод
//       console.error(errors);
//     }

//     return config;
//   },
//   error => Promise.reject(error),
// );

// const calcapi: AxiosInstance = axios.create({
//   baseURL: process.env.VUE_APP_SIGNAL_CHART,
//   // ошибки со статусом кода меньше 500 обрабатываем на фронте
//   validateStatus: status => status < 500,
//   timeout: 10000,
// });

// calcapi.interceptors.request.use(
//   async config => {
//     // store.dispatch("updateLoading", true);

//     // запрашиваем валидный accessToken
//     const user = await requestValidAccessToken();

//     const header = buildHeaderAuthorization(user);
//     config.headers = {
//       Authorization: header,
//     };

//     return config;
//   },
//   error => Promise.reject(error),
// );

// calcapi.interceptors.response.use(
//   async config => {
//     // store.dispatch("updateLoading", false);

//     const {
//       data: { errors },
//       status,
//     } = config;

//     // если пришла 401, разлогиниваем пользователя
//     if (status === 401 || status === 403 || status === 419) {
//       await store.dispatch("signout");
//     } else if (errors) {
//       // показываем ошибки сервера для фронта, если нет указаний пропустить их вывод
//       console.error(errors);
//     }

//     return config;
//   },
//   error => Promise.reject(error),
// );

const openchannelapi: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_TSCHANNELOPEN_BASE,
  // ошибки со статусом кода меньше 500 обрабатываем на фронте
  validateStatus: status => status < 500,
  timeout: 10000,
});

// const copyapi: AxiosInstance = axios.create({
//   baseURL: process.env.VUE_APP_COPY_API_BASE,
//   // ошибки со статусом кода меньше 500 обрабатываем на фронте
//   validateStatus: status => status < 500,
//   timeout: 10000,
// });

// copyapi.interceptors.request.use(
//   async config => {
//     store.dispatch("updateLoading", true);

//     // запрашиваем валидный accessToken
//     const user = await requestValidAccessToken();

//     const header = buildHeaderAuthorization(user);
//     config.headers = {
//       Authorization: header,
//     };

//     return config;
//   },
//   error => Promise.reject(error),
// );

// copyapi.interceptors.response.use(
//   async config => {
//     store.dispatch("updateLoading", false);

//     const {
//       data: { errors },
//       status,
//     } = config;

//     // если пришла 401, разлогиниваем пользователя
//     if (status === 401 || status === 403 || status === 419) {
//       await store.dispatch("signout");
//     } else if (errors) {
//       // показываем ошибки сервера для фронта, если нет указаний пропустить их вывод
//       console.error(errors);
//     }

//     return config;
//   },
//   error => Promise.reject(error),
// );

// const shopapi: AxiosInstance = axios.create({
//   baseURL: process.env.VUE_APP_TSSHOP_BASE,
//   validateStatus: status => status < 500,
//   timeout: 10000,
// });

// shopapi.interceptors.request.use(
//   async config => {
//     store.dispatch("updateLoading", true);

//     // запрашиваем валидный accessToken
//     const user = await requestValidAccessToken();

//     const header = buildHeaderAuthorization(user);
//     config.headers = {
//       Authorization: header,
//     };

//     return config;
//   },
//   error => Promise.reject(error),
// );

// shopapi.interceptors.response.use(
//   async config => {
//     store.dispatch("updateLoading", false);

//     const {
//       data: { errors },
//       status,
//     } = config;

//     // если пришла 401, разлогиниваем пользователя
//     if (status === 401 || status === 403 || status === 419) {
//       await store.dispatch("signout");
//     } else if (errors) {
//       // показываем ошибки сервера для фронта, если нет указаний пропустить их вывод
//       console.error(errors);
//     }

//     return config;
//   },
//   error => Promise.reject(error),
// );

const promoapi: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_PARTNER_API_BASE,
  // ошибки со статусом кода меньше 500 обрабатываем на фронте
  validateStatus: status => status < 500,
  timeout: 10000,
});

promoapi.interceptors.request.use(
  config => {
    store.dispatch("updateLoading", true);

    return config;
  },
  error => Promise.reject(error),
);

promoapi.interceptors.response.use(
  config => {
    store.dispatch("updateLoading", false);
    return config;
  },
  error => {
    store.dispatch("updateLoading", false);

    return Promise.reject(error);
  },
);

// const channelapi: AxiosInstance = axios.create({
//   baseURL: process.env.VUE_APP_TSCHANNEL_BASE,
//   // ошибки со статусом кода меньше 500 обрабатываем на фронте
//   validateStatus: status => status < 500,
//   timeout: 10000,
// });

// channelapi.interceptors.request.use(
//   async config => {
//     store.dispatch("updateLoading", true);

//     // запрашиваем валидный accessToken
//     const user = await requestValidAccessToken();

//     const header = buildHeaderAuthorization(user);
//     config.headers = {
//       Authorization: header,
//     };

//     return config;
//   },
//   error => Promise.reject(error),
// );

// channelapi.interceptors.response.use(
//   async config => {
//     store.dispatch("updateLoading", false);

//     const {
//       data: { errors },
//       status,
//     } = config;

//     // если пришла 401, разлогиниваем пользователя
//     if (status === 401 || status === 403 || status === 419) {
//       await store.dispatch("signout");
//     } else if (errors) {
//       // показываем ошибки сервера для фронта, если нет указаний пропустить их вывод
//       console.error(errors);
//     }
//     if (!config.data.success) EventBus.$emit("notify", config.data.message);
//     return config;
//   },
//   error => Promise.reject(error),
// );

const walletapi: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_WALLET_API_BASE,
  // ошибки со статусом кода меньше 500 обрабатываем на фронте
  validateStatus: status => status < 500,
  timeout: 10000,
});

walletapi.interceptors.request.use(
  async config => {
    store.dispatch("updateLoading", true);

    // запрашиваем валидный accessToken
    const user = await requestValidAccessToken();
    //console.log("requestValidAccessToken", user);

    const header = buildHeaderAuthorization(user);
    config.headers = {
      Authorization: header,
    };

    return config;
  },
  error => Promise.reject(error),
);

walletapi.interceptors.response.use(
  async config => {
    store.dispatch("updateLoading", false);

    const {
      data: { errors },
      status,
    } = config;

    if (status === 401 || status === 403 || status === 419) {
      console.log(
        "walletapi: request failed with code " + config.data.message.code,
      );
      return false;
      //await store.dispatch("signout");
    }
    if (errors) {
      EventBus.$emit("notify", errors);
    }
    return config;
  },
  error => Promise.reject(error),
);

const marketapi: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_MARKETPLACE_API_BASE,
  // ошибки со статусом кода меньше 500 обрабатываем на фронте
  validateStatus: status => status < 500,
  timeout: 10000,
});

marketapi.interceptors.request.use(
  async config => {
    store.dispatch("updateLoading", true);

    // запрашиваем валидный accessToken
    const user = await requestValidAccessToken();

    const header = buildHeaderAuthorization(user);
    config.headers = {
      //  "Content-Type": "text/plain",
      Authorization: header,
    };

    return config;
  },
  error => Promise.reject(error),
);

marketapi.interceptors.response.use(
  async function (response) {
    const {
      data: { errors },
      status,
    } = response;

    switch (status) {
      case 419: // 419 Authentication Timeout (not in RFC 2616) («обычно ошибка проверки CSRF»);
      case 401: // 401 Unauthorized («не авторизован (не представился)»)[2][3];
        // case 403: // Forbidden («запрещено (не уполномочен)»)[2][3];
        // разлогиниваем пользователя
        await store.dispatch("signout");
        return false;
      case 413: // 413 Payload Too Large («полезная нагрузка слишком велика»)[2][3];
        EventBus.$emit("notify", "__payload_too_large__");
        return false;
      default:
        break;
    }

    if (errors) {
      EventBus.$emit("notify", errors);
    }
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  },
);

const tokenapi: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_REFERAL_API_BASE,
  validateStatus: status => status < 500,
  timeout: 10000,
});

tokenapi.interceptors.request.use(
  async config => {
    const user = store.getters.userInfo;

    const header = buildHeaderAuthorization(user);
    config.headers = {
      //  "Content-Type": "text/plain",
      Authorization: header,
    };

    return config;
  },
  error => Promise.reject(error),
);

const referalapi: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_REFERAL_API_BASE,
  // ошибки со статусом кода меньше 500 обрабатываем на фронте
  validateStatus: status => status < 500,
  timeout: 10000,
});

referalapi.interceptors.request.use(
  async config => {
    store.dispatch("updateLoading", true);

    // запрашиваем валидный accessToken
    const user = await requestValidAccessToken();

    const header = buildHeaderAuthorization(user);
    config.headers = {
      //  "Content-Type": "text/plain",
      Authorization: header,
    };

    return config;
  },
  error => Promise.reject(error),
);

referalapi.interceptors.response.use(
  async config => {
    store.dispatch("updateLoading", false);

    const {
      data: { errors },
      status,
    } = config;

    // если пришла 401, разлогиниваем пользователя
    if (status === 401 || status === 403 || status === 419) {
      await store.dispatch("signout");
    }
    if (errors) {
      EventBus.$emit("notify", errors);
    }
    return config;
  },
  error => Promise.reject(error),
);

const profileapi: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_PROFILE_API_BASE,
  // ошибки со статусом кода меньше 500 обрабатываем на фронте
  validateStatus: status => status < 500,
  timeout: 10000,
});

profileapi.interceptors.request.use(
  async config => {
    store.dispatch("updateLoading", true);

    // запрашиваем валидный accessToken
    const user = await requestValidAccessToken();

    const header = buildHeaderAuthorization(user);
    config.headers = {
      //  "Content-Type": "text/plain",
      Authorization: header,
    };

    return config;
  },
  error => Promise.reject(error),
);

profileapi.interceptors.response.use(
  async config => {
    store.dispatch("updateLoading", false);

    const {
      data: { errors },
      status,
    } = config;

    // если пришла 401, разлогиниваем пользователя
    if (status === 401 || status === 403 || status === 419) {
      await store.dispatch("signout");
    }
    if (errors) {
      EventBus.$emit("notify", errors);
    }
    return config;
  },
  error => Promise.reject(error),
);

const referenceapi: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_REFERENCE_API_BASE,
  validateStatus: status => status < 500,
  timeout: 10000,
});

const EventBus: Vue = new Vue();

const locales = [
  {
    id: "en-EN",
    name: "English",
    flag: `${process.env.VUE_APP_HOME}${process.env.VUE_APP_PICTURES}/locales/gb.svg`,
  },
  {
    id: "ru-RU",
    name: "Русский",
    flag: `${process.env.VUE_APP_HOME}${process.env.VUE_APP_PICTURES}/locales/ru.svg`,
  },
];
const currencies = ["USDT"];

const changebleTariffs =
  process.env.VUE_APP_MARKETPLACE_DB_VERSION == "prod" ? [41] : [41];

const editor_mediaInsertMaxWidth = 800;

export {
  requestValidAccessToken,
  isDebug,
  locales,
  // copyapi,
  // calcapi,
  EventBus,
  currencies,
  // channelapi,
  referenceapi,
  // shopapi,
  openchannelapi,
  promoapi,
  walletapi,
  marketapi,
  userManager,
  storagesignalapi,
  openstoragesignalapi,
  // tradestoragesignalapi,
  changebleTariffs,
  referalapi,
  profileapi,
  tokenapi,
  editor_mediaInsertMaxWidth,
};
