<template>
  <nav>
    <v-app-bar
      dense
      flat
      app
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      class="grey darken-4"
    >
      <router-link
        tag="div"
        :to="{ name: 'root', params: { locale: $i18n.locale.substring(0, 2) } }"
        class="d-flex flex-row align-center pointer mr-3"
      >
        <v-img
          contain
          height="37"
          width="37"
          src="/tsverse-points-logo-3.svg"
          class="flex-grow-0"
        ></v-img>
        <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="yellow--text1 grey--text text--lighten-2 font-weight-bold ml-2"
        >
          TSVerse
        </div>
      </router-link>

      <v-btn
        v-if="!detailsMenu || (isLoggedIn && $vuetify.breakpoint.smAndUp)"
        dark
        :style="{ 'text-transform': 'none', 'font-weight': 400 }"
        class=""
        :to="{
          name: 'market-showcase-tschannel',
          params: { locale: $i18n.locale.substring(0, 2) },
        }"
        >{{ $t("tsmarket.terms.trading-robots") }}</v-btn
      >

      <v-btn
        text
        dark
        class="mr-3"
        v-if="!detailsMenu || (isLoggedIn && $vuetify.breakpoint.smAndUp)"
        :style="{ 'text-transform': 'none', 'font-weight': 400 }"
        :to="{
          name: 'market-showcase-tslab',
          params: { locale: $i18n.locale.substring(0, 2) },
        }"
        >{{ $t("tsmarket.terms.tslab-licenses") }}</v-btn
      >
      <v-divider
        vertical
        dark
        v-if="!detailsMenu || (isLoggedIn && $vuetify.breakpoint.smAndUp)"
      ></v-divider>
      <!-- personal block start -->

      <!-- tschannel menu -->
      <div v-if="!detailsMenu && isLoggedIn" class="d-flex flex-row">
        <v-menu bottom nudge-top="-30">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              dark
              v-on="on"
              dense
              class="ml-2"
              :style="{ 'text-transform': 'none', 'font-weight': 400 }"
              >{{ $t("tsmarket.titles.traders-cabinet")
              }}<v-icon right dark> mdi-chevron-down </v-icon></v-btn
            >
          </template>
          <div class="white">
            <v-list nav dense class="ma-0 pl-0 pr-0">
              <v-list-item
                v-if="isLoggedIn"
                :to="{
                  name: 'tschannels-list',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tschannel.nav.list")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="isLoggedIn"
                :to="{
                  name: 'receivers-list',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tschannel.nav.receiverslist")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-if="isLoggedIn"
                :to="{
                  name: 'tschannel-offers-list',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsmarket.nav.my-sales")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mb-2"></v-divider>
              <v-list-item
                :to="{
                  name: 'tsplayer-broadcasts',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsplayer.titles.broadcasts")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- v-if="isTester" -->
              <v-list-item
                :to="{
                  name: 'tsplayer',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsmarket.titles.my-agents")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!--
              <v-divider></v-divider>
              <v-list-item @click="openTSChannelDocs()">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("tsmarket.nav.tschannel-external-docs") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              -->
            </v-list>
          </div>
        </v-menu>
        <!-- my purchases -->
        <v-menu v-if="isLoggedIn" bottom nudge-top="-30">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              dark
              v-on="on"
              dense
              class="ml-2 mr-2"
              :style="{ 'text-transform': 'none', 'font-weight': 400 }"
              >{{ $t("tsmarket.terms.my-purchases")
              }}<v-icon right dark> mdi-chevron-down </v-icon></v-btn
            >
          </template>
          <div class="white">
            <v-list nav dense class="ma-0 pl-0 pr-0">
              <v-list-item
                :to="{
                  name: 'tschannel-subscriptions-list',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsmarket.terms.trading-robots")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'tslab-contracts',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsmarket.nav.myTSLabContracts")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!--
              <v-divider></v-divider>
              <v-list-item
                :to="{
                  name: 'market-contracts',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsmarket.nav.myContracts")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              -->
            </v-list>
          </div>
        </v-menu>
      </div>

      <v-spacer></v-spacer>

      <!-- кнопка - ссылка на внешнюю документацию -->
      <v-btn
        icon
        v-if="!detailsMenu || (isLoggedIn && $vuetify.breakpoint.smAndUp)"
        @click="openTSChannelDocs()"
        ><v-icon color="white">mdi-help-circle-outline</v-icon></v-btn
      >

      <!-- <v-btn @click="manageReferalToken()">test</v-btn> -->
      <!-- Правый блок для авторизованных -->
      <div v-if="isLoggedIn" class="d-flex flex-row align-center">
        <v-menu left v-if="!detailsMenu">
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              outlined
              color="grey lighten-2"
              small
              :style="{ 'text-transform': 'none', 'font-weight': 400 }"
              v-on="on"
            >
              {{ walletAmount }} {{ walletCurrency }}
            </v-btn>
          </template>

          <v-list nav dense class="ma-0 pl-0 pr-0">
            <v-list-item-group>
              <v-list-item
                exact
                :to="{
                  name: 'my-account',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content class="pl-4">
                  <div
                    class="d-flex flex-row justify-space-between align-center"
                  >
                    <div>
                      <div class="caption grey--text text--darken-1">
                        {{ $t("tsmarket.terms.wallet-amount") }}:
                      </div>
                      <div>
                        <span>{{ walletAmount }}</span> {{ walletCurrency }}
                      </div>
                    </div>
                    <v-tooltip bottom v-if="isLoggedIn">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click.stop.prevent="walletFetch()"
                          v-on="on"
                          class="pa-0 ml-3 mr-1"
                          small
                          icon
                          dense
                          elevation="0"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                      </template>
                      <span>Обновить баланс</span>
                    </v-tooltip>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-divider></v-divider>
          <v-list nav dense class="ma-0 pl-0 pr-0">
            <v-list-item-group>
              <v-list-item
                :to="{
                  name: 'my-account-replenishment',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content class="pl-4">
                  <v-list-item-title>{{
                    $t("wallet.nav.replenishmentTab")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'my-account-withdraw',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content class="pl-4">
                  <v-list-item-title>{{
                    $t("wallet.nav.withdrawTab")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'my-account-statement',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-content class="pl-4">
                  <v-list-item-title>{{
                    $t("wallet.nav.statementTab")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-menu bottom v-if="isLoggedIn" nudge-width="250" nudge-top="-30">
          <template v-slot:activator="{ on }">
            <v-avatar color="white" size="32" class="ml-3 pointer" v-on="on"
              ><span class="font-weight-black blue--text text--darken-2">{{
                userAvatarinitials
              }}</span></v-avatar
            >
          </template>
          <div class="white">
            <div class="white d-flex flex-row justify-start align-center pa-3">
              <v-avatar color="blue darken-1" size="41" class=""
                ><span class="font-weight-black white--text">{{
                  userAvatarinitials
                }}</span></v-avatar
              >
              <div class="ml-3 d-flex flex-column justify-space-around">
                <div class="font-weight-bold">{{ userName }}</div>
                <div class="caption grey--text text--darken-2">
                  {{ userEmail }}
                </div>
              </div>
            </div>

            <v-divider></v-divider>

            <v-menu nudge-left="50" left>
              <template v-slot:activator="{ on }">
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    v-on="on"
                    :class="[
                      'd-flex flex-row caption grey--text text--darken-3 font-weight-bold pointer pa-2 align-center mt-2 mb-2',
                      hover ? 'grey lighten-4' : '',
                    ]"
                  >
                    <v-img
                      :lazy-src="selectedLocale ? selectedLocale.flag : ''"
                      max-height="18"
                      max-width="25"
                      class="ml-1 mr-4"
                      :src="selectedLocale ? selectedLocale.flag : ''"
                    />
                    <div class="ml-4">
                      {{ selectedLocale ? selectedLocale.name : "" }}
                    </div>
                  </div>
                </v-hover>
              </template>
              <v-list nav dense class="ma-0 pl-0 pr-0">
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, ind) in locales"
                    :key="ind"
                    @click="selectLocale(item)"
                  >
                    <v-list-item-icon class="pl-1">
                      <v-img
                        :lazy-src="item.flag"
                        max-height="18"
                        max-width="25"
                        :src="item.flag"
                      />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-divider></v-divider>

            <v-list nav dense class="ma-0 pl-0 pr-0">
              <v-list-item-group>
                <v-list-item
                  :to="{
                    name: 'my-account',
                    params: { locale: $i18n.locale.substring(0, 2) },
                  }"
                >
                  <v-list-item-icon class="pl-1">
                    <v-icon>fas fa-wallet</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="font-weight-bold mr-2">{{
                        walletAmount
                      }}</span>
                      {{ walletCurrency }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-divider></v-divider>

            <v-list nav dense class="ma-0 pl-0 pr-0">
              <v-list-item-group>
                <v-list-item
                  :to="{
                    name: 'referal-programs-list',
                    params: { locale: $i18n.locale.substring(0, 2) },
                  }"
                >
                  <v-list-item-icon class="pl-1">
                    <v-icon>mdi-handshake</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("referal.nav.referal-programs")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!--
            <v-list nav dense class="ma-0 pl-0 pr-0">
              <v-list-item-group>
                <v-list-item
                  :to="{
                    name: 'statement-vidgets',
                    params: { locale: $i18n.locale.substring(0, 2) },
                  }"
                >
                  <v-list-item-icon class="pl-1">
                    <v-icon>mdi-handshake</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("profile.nav.statement-vidgets")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            -->

            <v-divider></v-divider>
            <v-list nav dense class="ma-0 pl-0 pr-0">
              <v-list-item-group>
                <v-list-item :to="{ name: 'profile' }">
                  <v-list-item-icon class="pl-1">
                    <v-icon>far fa-user-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("profile.titles.my-profile")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  :to="{
                    name: 'logout',
                    params: { locale: $i18n.locale.substring(0, 2) },
                  }"
                >
                  <v-list-item-icon class="pl-1">
                    <v-icon>fas fa-sign-out-alt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("sso.logout.name")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-menu>
      </div>

      <div v-else class="d-flex flex-row align-center">
        <v-btn
          text
          dark
          :style="{ 'text-transform': 'none', 'font-weight': 500 }"
          :to="{
            name: 'login',
            params: { locale: $i18n.locale.substring(0, 2) },
          }"
          >{{ $t("tsmarket.terms.sign-in") }}</v-btn
        >
        <v-btn
          dark
          :style="{ 'text-transform': 'none', 'font-weight': 400 }"
          outlined
          @click="register()"
          >{{ $t("tsmarket.terms.create-account") }}</v-btn
        >
      </div>

      <!-- <v-btn @click="signinPopup()">sp</v-btn> -->

      <!-- меню для мобил -->
      <div v-if="detailsMenu">
        <v-menu bottom nudge-width="250" nudge-top="-30">
          <template v-slot:activator="{ on }">
            <v-btn color="black" icon dark v-on="on" dense
              ><v-icon dark color="white">mdi-menu</v-icon></v-btn
            >
          </template>
          <div class="white">
            <v-list nav dense class="ma-0 pl-0 pr-0">
              <v-list-item
                v-if="!(isLoggedIn && $vuetify.breakpoint.smAndUp)"
                :to="{
                  name: 'market-showcase-tschannel',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
                prepend-icon="mdi-arrow-left-right-bold-outline"
              >
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsmarket.terms.trading-robots")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="!(isLoggedIn && $vuetify.breakpoint.smAndUp)"
                :to="{
                  name: 'market-showcase-tslab',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
                prepend-icon="mdi-arrow-left-right-bold-outline"
              >
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsmarket.terms.tslab-licenses")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="!(isLoggedIn && $vuetify.breakpoint.smAndUp)"
              ></v-divider>
              <v-list-group
                v-if="isLoggedIn"
                :value="true"
                prepend-icon="mdi-cart-variant"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{
                    $t("tsmarket.terms.my-purchases")
                  }}</v-list-item-title>
                </template>
                <v-list-item
                  :to="{
                    name: 'tschannel-subscriptions-list',
                    params: { locale: $i18n.locale.substring(0, 2) },
                  }"
                >
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("tsmarket.terms.trading-robots")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  :to="{
                    name: 'tslab-contracts',
                    params: { locale: $i18n.locale.substring(0, 2) },
                  }"
                >
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("tsmarket.nav.myTSLabContracts")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-divider v-if="isLoggedIn"></v-divider>
              <v-list-item
                v-if="isLoggedIn"
                :to="{
                  name: 'tschannel-offers-list',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-icon
                  ><v-icon class="blue--text text--darken-2"
                    >mdi-hand-coin-outline</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsmarket.nav.my-sales")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="isLoggedIn"></v-divider>
              <v-list-group
                :value="true"
                prepend-icon="mdi-arrow-left-right-bold-outline"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{
                    $t("tschannel.name")
                  }}</v-list-item-title>
                </template>
                <v-list-item
                  v-if="isLoggedIn"
                  :to="{
                    name: 'tschannels-list',
                    params: { locale: $i18n.locale.substring(0, 2) },
                  }"
                  prepend-icon="mdi-arrow-left-right-bold-outline"
                >
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("tschannel.nav.list")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="isLoggedIn"
                  :to="{
                    name: 'receivers-list',
                    params: { locale: $i18n.locale.substring(0, 2) },
                  }"
                >
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("tschannel.nav.receiverslist")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="openTSChannelDocs()">
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("tsmarket.nav.tschannel-external-docs") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-divider v-if="isLoggedIn"></v-divider>
              <v-list-item
                :to="{
                  name: 'tsplayer-broadcasts',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-icon
                  ><v-icon class="blue--text text--darken-2"
                    >mdi-broadcast</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsplayer.titles.broadcasts")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'tsplayer',
                  params: { locale: $i18n.locale.substring(0, 2) },
                }"
              >
                <v-list-item-icon
                  ><v-icon class="blue--text text--darken-2"
                    >mdi-laptop</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("tsmarket.titles.my-agents")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-group
                v-if="isLoggedIn"
                :value="true"
                prepend-icon="mdi-format-list-bulleted"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{
                    $t("tsmarket.nav.reports")
                  }}</v-list-item-title>
                </template>
                <v-list-item
                  :to="{
                    name: 'market-contracts',
                    params: { locale: $i18n.locale.substring(0, 2) },
                  }"
                >
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("tsmarket.nav.myContracts")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group> -->
            </v-list>
          </div>
        </v-menu>
      </div>
      <div>
        <v-menu nudge-top="-30" bottom>
          <template v-slot:activator="{ on }">
            <v-hover>
              <div
                slot-scope="{ hover }"
                v-on="on"
                :class="[
                  'd-flex flex-row caption grey--text text--darken-3 font-weight-bold pointer align-center ml-1',
                  hover ? '' : '',
                ]"
              >
                <v-img
                  :lazy-src="selectedLocale ? selectedLocale.flag : ''"
                  max-height="18"
                  max-width="25"
                  :src="selectedLocale ? selectedLocale.flag : ''"
                />
              </div>
            </v-hover>
          </template>
          <v-list nav dense class="ma-0 pl-0 pr-0">
            <v-list-item-group>
              <v-list-item
                v-for="(item, ind) in locales"
                :key="ind"
                @click="selectLocale(item)"
              >
                <v-list-item-icon class="pl-1">
                  <v-img
                    :lazy-src="item.flag"
                    max-height="18"
                    max-width="25"
                    :src="item.flag"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { find, filter, map } from "lodash";
//import favtoggle from "@/components/controls/z-fav-toggle";
import { locales } from "@/config";
//import Reference from "@/components/Reference/Reference.vue";
//import { DateTime } from "luxon";
import { setLocale } from "@/i18n";
import { getCookie, setCookie, removeCookie } from "tiny-cookie";
import { tokenapi } from "@/config";
//import { referalService } from "@/services/referalservice";
//import { userManager } from "@/config";

export default {
  name: "HeaderMain",
  data() {
    return {
      //drawer: this.$vuetify.breakpoint.lgAndUp,
      rightdrawer: false,
      routes: [],
      locales: locales,
      selectedLocale: null,
      detailsMenu: !this.$vuetify.breakpoint.mdAndUp,
      //isDebug: isDebug,
    };
  },
  props: {
    top: {
      default: 30,
    },
  },
  async created() {
    this.manageReferalToken();
    // проверим, что пользователь не был залогинен в прошлом сеансе и если был, то подгрузим его.
    // т.е. что бы после F5 Refresh не надо было кнопку Войти жать каждый раз
    await this.checkUser();
    this.updateRoutes();
    this.locales.forEach(l => {
      if (l.id == this.$i18n.locale) {
        this.selectedLocale = l;
      }
    });
    // узнаем о наличии скидок
    // this.discountFetch();
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "isLoggedIn",
      "isTester",
      "userName",
      "userAvatarinitials",
      "userEmail",
      "roles",
      "userInfo",
      "discount",
    ]),
    ...mapGetters("TSWallet", [
      "walletLoaded",
      "walletAmount",
      "walletCurrency",
    ]),
    ...mapGetters("navObject", ["navObjectName"]),

    quickMenuItems() {
      for (const route of this.routes) {
        if (route.active) {
          return route.children;
        }
        for (const child of route.children) {
          if (child.active) return route.children;
        }
      }
      return [];
    },
    pictures() {
      return `${process.env.VUE_APP_HOME}${process.env.VUE_APP_PICTURES}`;
    },
    home() {
      return process.env.VUE_APP_HOME;
    },
    userroles() {
      return this.$store.getters.roles;
    },
    flag() {
      let flag = "";
      switch (this.$i18n.locale) {
        case "ru":
          flag = "locales/gb.svg";
          break;
        default:
        case "en":
          flag = "locales/ru.svg";
          break;
      }
      return `${process.env.VUE_APP_HOME}${process.env.VUE_APP_PICTURES}${flag}`;
    },
    page() {
      if (this.$route.name == "my-account") {
        return {
          title: this.$t(this.$route.meta.i18n),
          name: this.$route.name,
        };
      }
      for (const route of this.routes) {
        if (route.active) {
          return { title: this.$t(route.meta.i18n), name: route.name };
        }
        for (const child of route.children) {
          if (child.active)
            return { title: this.$t(child.meta.i18n), name: child.name };
        }
      }
      return "";
    },
  },
  methods: {
    ...mapActions([
      "signinProfile",
      "checkUser",
      "discountFetch",
      "removeUser",
      "signin",
      "signinPopup",
    ]),

    async register() {
      await this.removeUser();
      await this.signin(this.$route);
    },

    async manageReferalToken() {
      // смотрим нет ли сохраненного ранее токена в куках.
      const tokenName = process.env.VUE_APP_REFERAL_INVITE_TOKEN_NAME;
      let token = localStorage
        ? localStorage.getItem(tokenName)
        : getCookie(tokenName);

      if (!token) {
        // значит в куках нет
        // проверяем нет ли реферального токена в адресной строке (переход по реферальной ссылке)
        var searchParams = new URLSearchParams(document.location.search);
        token = searchParams.get("ref");
        // если был в адресной строке, то дальше работаем с ним
      }
      let result = false;
      if (this.isLoggedIn && token) {
        // если пользователь зарегистрирован и есть токен, отправляем его на сервер
        // см. серверную логику
        const res = await tokenapi.post("referal", {
          token: token,
        });
        result = res.data && res.data.success ? res.data.data : false;
      }
      // если сервер вернул true, значит токен учтен (применен к данному пользователю) и более не нужен
      if (result && typeof result === "boolean" && result == true) {
        localStorage
          ? localStorage.removeItem(tokenName)
          : removeCookie(tokenName);
        console.log("remove token");
      } else if (token) {
        // токен был найден (переход неавторизованного пользователя по реферальной ссылке или неавторизованный пользователь с уже сохраненным токеном в куках)
        // но сервер не смог обработать токен, скорее всего потому, что пользователь не авторизован
        // сохраняем токен на будущее
        if (localStorage) localStorage.setItem(tokenName, token);
        else setCookie(tokenName, token);
      }
    },

    openTSChannelDocs() {
      console.log("openTSChannelDocs");
      window.open("https://doc.tsverse.pro/");
    },
    /*
    async testUser() {
      const res = await this.checkUser();
      console.log("testUser", res);
    },
    */

    selectLocale(item) {
      const params = this.$route.params;
      params.locale = item.id.substring(0, 2);
      this.$router.push({ name: this.$route.name, params: params });
      setLocale(item.id);
    },
    /*
    async test() {
      console.log(
        "expired",
        this.userInfo.expired,
        DateTime.fromSeconds(this.userInfo.expires_at).toLocaleString(
          DateTime.DATETIME_FULL,
        ),
      );
      //const user = await userManager.signinRedirectCallback();
      //console.log(user);
      //this.signinProfile();
      //DateTime.fromSeconds(this.userInfo.expires_at).toLocaleString(),
    },
    */

    ...mapActions("TSWallet", ["walletFetch"]),
    // navigate(to) {
    //   const name = to.redirect && to.redirect.name ? to.redirect.name : to.name;
    //   if (this.$router.currentRoute.name != name) this.$router.push(to);
    // },

    hasActiveChild(item) {
      //console.log("checkItem: ", item.name);
      if (item.childs && item.childs.indexOf(this.$route.name) != -1)
        return true;
      if (!item.children || item.children.length == 0) return false;
      for (const child in item.children) {
        if (
          child.name == this.$route.name ||
          (child.childs && child.childs.indexOf(this.$route.name) != -1)
        )
          return true;
        if (this.hasActiveChild(child)) return true;
      }
      return false;
    },

    updateRoutes() {
      const root = find(this.$router.options.routes, { name: "root" });
      //const rootLocale = find(root.children, { name: "root-locale" });
      const personal = find(root.children, { name: "personal" });

      const items = filter(personal.children, r => {
        return (
          r.meta &&
          r.meta.isShownInMenu &&
          r.meta.i18n &&
          (r.meta.allow
            ? this.$store.getters.roles.includes(r.meta.allow)
            : true)
        );
      });
      this.routes = map(items, i => {
        let childs = [];
        if (i.children) {
          i.children.forEach(c => {
            if (c.meta.isShownInMenu) {
              const child = {
                name: c.name,
                title: c.meta.i18n,
                path: c.path,
                redirect: c.redirect ? c.redirect : undefined,
                meta: c.meta,
                active: this.$router.currentRoute.name === c.name,
                favoriteAllow: c.meta.favoriteAllow ? true : false,
                favorite: c.meta.favorite ? true : false,
                icon: c.meta.icon ? c.meta.icon : "mdi-account",
                hover: false,
                childs: [],
              };

              if (c.children) {
                c.children.forEach(cc => {
                  //console.log(cc.name);
                  child.childs.push(cc.name);
                });
              }
              childs.push(child);
            }
          });
        }
        return {
          name: i.name,
          title: i.meta.i18n,
          path: i.path,
          active: this.$router.currentRoute.name === i.name,
          redirect: i.redirect ? i.redirect : undefined,
          favoriteAllow: i.meta.favoriteAllow ? true : false,
          favorite: i.meta.favorite ? true : false,
          icon: i.meta.icon ? i.meta.icon : "mdi-account",
          hover: false,
          children: childs,
          isFolder: childs.length > 0,
          isOpen: childs.length > 0,
          meta: i.meta,
        };
      });

      this.checkActiveRoute(this.$route);
    },

    checkActiveRoute(newRoute) {
      for (const route of this.routes) {
        route.active =
          (newRoute.meta.highlightItemName &&
            newRoute.meta.highlightItemName == route.name) ||
          newRoute.name == route.name;
        for (const child of route.children) {
          child.active =
            (newRoute.meta.highlightItemName &&
              newRoute.meta.highlightItemName == child.name) ||
            newRoute.name == child.name ||
            this.hasActiveChild(child);
        }
      }
    },
  },
  watch: {
    "$vuetify.breakpoint.mdAndUp"(newVal, oldVal) {
      //console.log("$vuetify.breakpoint.mdAndUp", newVal, oldVal);
      if (newVal != oldVal) this.detailsMenu = !newVal;
    },
    $route(newRoute) {
      this.checkActiveRoute(newRoute);
    },
    "$i18n.locale"() {
      this.locales.forEach(l => {
        if (l.id == this.$i18n.locale) {
          this.selectedLocale = l;
        }
      });
    },
    userInfo: {
      async handler(val) {
        if (val) {
          this.updateRoutes();
          //if (this.isLoggedIn) {
          this.walletFetch();
          this.manageReferalToken();
          this.discountFetch();
          //}
        }
      },
    },
  },
  components: {
    //"z-fav-toggle": favtoggle,
    //reference: Reference,
  },
  //beforeCreate() {
  //console.log("registerWeb3 Action dispatched from Headermain.vue");
  //this.$store.dispatch("registerWeb3");
  //},
};
</script>
